<div class="mt-2">

    <mat-tab-group class="tab-small" [selectedIndex]="languageTabIndex">
      <ng-container *ngFor="let t of entity.mappedTranslations; let i = index;">
        <mat-tab>
          <ng-template mat-tab-label>
            <span
                    [matTooltip]="t.language"
                    matTooltipShowDelay="500"
                    matTooltipPosition="above"
            >{{getLocale(t)}}</span>
            <i *ngIf="! isTranslated(entity, t.languageCode)" class='fa fa-pencil'></i>
          </ng-template>
          <table class="table" *ngIf="entity.mappedTranslations">
          <tr>
            <th>Headline:</th>
            <td>{{t.headline}}</td>
          </tr>
          <tr>
            <th>Subheadline:</th>
            <td>{{t.subheadline}}</td>
          </tr>
          <tr>
            <th>Image Description:</th>
            <td>{{t.imageAlt}}</td>
          </tr>
          </table>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

  <table class="table">
    <tr>
      <th>ID:</th>
      <td>{{entity.id}}</td>
    </tr>
    <tr>
      <th>Campaign-Parameter:</th>
      <td>{{entity.campaign}}</td>
    </tr>
    <tr>
      <th>Date:</th>
      <td>
        <app-date-range-info *ngIf="entity.startDate && entity.endDate; else infoTpl"
                             [startDate]="entity.startDate"
                             [endDate]="entity.endDate">
        </app-date-range-info>
        <ng-template #infoTpl>--</ng-template>
      </td>
    </tr>
    <tr>
      <th>Weekdays:</th>
      <td>
        <app-weekdays-info *ngIf="entity.weekdays" [weekdays]="entity.weekdays"></app-weekdays-info>
      </td>
    </tr>
    <tr>
      <th style="vertical-align: top;">
        Image desktop device:
      </th>
      <td>
        <app-image-container
          *ngIf="entity.image"
          [src]="getImagePath(entity.image)"
        ></app-image-container>
        {{entity.image || 'no image loaded'}}
      </td>
    </tr>
    <tr>
      <th>Active:</th>
      <td>
        <p>{{entity.active? 'yes' : 'no'}}</p>
      </td>
    </tr>
    <tr>
      <th>Insert-Ts:</th>
      <td>{{entity.insertTs | date: dateFormat }}</td>
    </tr>
    <tr>
      <th>Update-Ts:</th>
      <td>{{entity.updateTs | date: dateFormat}}</td>
    </tr>
  </table>
</div>
