import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root'
})
export class XlsxDownloadService {
  readonly fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  download(headlines: string[], data: any[], filename: string): void {
    const worksheet = XLSX.utils.json_to_sheet(data, {header: headlines, skipHeader: false});
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([excelBuffer], { type: this.fileType });

    this.onDownloadEvent(blob, filename);
  }

  private onDownloadEvent(buffer: any, filename: string): void {
    const data: Blob = new Blob([buffer], {type: this.fileType});
    const windowNavigator = window.navigator as any;
    if (windowNavigator && windowNavigator.msSaveOrOpenBlob) {
      windowNavigator.msSaveOrOpenBlob(data, filename);
    } else {
      const url: string = window.URL.createObjectURL(data);
      const downloadLink: any = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = url;
      downloadLink.download = filename;
      downloadLink.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(downloadLink);
    }
  }
}
