import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Shop} from '../../../classes/shop';
import {IDialogData} from '../../../interfaces/idialog-data';
import {IFormData} from '../../../interfaces/form-data';
import {Subscription} from 'rxjs';
import {CSS_LAYOUT_CONFIG, MEDIUM_CATEGORY_CONFIG} from '../../../consts';
import {environment} from '../../../../../environments/environment';
import {MessageBoxTypeEnum} from '../../../enum/message-box-type-enum';
import {FacadeService} from '../../../../services/facade.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FormType} from '../../../enum/form-type-enum';
import {DialogType} from '../../../enum/dialog-type-enum';
import {DeleteDialogComponent} from '../../dialog/delete-dialog/delete-dialog.component';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Mapper} from '../../../classes/mapper';
import {DateHelper} from '../../../classes/date-helper';
import {VisualComponent} from '../../../classes/visual-component';
import {MediumCategoryEnum} from '../../../enum/medium-category-enum';
import {VisualComponentDialogComponent} from '../../dialog/visual-component-dialog/visual-component-dialog.component';

@Component({
  selector: 'app-visual-component-list-root',
  templateUrl: './visual-component-list-root.component.html',
  styleUrls: ['./visual-component-list-root.component.scss']
})
export class VisualComponentListRootComponent implements OnInit, OnChanges, OnDestroy  {
  @Input() collection: VisualComponent[];
  @Input() mediumCategory: MediumCategoryEnum | undefined;
  @Input() shop?: Shop;
  @Output() dialogEvent = new EventEmitter<IDialogData>();
  @Output() formDataEvent = new EventEmitter<IFormData<VisualComponent>>();
  subscriptions: Subscription[] = [];
  contentDataIsLoaded: boolean;
  headline: string;
  displayButtonRefreshOrder: boolean;
  selectedItem: VisualComponent;
  isContentNarrow: boolean;
  LAYOUT_CONFIG = CSS_LAYOUT_CONFIG;
  imageDirectoryPath = environment.flagsimagesDir;
  messageBoxType = MessageBoxTypeEnum;
  MEDIUM_CATEGORY_CONF: any;
  cssLayoutConfig;
  dateFormat: string;

  constructor(
      private fs: FacadeService,
      private dialog: MatDialog
  ) {
    this.cssLayoutConfig = CSS_LAYOUT_CONFIG;
  }

  ngOnInit(): void {
    this.fs.gridLayoutService.resizeContentCols();
    this.MEDIUM_CATEGORY_CONF = MEDIUM_CATEGORY_CONFIG.teaser_specials_sale;
    if (this.MEDIUM_CATEGORY_CONF) {
      this.headline = this.MEDIUM_CATEGORY_CONF.HEADLINE_LIST_UNIT;
    }
    this.dateFormat = DateHelper.DATE_FORMATS.de.dateAndTime;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shop) {
      if (changes.shop.currentValue) {
        this.contentDataIsLoaded = true;
      }
    } else {
      this.contentDataIsLoaded = true;
    }
  }

  onUpdateOrderIndex(): void {
    this.collection.forEach((item, orderIndex) => {
      const visualComponent = this.collection.find(obj => Number(obj.id) === Number(item.id));
      if (visualComponent) {
        if (visualComponent.orderIndex !== orderIndex) {
          visualComponent.orderIndex = orderIndex;
          this.formDataEvent.emit({formType: FormType.UPDATE, obj: visualComponent} as IFormData<VisualComponent>);
        }
      } else {
        item.orderIndex = orderIndex;
        this.formDataEvent.emit({formType: FormType.INSERT, obj: visualComponent} as IFormData<VisualComponent>);
      }
    });
    this.displayButtonRefreshOrder = false;
  }

  onSelectItem(id: number | string): void {
    this.selectedItem = this.collection.find(obj => obj.id === id);
  }

  onEditItem(e: Event, id: number | string): void {
    e.preventDefault();
    this.onSelectItem(id);
    const config = {
      title: this.MEDIUM_CATEGORY_CONF.HEADLINE_CONFIGURE_UNIT,
      dialogClass: VisualComponentDialogComponent,
      dialogType: DialogType.UPDATE_DIALOG,
      obj: this.selectedItem,
    } as IDialogData;
    this.openDialog(config);
  }

  onCreateItem(e: Event): void {
    e.preventDefault();
    const item = Mapper.createVisualComponent();
    item.mediumCategoryId = this.mediumCategory;

    item.shopId = this.shop ? this.shop.id : null;
    item.orderIndex = this.collection.length || 0;

    const config = {
      title: this.MEDIUM_CATEGORY_CONF.HEADLINE_ADD_NEW_UNIT,
      dialogClass: VisualComponentDialogComponent,
      dialogType: DialogType.INSERT_DIALOG,
      obj: item,
    } as IDialogData;
    this.openDialog(config);
  }

  onDeleteItem(e: Event): void {
    e.preventDefault();
    if (this.selectedItem) {
      const config = {
        title: this.MEDIUM_CATEGORY_CONF.HEADLINE_DELETE_UNIT,
        dialogClass: DeleteDialogComponent,
        dialogType: DialogType.DELETE_DIALOG,
        obj: this.selectedItem,
        headline: `Do you really want to delete this ${this.MEDIUM_CATEGORY_CONF.HEADLINE}?`,
        displayActionBtn: true
      } as IDialogData;
      this.openDialog(config);
    }
  }

  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(config.dialogClass, {
      data: config
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.dialogType && res.data) {
        this.dialogEvent.emit(res as IDialogData);
      }
    });
  }

  onToggleResizeContent(): void {
    this.isContentNarrow = !this.isContentNarrow;
    this.fs.gridLayoutService.resizeContentColLeft(this.isContentNarrow);
  }

  getImagePath(countryCode: string): string {
    return `${this.imageDirectoryPath}flag_${countryCode}.jpg`;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  drop(event: CdkDragDrop<VisualComponent[]>): void {
    if (event.previousContainer === event.container) {
      if (event.previousIndex !== event.currentIndex) {
        this.displayButtonRefreshOrder = true;
        moveItemInArray(event.previousContainer.data, event.previousIndex, event.currentIndex);
      }
    } else {
      if (this.collection.map(obj => obj.id).indexOf(event.item.data.id) === -1) {
        transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
      }
    }
  }
}
