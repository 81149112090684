import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, EventEmitter,
  Input, OnChanges, OnDestroy,
  OnInit, Output,
  SimpleChanges
} from '@angular/core';
import {Subscription} from 'rxjs';
import {FacadeService} from '../../../../services/facade.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {MessageBoxTypeEnum} from '../../../enum/message-box-type-enum';
import {INFO_TEXT} from '../../../consts';
import {VisualComponent} from '../../../classes/visual-component';
import {environment} from '../../../../../environments/environment';
import {Translation} from "../../../classes/translation";
import {TranslationTextType} from "../../../enum/translation-text-type-enum";

@Component({
  selector: 'app-visual-component-wysiwyg-texteditor',
  templateUrl: './visual-component-wysiwyg-texteditor.component.html',
  styleUrls: ['./visual-component-wysiwyg-texteditor.component.scss']
})
export class VisualComponentWysiwygTexteditorComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy  {
  @Input() entity: VisualComponent;
  @Input() languageTabIndex = 0;
  @Output() languageTabIndexEvent = new EventEmitter<number>();
  INFO_TEXT = INFO_TEXT;
  messageBoxType = MessageBoxTypeEnum;
  containerWidth: number;
  subscriptions: Subscription[] = [];
  contentWidthSubscription: Subscription | undefined;
  imagesUrl: string;

  constructor(
      private fs: FacadeService,
      private cdr: ChangeDetectorRef
  ) {
    this.containerWidth = 750;
    this.imagesUrl = environment.imagesUrl;
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.initTranslations(this.languageTabIndex);

    }
  }

  ngAfterViewInit(): void {
    this.contentWidthSubscription = this.fs.gridLayoutService.adaptationCol$.subscribe(width => {
      if (this.containerWidth !== width) {
        this.containerWidth = width;
        this.cdr.detectChanges();
      }
    });
    this.subscriptions.push(this.contentWidthSubscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  onTabChange(event: MatTabChangeEvent): void {
    if (this.languageTabIndex !== event.index) {
      this.languageTabIndex = event.index;
      this.languageTabIndexEvent.emit(this.languageTabIndex);
    }
  }

  getImagePath(imageName: string): string {
    return `${this.imagesUrl}/${imageName}`;
  }

  getLocale(t: Translation): string {
    return (t.languageCode === 'en' && ! t.countryCode) ? 'en' : `${t.languageCode}-${t.countryCode}`;
  }

  private initTranslations(index: number): void {
    if (! this.entity) { return; }
 }
}
