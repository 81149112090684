<ng-container>
  <mat-accordion id="side-nav">
    <mat-nav-list>
        <span *ngFor="let item of menu">
          <span *ngIf="!item.children || item.children.length === 0">
            <mat-list-item *ngIf="item.display" [routerLink]="item.route" routerLinkActive="active">
              <div fxLayout="row" fxLayoutAlign="start center">
                <mat-icon *ngIf="item.iconName">{{item.iconName}}</mat-icon>
                <span class="nav-item-title">{{item.displayName}}</span>
              </div>
            </mat-list-item>
          </span>
          <span *ngIf="item.children && item.children.length > 0">
            <mat-accordion *ngIf="item.display">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-icon *ngIf="item.iconName">{{item.iconName}}</mat-icon>
                      <span class="nav-item-title">{{item.displayName}}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <span *ngFor="let child of item.children">
                  <mat-list-item *ngIf="child.display" [routerLink]="child.route" routerLinkActive="active">
                    <div fxLayout="row" fxLayoutAlign="start center">
                      <mat-icon *ngIf="item.iconName">{{child.iconName}}</mat-icon>
                      <span class="nav-item-title">{{child.displayName}}</span>
                    </div>
                  </mat-list-item>
                </span>
              </mat-expansion-panel>
            </mat-accordion>
          </span>

        </span>
    </mat-nav-list>
  </mat-accordion>
</ng-container>
