import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {Shop} from '../../classes/shop';

@Component({
  selector: 'app-language-filter',
  templateUrl: './language-filter.component.html',
  styleUrls: ['./language-filter.component.scss']
})
export class LanguageFilterComponent implements OnInit, OnDestroy {
  @Input() settings: any;
  @Output() formDataEvent = new EventEmitter<any>();
  form: FormGroup;
  submitted = false;
  subscriptions: Subscription[] = [];
  display: boolean;

  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
      this.form = this.initForm();
  }

  initForm(): FormGroup {
    return this.fb.group({
      selectedShop: [this.settings.selectedShop],
      selectedLanguageCode: [this.settings.selectedLanguageCode]
    });
  }

  onChangeShop(shop: Shop): void {
    this.settings.countryCode = shop ? shop.countryCode : null;
  }

  onSubmit(): void {
    if (this.form.valid) {
      const settings = {...this.settings, ...this.form.value};
      this.formDataEvent.emit(settings);
      this.submitted = true;
    }
  }

  onResetForm(e): void {
    e.preventDefault();
    this.form.reset();
    this.onSubmit();
  }

  onToggleFilter(): void {
    this.display = !this.display;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
