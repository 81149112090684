import {Component, Input, OnInit} from '@angular/core';
import {Shop} from '../../classes/shop';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FacadeService} from '../../../services/facade.service';
import {MediumUnit} from '../../classes/medium-unit';
import {DialogType} from '../../enum/dialog-type-enum';
import {MediumUnitInfoListDialogComponent} from '../dialog/medium-unit-info-list-dialog/medium-unit-info-list-dialog.component';
import {IDialogData} from '../../interfaces/idialog-data';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';

@Component({
  selector: 'app-dashboard-teaser',
  templateUrl: './dashboard-teaser.component.html',
  styleUrls: ['./dashboard-teaser.component.scss']
})
export class DashboardTeaserComponent implements OnInit {
  @Input() display: boolean;
  @Input() shops: Shop[] = [];
  form: FormGroup;
  config: any;
  selectedShop: Shop;
  data = {
    all: [],
    active: [],
    inactive: [],
    global: [],
    individual: [],
    selectedShop: {
      all: [],
      active: [],
      inactive: []
    }
  };

  constructor(
      private fb: FormBuilder,
      private fs: FacadeService,
      private dialog: MatDialog
  ) {
    this.config = {title: 'Teaser', icon: 'view_quilt', buttonLabel: 'Configure Teaser', route: 'section-teaser'};
  }

  ngOnInit(): void {
    this.selectedShop = this.shops.find(s => s.country === 'Germany');

    const data = this.fs.mediumUnitService.getAllSubjects().filter(s => s.mediumCategoryId === 2);
    this.data.all = data;
    this.data.active = data.filter(s => s.active === true);
    this.data.inactive = data.filter(s => s.active === false);
    this.data.global = data.filter(s => s.shopId === null || s.isDefault);
    this.data.individual = data.filter(s => s.shopId !== null && s.isDefault === false);
    this.loadShopData(this.selectedShop.id);
    this.form = this.initForm();
  }

  initForm(): FormGroup {
    const fb = this.fb.group({
      selectedShop: [this.selectedShop],
    });
    fb.get('selectedShop').valueChanges
        .subscribe(shop => {
          this.selectedShop = shop;
          this.loadShopData(shop.id);
        });
    return fb;
  }

  loadShopData(id: number): void {
    this.data.selectedShop.all = this.data.all.filter(s => s.shopId === id);
    this.data.selectedShop.active = this.data.active.filter(s => s.shopId === id);
    this.data.selectedShop.inactive = this.data.inactive.filter(s => s.shopId === id);
  }
  onOpenInfoDialog(collection: MediumUnit[]): void {
    if (! collection.length) { return; }
    const sTitle = 'Info ' + this.config.title;
    const dialogConf = {
      dialogType: DialogType.INFO_DIALOG,
      title: sTitle,
      list: collection,
      dialogClass: MediumUnitInfoListDialogComponent,
      dialogDimensions: {width: '1400px', height: 'auto'}
    } as IDialogData;
    this.openDialog(dialogConf);
  }

  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(config.dialogClass, {data: config});
  }
}
