<h2 mat-dialog-title>{{data.title}}</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div mat-dialog-content>
      <input type="hidden" formControlName="id">

      <p *ngIf="data.headline">{{data.headline}}</p>
      <p *ngIf="data.text">{{data.text}}</p>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-stroked-button type="submit" *ngIf="data.displayActionBtn">{{data.btnLabel || 'Delete'}}</button>
  </mat-dialog-actions>
</form>

