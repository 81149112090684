<mat-form-field appearance="standard" floatLabel="always">
    <mat-toolbar>
        <input matInput id="matInput" [value]="selectedFileName" />
        <button mat-stroked-button
                debounceClick
                [debounceTime]="500"
                [disabled]="! selectedImage"
                (onEvent)="onUpload()">Upload
        </button>
    </mat-toolbar>
    <input
      type="file"
      id="fileInput"
      (change)="onSelectFile($event)"
      name="fileInput"
      accept="image/*"
    />
</mat-form-field>

<app-message-box *ngIf="errorMessage"
                 [type]="MessageBoxType.Danger"
                 [message]="errorMessage"
></app-message-box>


