import {Translation} from './translation';
import {IObject} from '../interfaces/iobject';
import {IKeyValuePair} from '../interfaces/i-key-value-pair';
import {MediumCategory} from './medium-category';
import {TranslateUrl} from './translateUrl';

export class VisualComponent implements IObject{
  constructor(
    public id?: number,
    public name?: string,
    public mediumCategoryId?: number,
    public shopId?: number,
    public url?: string,
    public campaign?: string,
    public orderIndex?: number,
    public startDate?: string,
    public endDate?: string,
    public weekdays?: number,
    public image?: string,
    public imageMobile?: string,
    public imageAlt?: string,
    public cssStyles?: string,
    public mappedCssStyles?: IKeyValuePair[],
    public translations?: string,
    public mappedTranslations?: Translation[] | undefined,
    public translateUrls?: string,
    public mappedTranslateUrls?: TranslateUrl[],
    public archived?: boolean,
    public active?: boolean,
    public insertTs?: string,
    public updateTs?: string,
    public mediumCategory?: MediumCategory,
  ) {}
}
