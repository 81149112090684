import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
    constructor(
        private userService: UserService,
        private router: Router
    ) {
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((err) => {
                switch (err.status) {
                    case 401:
                        this.userService.logout();
                        break;
                    case 404:
                        this.router.navigate(['not-found']);
                        break;
                    default:
                        if (!environment.production) {
                            console.error(err);
                        }
                        break;
                }
                return throwError(err);
            })
        );
    }
}
