// @ts-ignore
import * as _moment from 'moment';
import {IDateRange} from '../interfaces/idate-range';
const moment = _moment;

export class DateHelper {
  static DATE_FORMATS = {
    parse: {
      dateInput: 'DD.MM.YYYY',
      dateOutput: 'YYYY-MM-DD',
    },
    display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY',
      date: 'dd.MM.YYYY',
      dateAndTime: 'dd.MM.YYYY HH:mm'
    },
    de: {
      date: 'dd.MM.yyyy',
      dateAndTime: 'dd.MM.YYYY HH:mm'
    },
    en: {
      date: 'yyyy-MM-dd',
      dateAndTime: 'yyyy-MM-dd HH:mm'
    }
  };

  static DATE_TIME_FORMATS = {
    parse: {
      dateInput: 'l, LTS',
      dateOutput: 'YYYY-MM-DD HH:mm'
    },
    display: {
      dateInput: 'YYYY-MM-DD HH:mm',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
      date: 'dd.MM.YYYY HH:mm'
    },
    de: {
      date: 'dd.MM.yyyy HH:mm'
    },
    en: {
      date: 'yyyy-MM-dd HH:mm'
    }
  };

  static loadDateRanges<T extends { id: number | string, startDate: string, endDate: string }>(
    collection: T[],
    skipIds: number[] = []
  ): IDateRange[] {
    let dateRanges: IDateRange[] = [];
    if (collection && collection.length) {
      dateRanges = collection
        .filter(obj => ! skipIds.includes(Number(obj.id)))
        .filter(obj => DateHelper.isValidDateRange(obj.startDate, obj.endDate))
        .map(obj => {
          return DateHelper.createDateRange(obj.startDate, obj.endDate);
        });
    }
    return dateRanges;
  }

  static dateRangesOverlaps(range1: IDateRange, range2: IDateRange ): boolean {
    return (
      range1.from.isBetween(range2.from, range2.to, null, '[]') || range1.to.isBetween(range2.from, range2.to, null, '[]') ||
      range1.from.isBefore(range2.from) && range1.to.isAfter(range2.to) ||
      range2.from.isBefore(range1.from) && range2.to.isAfter(range1.to)
    );
  }

  static isValidDateRange(startDate: string, endDate: string): boolean {
    const sDate = moment(startDate);
    const eDate = moment(startDate);
    return sDate.isValid() && eDate.isValid() && sDate.isSameOrBefore(endDate);
  }

  static isValidDate(date: Date): boolean {
    return moment(date).isValid();
  }

  static createDateRange(startDate: string, endDate: string): IDateRange {
    return {
      from: moment(startDate),
      to: moment(endDate)
    };
  }

  static isDateOutOfDateRange(startDate: Date, endDate: Date, date: Date): boolean {
    return moment(startDate).isAfter(date) || moment(endDate).isBefore(date);
  }

  static formatDate(date: Date, format: string): string {
    return moment(date).format(format);
  }

  static isMonthAndYearActual(date: string = ''): boolean {
    const actual = true;
    if (! date) { return actual; }
    const currentDate = new Date();
    return moment(date).isSameOrAfter(currentDate, 'month') && moment(date).isSameOrAfter(currentDate, 'year');
  }
}
