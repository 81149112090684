export enum UserRole {
    ROLE_CONFIGURE_USER = 'ROLE_CONFIGURE_USER',
    ROLE_CONFIGURE_SLIDER = 'ROLE_CONFIGURE_SLIDER',
    ROLE_CONFIGURE_SLIDER_ARCHIVE = 'ROLE_CONFIGURE_SLIDER_ARCHIVE',
    ROLE_CONFIGURE_TEASER = 'ROLE_CONFIGURE_TEASER',
    ROLE_CONFIGURE_TEASER_ARCHIVE = 'ROLE_CONFIGURE_TEASER_ARCHIVE',
    ROLE_CONFIGURE_INFOBOX = 'ROLE_CONFIGURE_INFOBOX',
    ROLE_CONFIGURE_INFOBOX_ARCHIVE = 'ROLE_CONFIGURE_INFOBOX_ARCHIVE',
    ROLE_CONFIGURE_TEASER_SPECIALS = 'ROLE_CONFIGURE_TEASER_SPECIALS',
    ROLE_CONFIGURE_TEASER_SPECIALS_ARCHIVE = 'ROLE_CONFIGURE_TEASER_SPECIALS_ARCHIVE'
}

