<div class="mt-2">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <input type="hidden" formControlName="id">

        <div
                fxLayout="column"
                fxLayoutAlign="flex-start">

            <div formGroupName="dateRange" fxFlex="100%" fxLayoutGap="10px">
                <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 50%">
                    <input matInput [ngxMatDatetimePicker]="pickerStart" formControlName="startDate" placeholder="Start date">
                    <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerStart
                                             [showSeconds]="showSeconds"
                                             [stepMinute]="stepMinutes"
                                             [stepSecond]="stepSeconds"
                    ></ngx-mat-datetime-picker>
                </mat-form-field>
                <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 50%">
                    <input matInput [ngxMatDatetimePicker]="pickerEnd" formControlName="endDate" placeholder="End date">
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <ngx-mat-datetime-picker #pickerEnd
                                             [showSeconds]="showSeconds"
                                             [stepMinute]="stepMinutes"
                                             [stepSecond]="stepSeconds"
                    ></ngx-mat-datetime-picker>
                </mat-form-field>
            </div>
            <mat-error *ngIf="registerFormControl.dateRange.hasError('dateRangeInvalid')" class="mb-2">
                Invalid date range
            </mat-error>

            <ng-container>
                <div formArrayName="weekdays"
                     class="col weekdays-wrapper mb-2"
                     fxLayout="row wrap"
                     fxLayoutAlign="flex-start"
                >
                    <ng-container *ngFor="let day of weekdays.controls; let i = index">
                        <mat-checkbox
                                fxFlex="90px"
                                fxLayoutGap="20px"
                                [formControl]="day">
                            {{weekDaysData[i].shortName}}
                        </mat-checkbox>
                    </ng-container>
                </div>
            </ng-container>

            <mat-checkbox class="mt-2" formControlName="active">Active</mat-checkbox>
            <div class="mt-4">
                <button mat-stroked-button
                        debounceClick
                        [debounceTime]="500"
                        [disabled]="! form.valid"
                        (onEvent)="onSubmitHandler($event)">Save
                </button>
            </div>
        </div>
        <br>
    </form>
</div>


