import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from '../../classes/message';
import {MessageService} from '../../../services/message.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit, OnDestroy {
  public messages: Message[] = [];
  private subscriptions: Subscription[] = [];

  constructor(private messageService: MessageService) {
    const subscription: Subscription = this.messageService.messages$.subscribe(
        newMessages => {
          this.messages.length = 0;
          if (newMessages !== undefined){
            newMessages.forEach(message => {
              this.messages.push(message);
            });
          }
        }
    );
    this.subscriptions.push(subscription);
  }

  ngOnInit(): void {}

  public closeMessage(message: Message): void {
    this.messageService.remove(message);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
