import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressBarService {
  private data = new BehaviorSubject<number>(0);
  private interval;
  private currentProgress = 0;
  private step = 0.5;
  progressBar$: Observable<number> = this.data.asObservable();

  constructor() {}

  start(): void {
    this.reset();
    this.interval = setInterval(() => {
      this.currentProgress += this.step;
      const progress = Math.round(Math.atan(this.currentProgress) / (Math.PI / 2) * 100 * 1000) / 1000;
      if (progress >= 100){
        clearInterval(this.interval);
      } else if (progress >= 70) {
        this.step = 0.1;
      }
      this.data.next(progress);
    }, 120);
  }

  complete(): void {
    this.data.next(100);
    this.reset();
  }

  reset(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.currentProgress = 0;
  }
}
