import {AfterContentChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from 'src/app/shared/enum/dialog-type-enum';
import {ICheckboxItem} from 'src/app/shared/interfaces/icheckbox-item';
import {IDialogData} from 'src/app/shared/interfaces/idialog-data';
import {DateRangeValidator} from 'src/app/shared/formValidators/date-range-validator';
import {WeekdaysHelper} from 'src/app/shared/classes/weekdays-helper';
import {DateHelper} from 'src/app/shared/classes/date-helper';
import {FacadeService} from 'src/app/services/facade.service';
import {Subscription} from 'rxjs';
import {DIALOG_DIMENSIONS, MEDIUM_CATEGORY_CONFIG} from '../../../consts';
import {Mapper} from '../../../classes/mapper';
import {Translation} from '../../../classes/translation';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {DateTimeAdapter} from '../../../classes/date-time-adapter';
// @ts-ignore
import {Moment} from 'moment';
import * as _moment from 'moment';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {VisualComponent} from '../../../classes/visual-component';
const moment = _moment;

@Component({
  selector: 'app-visual-component-dialog',
  templateUrl: './visual-component-dialog.component.html',
  styleUrls: ['./visual-component-dialog.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: DateHelper.DATE_TIME_FORMATS},
    { provide: NgxMatDateAdapter, useClass: DateTimeAdapter},
  ]
})
export class VisualComponentDialogComponent implements OnInit, OnDestroy, AfterContentChecked {
  subscription: Subscription[] = [];
  form: FormGroup;
  submitted = false;
  entity: VisualComponent;
  dialogType: DialogType;
  displayDateRange: boolean;
  displayWeekdays: boolean;
  unavailableDateRanges: { from: Moment, to: Moment }[] = [];
  validators = {dateRange: [], isDefaultCheckbox: []};
  weekDaysData: ICheckboxItem[] = [];
  dateFormat = DateHelper.DATE_FORMATS.display.dateAndTime;
  MEDIUM_CATEGORY_CONF: any;
  showSeconds: boolean;
  stepMinutes = 5;
  stepSeconds = 10;

  constructor(
      private ref: ChangeDetectorRef,
      private fs: FacadeService,
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<VisualComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
    this.entity = data.obj;
    this.dialogType = data.dialogType;
    this.weekDaysData = WeekdaysHelper.weekdays;
  }

  ngOnInit(): void {
    this.MEDIUM_CATEGORY_CONF = MEDIUM_CATEGORY_CONFIG['section-teaser-specials'];
    this.displayDateRange = true;
    this.displayWeekdays = this.displayWeekdaysFormSection();
    this.initForm();

    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscription.forEach(obj => obj.unsubscribe());
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const entity: VisualComponent = {...this.entity};
      Mapper.mapFormGroup(entity, this.form);
      this.dialogRef.close({dialogType: this.dialogType, data: entity});
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }

  get weekdays(): FormArray {
    return this.form.get('weekdays') as FormArray;
  }

  rangeFilter = (date: Date): boolean => {
    return this.isDateAvailable(date);
  }

  handleDateChange(): void {
    const dateRange = this.form.value.dateRange;
    const isValid = DateHelper.isValidDateRange(dateRange.startDate, dateRange.endDate);
    if (isValid) {
      this.displayWeekdays = true;
    }
  }

  handleWeekday(): void {
    WeekdaysHelper.getSumOfSelectedWeekdays(this.weekdays.value);
  }

  onChangeIsArchive(event: MatCheckboxChange): void {
    const isActiveElem = this.form.controls.active;
    if (event.checked) {
      isActiveElem.setValue(false);
      isActiveElem.disable();
    }
    else {
      isActiveElem.enable();
    }
  }

  private isDateAvailable(date: Date): boolean {
    const mDate = moment(date);
    if (this.unavailableDateRanges.length) {
      for (const d of this.unavailableDateRanges) {
        if (mDate.isSameOrAfter(d.from) && mDate.isSameOrBefore(d.to)) {
          return false;
        }
      }
    }
    return true;
  }

  private createWeekdaysFormArray(items: ICheckboxItem[]): FormArray {
    const arr = items.map(obj => {
      return new FormControl({
        value: WeekdaysHelper.isWeekdaySelected(obj.key, this.entity.weekdays),
        disabled: false
      });
    });
    return new FormArray(arr);
  }

  private displayWeekdaysFormSection(): boolean {
    let display = true;
    if (!this.entity.startDate || !this.entity.endDate) {
      display = false;
    }
    return display;
  }

  private buildTranslationArray(values: Translation[]): FormArray {
    return this.fb.array(
        values.map(t => this.fb.group(t))
    );
  }

  private initForm(): void {
    const translationsArray = this.buildTranslationArray(this.entity.mappedTranslations);
    const startDate = this.entity.startDate ? new Date(this.entity.startDate) : null;
    const endDate = this.entity.endDate ? new Date(this.entity.endDate) : null;
    this.form = this.fb.group({
      id: [this.entity.id],
      name: [this.entity.name, Validators.required],
      mediumCategoryId: [this.entity.mediumCategoryId, Validators.required],
      shopId: [this.entity.shopId],
      mappedTranslations: translationsArray,
      active: [this.entity.active],
      archived: [this.entity.archived],
      dateRange: this.fb.group({
            startDate: [startDate, Validators.required],
            endDate: [endDate, Validators.required]
          }, {validators: [DateRangeValidator.checkDateRange()]}
      ),
      weekdays: this.createWeekdaysFormArray(this.weekDaysData),
    });


    if (this.entity.archived) {
      this.form.controls.active.setValue(false);
      this.form.controls.active.disable();
    }
  }
}
