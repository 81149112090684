import {FormGroup, ValidatorFn } from '@angular/forms';
// @ts-ignore
import * as _moment from 'moment';
// @ts-ignore
import { Moment } from 'moment';
const moment = _moment;

export class DateRangeValidator {
  static checkDateRange(
    errorName: string = 'dateRangeInvalid'
  ): ValidatorFn {
    // @ts-ignore
    return (fg: FormGroup): { [key: string]: boolean } | null => {
      const propStartDate = 'startDate';
      const propEndDate = 'endDate';
      const startDate = fg.controls[propStartDate];
      const endDate = fg.controls[propEndDate];
      if ((startDate.value && ! endDate.value) || (! startDate.value && endDate.value)) {
        return {[errorName]: true};
      }
      if (startDate.value && endDate.value) {
        const sDate = moment(startDate.value);
        const eDate = moment(endDate.value);
        if (! sDate.isValid() || ! eDate.isValid() || ! sDate.isSameOrBefore(eDate)) {
          return {[errorName]: true};
        }
      }
      return null;
    };
  }

  static checkDateRangeOverlap(
    unavailableDateRanges: {from: Moment, to: Moment}[] = [],
    errorName: string = 'dateRangeOverlap'
  ): ValidatorFn {
    // @ts-ignore
    return (fg: FormGroup): { [key: string]: boolean } | null => {
      if (unavailableDateRanges.length > 0 && fg.controls) {
        const propStartDate = 'startDate';
        const propEndDate = 'endDate';
        const startDate = fg.controls[propStartDate];
        const endDate = fg.controls[propEndDate];
        const sDate = moment(startDate.value);
        const eDate = moment(endDate.value);
        if (startDate && endDate) {
          for (const d of unavailableDateRanges) {
            if (
              sDate.isSameOrAfter(d.from) && sDate.isSameOrBefore(d.to) || eDate.isSameOrAfter(d.from) && eDate.isSameOrBefore(d.to) ||
              (sDate.isBefore(d.from) && eDate.isAfter(d.to))
            ) {
              return {[errorName]: true};
            }
          }
        }
      }
      return null;
    };
  }
}
