import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material/material.module';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ResizableModule} from 'angular-resizable-element';
import {HttpClientModule} from '@angular/common/http';
import {AngularEditorModule} from '@kolkov/angular-editor';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MediumUnitListComponent} from './components/medium-unit-list/medium-unit-list.component';
import {StripHtmlPipe} from './pipes/strip-html.pipe';
import {SafeHtmlPipe} from './pipes/safe-html.pipe';
import {DeleteDialogComponent} from './components/dialog/delete-dialog/delete-dialog.component';
import {MediumUnitDialogComponent} from './components/dialog/medium-unit-dialog/medium-unit-dialog.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {RouterModule} from '@angular/router';
import {MediumListComponent} from './components/medium-list/medium-list.component';
import {MediumDialogComponent} from './components/dialog/medium-dialog/medium-dialog.component';
import {MediumAdaptationContentComponent} from './components/medium-adaptation-content/medium-adaptation-content.component';
import {MediumInfoComponent} from './components/medium-info/medium-info.component';
import {MediumEditComponent} from './components/medium-edit/medium-edit.component';
import {MediumConfigureComponent} from './components/medium-configure/medium-configure.component';
import {ImageLoaderComponent} from './components/image-loader/image-loader.component';
import {MediumWysiwygTexteditorComponent} from './components/medium-wysiwyg-texteditor/medium-wysiwyg-texteditor.component';
import {DateRangeInfoComponent} from './components/date-range-info/date-range-info.component';
import {WeekdaysInfoComponent} from './components/weekdays-info/weekdays-info.component';
import {TeaserHorizontalListComponent} from './components/teaser-horizontal-list/teaser-horizontal-list.component';
import {MediumAdaptationRootComponent} from './components/medium-adaptation-root/medium-adaptation-root.component';
import {MediumCategoryPageSelectionComponent} from './components/medium-shop-category-selection/medium-category-page-selection.component';
import {InfoDialogComponent} from './components/dialog/info-dialog/info-dialog.component';
import {SliderMergedListComponent} from './components/slider-merged-list/slider-merged-list.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MediumInfoDialogComponent} from './components/dialog/medium-info-dialog/medium-info-dialog.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImageCropperComponent } from './components/image-cropper/image-cropper.component';
import { FormImageLoaderComponent } from './components/form-image-loader/form-image-loader.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { ImageContainerComponent } from './components/image-container/image-container.component';
import { ArchiveFilterComponent} from './components/filter/archive-filter/archive-filter.component';
import { MediumColunitPipe } from './pipes/medium-colunit.pipe';
import { MediumCopyDialogComponent } from './components/dialog/medium-copy-dialog/medium-copy-dialog.component';
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';
import { ChangePasswordFormComponent } from '../user/components/change-password-form/change-password-form.component';
import { WeekdayBadgesListComponent } from './components/weekday-badges-list/weekday-badges-list.component';
import { MessagesComponent } from './components/messages/messages.component';
import {TranslationUrlListDialogComponent} from './components/dialog/translation-url-list-dialog/translation-url-list-dialog.component';
import {MatTableComponent} from './components/mat-table/mat-table.component';
import {ActionButtonsComponent} from './components/action-buttons/action-buttons.component';
import {TableActionDirective} from './directives/table-action.directive';
import {LoadTranslationUrlsComponent} from './components/load-translation-urls/load-translation-urls.component';
import { TranslationUrlList } from './components/translation-url-list/translation-url-list.component';
import {LanguageFilterComponent} from './components/language-filter/language-filter.component';
import {AddTranslationUrlsComponent} from './components/add-translation-urls/add-translation-urls.component';
import {TranslationUrlDialogComponent} from './components/dialog/translation-url-dialog/translation-url-dialog.component';
import {RxReactiveFormsModule} from '@rxweb/reactive-form-validators';
import {DebounceClickDirective} from './directives/debounce-click.directive';
import { InfoboxListRootComponent } from './components/infobox-list-root/infobox-list-root.component';
import {InfoboxDialogComponent} from './components/dialog/infobox-dialog/infobox-dialog.component';
import { InfoboxAdaptationRootComponent } from './components/infobox-adaptation-root/infobox-adaptation-root.component';
import { InfoboxAdaptationContentComponent } from './components/infobox-adaptation-content/infobox-adaptation-content.component';
import { InfoboxInfoComponent } from './components/infobox-info/infobox-info.component';
import { InfoboxWysiwygTexteditorComponent } from './components/infobox-wysiwyg-texteditor/infobox-wysiwyg-texteditor.component';
import { InfoboxConfigureComponent } from './components/infobox-configure/infobox-configure.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardSliderComponent } from './components/dashboard-slider/dashboard-slider.component';
import { DashboardTeaserComponent } from './components/dashboard-teaser/dashboard-teaser.component';
import { DashboardInfoboxComponent } from './components/dashboard-infobox/dashboard-infobox.component';
import { DashboardUserComponent } from './components/dashboard-user/dashboard-user.component';
import { MediumUnitInfoListDialogComponent } from './components/dialog/medium-unit-info-list-dialog/medium-unit-info-list-dialog.component';
import { InfoboxInfoListDialogComponent } from './components/dialog/infobox-info-list-dialog/infobox-info-list-dialog.component';
import { UserInfoListDialogComponent } from './components/dialog/user-info-list-dialog/user-info-list-dialog.component';
import {MediumImportDialogComponent} from './components/dialog/medium-import-dialog/medium-import-dialog.component';
import {MediumWysiwygTexteditorCaptionboxFormComponent} from './components/medium-wysiwyg-texteditor-captionbox-form/medium-wysiwyg-texteditor-captionbox-form.component';
import {MediumWysiwygTexteditorTextformatFormComponent} from './components/medium-wysiwyg-texteditor-textformat-form/medium-wysiwyg-texteditor-textformat-form.component';
import {ColorPickerModule} from 'ngx-color-picker';
import {MediumWysiwygTexteditorV2Component} from './components/medium-wysiwyg-texteditor-v2/medium-wysiwyg-texteditor-v2.component';
import {
    InfoboxImportComponent
} from './components/infobox-import/infobox-import.component';
import {DownloadXlsxComponent} from './components/download-xlsx/download-xlsx.component';
import { DynamicDialogComponent } from './components/dialog/dynamic-dialog/dynamic-dialog.component';
import {SmoothScrollbarDirective} from './directives/smooth-scrollbar.directive';
import {
    VisualComponentListRootComponent
} from './components/visual-component/visual-component-list-root/visual-component-list-root.component';
import {
    VisualComponentAdaptationRootComponent
} from './components/visual-component/visual-component-adaptation-root/visual-component-adaptation-root.component';
import {
    VisualComponentAdaptationContentComponent
} from './components/visual-component/visual-component-adaptation-content/visual-component-adaptation-content.component';
import {VisualComponentInfoComponent} from './components/visual-component/visual-component-info/visual-component-info.component';
import {VisualComponentEditComponent} from './components/visual-component/visual-component-edit/visual-component-edit.component';
import {
    VisualComponentConfigureComponent
} from './components/visual-component/visual-component-configure/visual-component-configure.component';
import {MediumListRootComponent} from './components/medium-list-root/medium-list-root.component';
import {
    VisualComponentWysiwygTexteditorComponent
} from './components/visual-component/visual-component-wysiwig-texteditor/visual-component-wysiwyg-texteditor.component';
import {
    VisualComponentDialogComponent
} from './components/dialog/visual-component-dialog/visual-component-dialog.component';
import {
    VisualComponentImportComponent
} from './components/visual-component/visual-component-import/visual-component-import.component';
import {StrongifyPipe} from './pipes/strongify.pipe';

@NgModule({
  providers: [SafeHtmlPipe, StripHtmlPipe],
  declarations: [
    DeleteDialogComponent,
    InfoDialogComponent,
    MediumUnitDialogComponent,
    TranslationUrlListDialogComponent,
    MediumUnitListComponent,
    PageNotFoundComponent,
    MediumListComponent,
    MediumDialogComponent,
    MediumAdaptationContentComponent,
    MediumInfoComponent,
    MediumEditComponent,
    LoadTranslationUrlsComponent,
    AddTranslationUrlsComponent,
    MediumConfigureComponent,
    ImageLoaderComponent,
    MediumWysiwygTexteditorComponent,
    MediumWysiwygTexteditorV2Component,
    SafeHtmlPipe,
    DateRangeInfoComponent,
    WeekdaysInfoComponent,
    TeaserHorizontalListComponent,
    MediumAdaptationRootComponent,
    MediumCategoryPageSelectionComponent,
    MediumCategoryPageSelectionComponent,
    SliderMergedListComponent,
    MediumInfoDialogComponent,
    ImageCropperComponent,
    FormImageLoaderComponent,
    MessageBoxComponent,
    ImageContainerComponent,
    ArchiveFilterComponent,
    MediumColunitPipe,
    MediumCopyDialogComponent,
    NoPermissionsComponent,
    ChangePasswordFormComponent,
    WeekdayBadgesListComponent,
    MessagesComponent,
    MatTableComponent,
    ActionButtonsComponent,
    TableActionDirective,
    TranslationUrlList,
    LanguageFilterComponent,
    TranslationUrlDialogComponent,
    DebounceClickDirective,
    InfoboxListRootComponent,
    InfoboxDialogComponent,
    InfoboxAdaptationRootComponent,
    InfoboxAdaptationContentComponent,
    InfoboxInfoComponent,
    InfoboxWysiwygTexteditorComponent,
    InfoboxConfigureComponent,
    DashboardComponent,
    DashboardSliderComponent,
    DashboardTeaserComponent,
    DashboardInfoboxComponent,
    DashboardUserComponent,
    MediumUnitInfoListDialogComponent,
    InfoboxInfoListDialogComponent,
    UserInfoListDialogComponent,
    MediumImportDialogComponent,
    MediumWysiwygTexteditorCaptionboxFormComponent,
    MediumWysiwygTexteditorTextformatFormComponent,
    InfoboxImportComponent,
    DownloadXlsxComponent,
    DynamicDialogComponent,
    SmoothScrollbarDirective,
    VisualComponentListRootComponent,
    VisualComponentAdaptationRootComponent,
    VisualComponentAdaptationContentComponent,
    VisualComponentInfoComponent,
    VisualComponentEditComponent,
    VisualComponentConfigureComponent,
    TranslationUrlListDialogComponent,
    MediumListRootComponent,
    VisualComponentWysiwygTexteditorComponent,
    VisualComponentDialogComponent,
    VisualComponentImportComponent,
    StrongifyPipe
  ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        MaterialModule,
        ResizableModule,
        HttpClientModule,
        AngularEditorModule,
        FlexLayoutModule,
        RouterModule,
        MatProgressBarModule,
        ImageCropperModule,
        RxReactiveFormsModule,
        ColorPickerModule,
        NgOptimizedImage
    ],
    exports: [
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        DragDropModule,
        FontAwesomeModule,
        ResizableModule,
        HttpClientModule,
        AngularEditorModule,
        FlexLayoutModule,
        MediumUnitListComponent,
        PageNotFoundComponent,
        MediumListComponent,
        MediumDialogComponent,
        InfoDialogComponent,
        MediumAdaptationContentComponent,
        TeaserHorizontalListComponent,
        MediumAdaptationRootComponent,
        MatProgressBarModule,
        ImageCropperModule,
        ArchiveFilterComponent,
        SafeHtmlPipe,
        MessageBoxComponent,
        MessagesComponent,
        LanguageFilterComponent,
        InfoboxListRootComponent,
        InfoboxAdaptationContentComponent,
        InfoboxAdaptationRootComponent,
        VisualComponentListRootComponent,
        MediumImportDialogComponent,
        ColorPickerModule,
        VisualComponentAdaptationRootComponent,
        VisualComponentAdaptationContentComponent,
        VisualComponentInfoComponent,
        VisualComponentEditComponent,
        VisualComponentConfigureComponent,
        TranslationUrlList,
        TranslationUrlDialogComponent,
        AddTranslationUrlsComponent,
        TranslationUrlListDialogComponent,
        MediumListRootComponent,
        VisualComponentWysiwygTexteditorComponent,
        StrongifyPipe
    ]
})
export class SharedModule {}
