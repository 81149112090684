import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FacadeService} from 'src/app/services/facade.service';
import {Subscription} from 'rxjs';
import {ImageType} from '../../../enum/image-type-enum';
import {Medium} from '../../../classes/medium';
import {IFormData} from '../../../interfaces/form-data';
import {FormType} from '../../../enum/form-type-enum';
import {Message} from '../../../classes/message';
import {VisualComponent} from '../../../classes/visual-component';

@Component({
  selector: 'app-visual-component-adaptation-root',
  templateUrl: './visual-component-adaptation-root.component.html',
  styleUrls: ['./visual-component-adaptation-root.component.scss']
})
export class VisualComponentAdaptationRootComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  entity: VisualComponent | undefined;
  constructor(
    private fs: FacadeService,
    private route: ActivatedRoute,
    private router: Router
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = +params.id;
      this.entity = this.fs.visualComponentService.getOneSubject(id);
      if (! this.entity) {
        this.router.navigate(['/']);
      }
    });
  }
  onImageEvent(data: IFormData<VisualComponent>): void {
    if (data.obj && this.entity && this.entity.id) {
      if (this.entity.id !== data.obj.id) {
        return;
      }
      const imageType = data.imageType ? data.imageType : ImageType.DESKTOP_LANDSCAPE;
      switch (data.formType) {
        case FormType.INSERT:
          if (! data.image) { return; }
          this.fs.visualComponentService.imageUpload(this.entity.id, data.image, imageType).subscribe(
            (resp) => {
              if (resp.statusCode === 200 && resp.data) {
               switch (imageType) {
                  case ImageType.DESKTOP_LANDSCAPE:
                    if (this.entity) {
                      this.entity.image = resp.data;
                    }
                    break;
                  case ImageType.MOBILE_LANDSCAPE:
                    if (this.entity) {
                      this.entity.imageMobile = resp.data;
                    }
                    break;
               }
               if (this.entity) {
                 this.updateEntity(this.entity, 'Success! Upload Image');
               }
              }
            }, error => {
                this.errorHandling(error);
            });
          break;
        case FormType.DELETE:
          this.fs.visualComponentService.imageDelete(this.entity.id, imageType).subscribe(
            (resp) => {
              if (resp.statusCode === 200) {
                switch (imageType) {
                  case ImageType.DESKTOP_LANDSCAPE:
                    if (this.entity) {
                      this.entity.image = undefined;
                    }
                    break;
                  case ImageType.MOBILE_LANDSCAPE:
                    if (this.entity) {
                      this.entity.imageMobile = undefined;
                    }
                    break;
                }
                if (this.entity) {
                  this.updateEntity(this.entity, 'Success! Delete Image');
                }
              }
            }, error => {
                this.errorHandling(error);
              });
          break;
      }
    }
  }

  onSubmitForm(data: IFormData<Medium>): void {
    if (data.obj) {
      switch (data.formType) {
        case FormType.UPDATE:
            this.updateEntity(data.obj, 'Success! Update | ID: ' + data.obj.id);
            break;
      }
    }
  }

  private updateEntity(visualComponent: VisualComponent, message: string): void {
    this.fs.progeressBarService.start();
    this.fs.visualComponentService.update(visualComponent.id, visualComponent).subscribe(entity => {
      this.fs.visualComponentService.updateSubject(entity.id, entity);
      this.entity = entity;
      this.successHandling(message);
    }, error => {
      this.errorHandling(error);
    });
  }

  private successHandling(message: string): void {
    this.fs.messageService.add(new Message('success', message, true), 10000);
    this.fs.progeressBarService.complete();
  }

  private errorHandling(err: any): void {
    const message = err.error.description;
    this.fs.messageService.add(new Message('danger', message, true), 20000);
    this.fs.progeressBarService.complete();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(obj => obj.unsubscribe());
  }
}
