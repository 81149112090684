import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, delay, map, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {User} from '../shared/classes/user';
import {ApiResponse} from '../shared/interfaces/api-response';
import {BaseService} from './base-service';
import {UserRole} from '../shared/enum/user-role-enum';

@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseService<User> implements OnDestroy {
  private timer: Subscription;
  private user = new BehaviorSubject<User>(null);
  user$: Observable<User> = this.user.asObservable();

  constructor(
      private router: Router,
      protected override http: HttpClient,
  ) {
    super(http, `${environment.apiBaseUrl}/users`);
    window.addEventListener('storage', this.storageEventListener.bind(this));
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.storageEventListener.bind(this));
  }

  resetPassword(user: User): Observable<User> {
    return this.http.put<ApiResponse>(this.url + '/' + Number(user.id) + '/reset-password', user, {}).pipe(
        map((response: ApiResponse) => {
          return response.data;
        }),
        catchError(err => {
          return throwError(err.error);
        })
    );
  }

  changePassword(user: User, currentPassword: string, newPassword: string): Observable<boolean> {
    return this.http.put<ApiResponse>(this.url + '/' + user.id + '/change-password', {
      currentPassword,
      newPassword
    }).pipe(
        map(apiResponse => {
          return apiResponse.statusCode === 200;
        }),
        catchError(err => {
          return throwError(err.error);
        })
    );
  }

  login(email: string, password: string): Observable<User> {
    return this.http
      .post<ApiResponse>(`${this.url}/login`, {email, password})
      .pipe(
        map((apiResponse) => {
          this.user.next(apiResponse.data.user);
          this.setLocalStorage(apiResponse.data.token);
          this.startTokenTimer();
          return apiResponse.data.user;
        }),
        catchError(err => {
          this.logout();
          return throwError(err);
        })
      );
  }

  logout(): void {
    this.clearLocalStorage();
    this.user.next(null);
    this.stopTokenTimer();
    this.router.navigate(['login']);
  }

  refreshToken(): any {
    const refreshToken = localStorage.getItem('refresh_token');
    if (!refreshToken) {
      this.clearLocalStorage();
      return of(null);
    }

    return this.http
      .post<ApiResponse>(`${this.url}/refresh-token`, {refreshToken})
      .pipe(
          map((apiResponse) => {
            this.user.next(apiResponse.data.user);
            this.setLocalStorage(apiResponse.data.token);
            this.startTokenTimer();
            return apiResponse.data.user;
          }),
          catchError(err => {
            this.logout();
            return throwError(err);
          })
      );
  }

  setLocalStorage(res: any): void {
    localStorage.setItem('access_token', res.accessToken);
    localStorage.setItem('refresh_token', res.refreshToken);
    localStorage.setItem('login-event', 'login' + Math.random());
  }

  clearLocalStorage(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.setItem('logout-event', 'logout' + Math.random());
  }

  getUser(): User {
    const user = this.user.getValue();
    if (! user) {
      this.router.navigate(['login']);
      return;
    }
    return user;
  }

  hasShopPermission(shopId: number): boolean {
    const user = this.user.getValue();
    if (! user) {
      this.router.navigate(['login']);
      return;
    }

    if (user.admin || ! user.shop) {
      return true;
    }

    if (user.shop && user.shop.id === shopId) {
      return true;
    }
    return false;
  }

  userHasRole(role: UserRole): boolean {
    const user = this.getUser();
    return user.roles.some(r => r.name === role) || user.admin;
  }

  private storageEventListener(event: StorageEvent): void {
    if (event.storageArea === localStorage) {
      if (event.key === 'logout-event') {
        this.stopTokenTimer();
        this.user.next(null);
      }
    }
  }

  private getTokenRemainingTime(): number {
    const accessToken = localStorage.getItem('access_token');
    if (!accessToken) {
      return 0;
    }
    const jwtToken = JSON.parse(atob(accessToken.split('.')[1]));
    const expires = new Date(jwtToken.exp * 1000);
    return expires.getTime() - Date.now() - (60 * 1000);
  }

  private startTokenTimer(): void {
    const timeout = this.getTokenRemainingTime();
    this.timer = of(true)
      .pipe(
        delay(timeout),
        tap(() => this.refreshToken().subscribe())
      )
      .subscribe();
  }

  private stopTokenTimer(): void {
    this.timer?.unsubscribe();
  }
}
