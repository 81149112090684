import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {catchError, map, retry, share} from 'rxjs/operators';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import { ICrudMethods } from 'src/app/shared/interfaces/icrud-methods';
import {IObject} from '../shared/interfaces/iobject';
import {Injectable} from '@angular/core';
import {ApiResponse} from '../shared/interfaces/api-response';

const RETRY_SIZE = 1;
@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<T extends IObject> implements ICrudMethods<T> {
  protected data: Array<T> = [];
  protected subject = new BehaviorSubject<T[]>(this.data);
  observable$ = this.subject.asObservable();

  protected constructor(
    protected http: HttpClient,
    protected url: string
  ) {}

  findOne(id: number): Observable<T> {
    return this.http.get<ApiResponse>(this.url + '/' + Number(id)).pipe(
      share(),
      retry(RETRY_SIZE),
      map((response: ApiResponse) => {
        return response.data;
      }), catchError(this.errorHandler)
    );
  }

  findAll(): Observable<T[]> {
    return this.http.get<ApiResponse>(this.url).pipe(
      share(),
      retry(RETRY_SIZE),
      map((response: ApiResponse) => {
        return response.data;
      }), catchError(this.errorHandler)
    );
  }

  post<T>(t: T): Observable<T> {
    return this.http.post<ApiResponse>(this.url, t).pipe(
      map((response: ApiResponse) => {
        return response.data;
      }), catchError(this.errorHandler)
    );
  }

  update<T>(id: number, t: T): Observable<T> {
    return this.http.put<ApiResponse>(this.url + '/' + Number(id), t, {}).pipe(
      map((response: ApiResponse) => {
        return response.data;
      }), catchError(this.errorHandler)
    );
  }

  delete(id: number): Observable<T> {
    return this.http.delete<ApiResponse>(this.url + '/' + Number(id))
        .pipe(
            map((response: ApiResponse) => {
              return response.data;
            }), catchError(this.errorHandler)
        );
  }

  filterById(id: number): Observable<T> {
    return this.observable$.pipe(
      map(item => item.find(obj => obj.id == id))
    );
  }

  getOneSubject(id: number): T {
    return this.data.find(obj => obj.id == id);
  }

  getOneSubjectByName(name: string): T {
    return this.data.find(obj => obj.name === name);
  }

  getAllSubjects(): T[] {
    return this.data;
  }

  updateSubject(id: number, obj: T): void {
    if (this.ItemExists(id)) {
      const index = this.data.findIndex(item => item.id == id);
      let newObj = {} as T;
      newObj = {...obj};
      this.data[index] = newObj;
      this.subject.next(this.data);
    }
  }

  insertSubject(obj: T): T {
    if (this.ItemExists(obj.id)) { return; }
    this.data.push(obj);
    this.subject.next(this.data);
    return obj;
  }

  removeSubject(id: number): void {
    if (this.ItemExists(id)) {
      this.data = this.data.filter(item => item.id != id);
      this.subject.next(this.data);
    }
  }

  ItemExists(id: number): boolean {
    return this.data.some(item => item.id == id);
  }

  errorHandler(err: HttpErrorResponse): Observable<any> {
    console.log('ERROR-HANDLER: ', err, err.error);
    return throwError(err.error);
  }
}
