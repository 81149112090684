import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Medium} from '../../classes/medium';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IFormData} from '../../interfaces/form-data';
import {FormType} from '../../enum/form-type-enum';
import {Mapper} from '../../classes/mapper';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {INFO_TEXT, HTML_URL_TARGET} from '../../consts';
import {IDialogData} from '../../interfaces/idialog-data';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FacadeService} from '../../../services/facade.service';
import {TranslateUrl} from '../../classes/translateUrl';
import {UrlValidator} from '../../formValidators/url-validator';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {RxwebValidators} from '@rxweb/reactive-form-validators';
import {VisualComponent} from '../../classes/visual-component';

@Component({
  selector: 'app-add-translation-urls',
  templateUrl: './add-translation-urls.component.html',
  styleUrls: ['./add-translation-urls.component.scss']
})
export class AddTranslationUrlsComponent implements OnInit, OnChanges {
  @Input() entity: Medium | VisualComponent;
  @Output() formDataEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  @Output() dialogEvent = new EventEmitter<IDialogData>();
  readonly comparisonTranslateUrls: TranslateUrl[] = [];
  translateUrls: TranslateUrl[] = [];
  form: FormGroup;
  submitted = false;
  mediumCategory: string;
  messageBoxType = MessageBoxTypeEnum;
  message: string;
  INFO_TEXT = INFO_TEXT;
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    private fs: FacadeService
  ) {
    this.comparisonTranslateUrls = this.fs.mainShopService.getAllTranslateUrls();

    /*
      Additional local 'en_DE' - the local en_DE is only used for the manual creation of the urls
      {languageCode: 'en', language: 'English (en_DE)', countryCode: 'DE'}
    */
    const additionalTranslateUrl = new TranslateUrl('en_DE', 'en', 'DE' );
    this.comparisonTranslateUrls.unshift(additionalTranslateUrl);
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.init();
    }
  }

  init(): void {
    if (this.entity.mappedTranslateUrls && this.entity.translateUrls.length > 0) {
      this.translateUrls = this.entity.mappedTranslateUrls;
    }
    else {
      this.translateUrls = [];
    }
    this.form = this.initForm();
  }

  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(config.dialogClass, {
      data: config
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        this.translateUrls = res.data.collection;

        this.clearFormArrayGroup();
        this.translateUrls.forEach(element => {
          const fb = this.buildFormGroup(element);
          this.registerFormArray.push(fb);
        });
      }
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const entity: Medium | VisualComponent = {...this.entity};
      Mapper.mapFormGroup(entity, this.form);
      this.formDataEvent.emit({formType: FormType.UPDATE, obj: entity} as IFormData<Medium | VisualComponent>);
    }
  }

  onAddAllFromArrayElements(): void {
    this.clearFormArrayGroup();
    this.comparisonTranslateUrls.forEach(element => {
      const fb = this.buildFormGroup(element);
      this.registerFormArray.push(fb);
      this.translateUrls.push(element);
    });
  }

  onAddOneFormArrayElement(): void {
    const element = new TranslateUrl();
    const fg = this.buildFormGroup(element);
    this.registerFormArray.push(fg);
    this.translateUrls.push(element);
  }

  onDeleteAllUrls(e: Event): void {
    e.preventDefault();
    const config = {
      title: 'Delete Translated URLs',
      dialogClass: DeleteDialogComponent,
      headline: `Do you really want to delete all URLs?`,
      obj: this.entity,
      displayActionBtn: true
    } as IDialogData;

    const dialogRef = this.dialog.open(config.dialogClass, {data: config});
    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        this.translateUrls = [];
        this.registerFormArray.clear();
        this.onSubmit();
      }
    });
  }

  onDeleteOneUrl(e: Event, index: number): void {
    e.preventDefault();
    const config = {
      title: 'Delete Translated URL',
      dialogClass: DeleteDialogComponent,
      headline: `Do you really want to delete this URL?`,
      obj: this.entity,
      displayActionBtn: true
    } as IDialogData;

    const dialogRef = this.dialog.open(config.dialogClass, {data: config});
    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        this.registerFormArray.removeAt(index);
        this.onSubmit();
      }
    });
  }

  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }

  get registerFormArray(): FormArray {
    return this.form.get('mappedTranslateUrls') as FormArray;
  }

  private clearFormArrayGroup(): void {
    this.registerFormArray.clear();
  }

  private buildFormGroup(element: TranslateUrl): FormGroup {
    return this.fb.group({
      locale: [element.locale, [Validators.required, RxwebValidators.unique()]],
      url: [element.url, [Validators.required, UrlValidator.checkUrl]],
      countryCode: [element.countryCode],
      languageCode: [element.languageCode]
    });
  }

  private buildFormArray(values: TranslateUrl[]): FormArray {
    if (! values || values.length === 0) {
      return this.fb.array([]);
    }
    return this.fb.array(
        values.map(t => this.buildFormGroup(t))
    );
  }

  private initForm(): FormGroup {
    return this.fb.group({
      id: [this.entity.id],
      mappedTranslateUrls: this.buildFormArray(this.translateUrls)
    });
  }
}
