<h2 mat-dialog-title>{{config?.title}}</h2>

<mat-dialog-content>
    <h2 class="mb-2" *ngIf="config.headline">{{config.headline}}</h2>
        <ng-template #target></ng-template>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
