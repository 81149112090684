export enum MessageBoxTypeEnum {
    Default = 'Default',
    Info = 'Info',
    Success = 'Success',
    Warning = 'Warning',
    Danger = 'Danger',
    NoContent = 'NoContent'
}



