<script src="infobox-dialog.component.ts"></script><h2 mat-dialog-title>{{data.title}}</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-dialog-content fxLayout="column">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="shopId">
    <input type="hidden" formControlName="mediumCategoryId">

    <mat-form-field appearance="standard" floatLabel="always" fxFlex="100%">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" required>
      <mat-error *ngIf="(registerFormControl.name.touched || submitted) && registerFormControl.name.errors?.required">
        Title is required
      </mat-error>
    </mat-form-field>

    <div formGroupName="dateRange" fxFlex="100%" fxLayoutGap="10px">
      <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 50%">
        <input (dateChange)="handleDateChange()"  matInput [ngxMatDatetimePicker]="pickerStart" formControlName="startDate" placeholder="Start date">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #pickerStart
                                 [showSeconds]="showSeconds"
                                 [stepMinute]="stepMinutes"
                                 [stepSecond]="stepSeconds"
        ></ngx-mat-datetime-picker>
      </mat-form-field>
      <mat-form-field appearance="standard" floatLabel="always" fxFlex="1 1 50%">
        <input (dateChange)="handleDateChange()"  matInput [ngxMatDatetimePicker]="pickerEnd" formControlName="endDate" placeholder="End date">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #pickerEnd
                                 [showSeconds]="showSeconds"
                                 [stepMinute]="stepMinutes"
                                 [stepSecond]="stepSeconds"
        ></ngx-mat-datetime-picker>
      </mat-form-field>
    </div>
    <mat-error *ngIf="registerFormControl.dateRange.hasError('dateRangeInvalid')" class="mb-2">
      Invalid date range
    </mat-error>

    <ng-container *ngIf="displayWeekdays">
      <div formArrayName="weekdays"
           class="col weekdays-wrapper mb-2"
           fxLayout="row wrap"
           fxLayoutAlign="flex-start">
        <ng-container *ngFor="let day of weekdays.controls; let i = index">
          <mat-checkbox
            fxFlex="90px"
            fxLayoutGap="20px"
            (change)="handleWeekday()"
            [formControl]="day">
            {{weekDaysData[i].shortName}}
          </mat-checkbox>
        </ng-container>
      </div>
    </ng-container>


    <div  class="checkbox-wrapper mt-2" fxLayout="column">
      <mat-checkbox formControlName="active">Active</mat-checkbox>
    </div>

    <div *ngIf="entity.id" class="checkbox-wrapper" fxLayout="column">
      <mat-checkbox formControlName="archived" (change)="onChangeIsArchive($event)">Archived</mat-checkbox>
    </div>

    <table class="mt-4" *ngIf="entity.id">
      <tr>
        <th>ID:</th>
        <td>{{entity.id }} </td>
      </tr>
    <tr>
      <th>Insert-Ts:</th>
      <td>{{entity?.insertTs | date: dateFormat }} </td>
    </tr>
    <tr>
      <th>Update-Ts:</th>
      <td>{{entity?.updateTs | date: dateFormat}}</td>
    </tr>
    </table>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Close</button>
    <button mat-stroked-button type="submit"
            [disabled]="! form.valid">Save
    </button>
  </mat-dialog-actions>
</form>
