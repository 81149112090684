<div class="row">
    <div class="col-12">
        <ng-container *ngIf="content">
            <div [innerHTML]="content | safeHtml"></div>
        </ng-container>
    </div>
</div>
<div class="row">
    <div class="col-12 mt-4">
        <a class="link-secondary" (click)="onDownloadXlsx($event)">Download excel template</a>
    </div>
</div>

