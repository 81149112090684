import { Component, OnInit } from '@angular/core';
import {User} from '../../../shared/classes/user';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.css']
})
export class UserInfoComponent implements OnInit {
  user: User;
  constructor(
      private userService: UserService
  ) {
    this.userService.user$.subscribe(user => this.user = user);
  }

  ngOnInit(): void {
  }
}
