<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="filter-wrapper">

    <div class="filter-header" (click)="onToggleFilter()">
        <p>Filter</p>
        <mat-icon [ngClass]="display ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" title="Open / Close Filter"></mat-icon>
    </div>

    <div *ngIf="display" class="container-fluid">
      <div class="row">
        <div class="col-md-6 col-sm-12">
          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
            <mat-label>Language-Code</mat-label>
            <mat-select formControlName="selectedLanguageCode">
              <mat-option value="">-- all --</mat-option>
              <mat-option [value]="c.languageCode" *ngFor="let c of settings.languageCodes">
                <span class="span-option">{{c.languageCode}} </span><span> {{c.language}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12">
          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
            <mat-label>Country-Code</mat-label>
            <mat-select formControlName="selectedShop" (selectionChange)="onChangeShop($event.value)">
              <mat-option value="">-- all --</mat-option>
              <mat-option [value]="s" *ngFor="let s of settings.shops as Shop">
                <span class="span-option">{{s.countryCode}} </span><span> {{s.country}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 col-sm-12">
          <div class="btn-wrapper">
            <button mat-stroked-button title="Reset Fields" (click)="onResetForm($event)">
              <i class="fa fa-repeat"></i>
            </button>
            <button class="filter-btn" mat-stroked-button type="submit"
                    [disabled]="!form.valid">Filter
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>


