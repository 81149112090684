import {Component, OnInit} from '@angular/core';
import {CSS_LAYOUT_CONFIG} from '../../consts';
import {FacadeService} from '../../../services/facade.service';
import {UserRole} from '../../enum/user-role-enum';
import {Shop} from '../../classes/shop';
import {InfoboxType} from '../../classes/infobox-type';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  CSS_LAYOUT_CONF: any;
  cards: any[];
  shops: Shop[];
  infoboxTypes: InfoboxType[];
  displaySliderDashboard: boolean;
  displayTeaserDashboard: boolean;
  displayInfoboxDashboard: boolean;
  displayUserDashboard: boolean;

  constructor(
      private fs: FacadeService
  ) {
    this.CSS_LAYOUT_CONF = CSS_LAYOUT_CONFIG;
  }

  ngOnInit(): void {
    this.shops = this.fs.mainShopService.getAllSubjects();
    this.infoboxTypes = this.fs.infoboxTypeService.getAllSubjects();
    this.displaySliderDashboard = this.fs.userService.userHasRole(UserRole.ROLE_CONFIGURE_SLIDER);
    this.displayTeaserDashboard = this.fs.userService.userHasRole(UserRole.ROLE_CONFIGURE_TEASER);
    this.displayInfoboxDashboard = this.fs.userService.userHasRole(UserRole.ROLE_CONFIGURE_INFOBOX);
    this.displayUserDashboard = this.fs.userService.userHasRole(UserRole.ROLE_CONFIGURE_USER);
  }
}
