import {Medium} from '../classes/medium';
import {Translation} from '../classes/translation';
import {TranslationMapper} from '../classes/translation-mapper';

export class MediumHelper {

  static loadHeadline(medium: Medium, languageCode?: string): string {
    const headline = '--Placeholder--';
    if (! medium.mappedTranslations || medium.mappedTranslations.length === 0) {
      return headline;
    }
    let translation;
    if (languageCode) {
      translation = TranslationMapper.getTranslationByLanguageCode(medium.mappedTranslations, languageCode);
    } else {
      translation = medium.mappedTranslations.filter(obj => obj.headline)[0] as Translation;
    }
    return (translation && translation.headline) ? translation.headline : headline;
  }

  static isTranslated(medium: Medium, languageCode: string): boolean {
    let translated = false;
    const translation = medium?.mappedTranslations?.find(obj => obj.languageCode === languageCode);
    if (translation) {
      switch (medium.mediumCategoryId) {
        case 1: // Slider
          translated = !!((translation.headline || translation.subheadline) && translation.btnLabel);
          break;
        case 2: // Teaser
          translated = !!((translation.headline || translation.subheadline));
          break;
      }
    }
    return translated;
  }
}
