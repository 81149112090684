<section>

  <ng-container *ngIf="! imageName; else imageDeleteTemplate">
    <ng-container *ngIf="cropAllowed; else imageLoadTemplate">
      <div class="mt-2 mb-4" *ngIf="entity.image; else infoTemplate">
        <mat-radio-group aria-label="Select an option" [(ngModel)]="imageUploadType">
          <mat-radio-button [checked]="true" [value]="'upload'">Upload Image
          </mat-radio-button>
          <mat-radio-button [disabled]="! entity.image" [value]="'crop'">Crop Image
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <ng-container *ngIf="imageUploadType !== defaultImageUploadType; else imageLoadTemplate">
        <ng-container *ngIf="entity.image">
          <app-image-cropper
                  [entity]="entity"
                  [imageType]="imageType"
                  [aspectRatio]="16 / 5"
                  (imageEvent)="onImageUpload($event)"
          ></app-image-cropper>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-template #infoTemplate>
      <app-message-box
              [type]="MessageBoxType.Info"
              [message]="'To crop an Mobile-Image, a reference Image is first required, which can be loaded under the \'Desktop\'-Tab.'"
      ></app-message-box>
    </ng-template>
    <ng-template #imageLoadTemplate>
        <app-form-image-loader
                [entity]="entity"
                [imageType]="imageType"
                (imageEvent)="onImageUpload($event)"
        ></app-form-image-loader>
    </ng-template>
  </ng-container>

  <ng-template #imageDeleteTemplate>
    <div *ngIf="imageName" class="img-container mt-4 mb-4">
      <img [src]="getImagePath()" [alt]="imageName">
      <div class="row">
        <div class="col mt-2">
          <button mat-stroked-button (click)="deleteImage()">Delete image</button>
        </div>
      </div>
    </div>
  </ng-template>

  <app-message-box
          [type]="MessageBoxType.Default"
          [message]="infoImageUploadDimensions"
  ></app-message-box>
</section>






