import {Component, OnInit, Input, SimpleChanges, OnChanges} from '@angular/core';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {IKeyValuePair} from '../../interfaces/i-key-value-pair';
import {CSS_ALERT_BOX_VALUES} from '../../consts';

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.scss']
})
export class MessageBoxComponent implements OnInit, OnChanges {
  @Input() type: MessageBoxTypeEnum;
  @Input() message: string;
  config: IKeyValuePair;

  constructor() { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.config = CSS_ALERT_BOX_VALUES.find(obj => obj.key === this.type) ||
          CSS_ALERT_BOX_VALUES.find(obj => obj.key === 'Default');
    }
  }
}
