<div
        fxLayout="row"
        fxLayout.lt-md="column"
        fxLayoutGap="20px"
        fxLayoutGap.xs="0"
>
    <div>
        <mat-divider></mat-divider>
        <section
                fxLayout="row wrap"
                fxLayout.sm="column"
                fxLayout.xs="column"
                fxLayoutGap="6px"
                fxLayoutGap.xs="4px">

            <app-dashboard-slider [shops]="shops" [display]="displaySliderDashboard"></app-dashboard-slider>
            <app-dashboard-teaser [shops]="shops" [display]="displayTeaserDashboard"></app-dashboard-teaser>
            <app-dashboard-infobox [shops]="shops" [infoboxTypes]="infoboxTypes"  [display]="displayInfoboxDashboard"></app-dashboard-infobox>
            <app-dashboard-user [shops]="shops" [display]="displayUserDashboard"></app-dashboard-user>
        </section>
    </div>
</div>


