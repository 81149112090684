import {APP_INITIALIZER, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './shared/shared.module';
import {FooterComponent} from './components/footer/footer.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppMenuComponent} from './components/app-menu/app-menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CoreModule } from './core/core.module';
import { LoginComponent } from './components/login/login.component';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import {AppInitService} from './services/app-init.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

export function initializeApp(appInitService: AppInitService): any {
    return (): Promise<any> => appInitService.init();
}

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        AppMenuComponent,
        PageNotFoundComponent,
        LoginComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule,
        CoreModule,
        LoggerModule.forRoot({
          serverLoggingUrl: `${environment.apiBaseUrl}/angularAppLogs`,
          level: environment.logLevel,
          serverLogLevel: environment.serverLogLevel,
          disableConsoleLogging: environment.disableConsoleLogging
        })
    ],
    providers: [
        AppInitService,
        {provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
        {provide: LocationStrategy, useClass: HashLocationStrategy}
    ],
    exports: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
