import { IKeyValuePair } from './interfaces/i-key-value-pair';

export const LANGUAGES = [
  {languageCode: 'de', language: 'German'},
  {languageCode: 'en', language: 'English'},
  {languageCode: 'cs', language: 'Czech'},
  {languageCode: 'da', language: 'Danish'},
  {languageCode: 'nl', language: 'Dutch'},
  {languageCode: 'fr', language: 'French'},
  {languageCode: 'it', language: 'Italian'},
  {languageCode: 'no', language: 'Norwegian'},
  {languageCode: 'pl', language: 'Polish'},
  {languageCode: 'sv', language: 'Swedish'}
];

export const LANGUAGES_EXPANDED = [
  {languageCode: 'de',    language: 'German',          countryCode: 'DE'},
  {languageCode: 'en',    language: 'English',         countryCode: null},

  {languageCode: 'de',    language: 'German (de_AT)',  countryCode: 'AT'},
  {languageCode: 'en',    language: 'English (en_AT)', countryCode: 'AT'},

  {languageCode: 'de',    language: 'German (de_CH)',  countryCode: 'CH'},
  {languageCode: 'it',    language: 'Italian (it_CH)', countryCode: 'CH'},
  {languageCode: 'fr',    language: 'French (fr_CH)',  countryCode: 'CH'},
  {languageCode: 'en',    language: 'English (en_CH)', countryCode: 'CH'},

  {languageCode: 'nl',    language: 'Dutch (nl_BE)',   countryCode: 'BE'},
  {languageCode: 'fr',    language: 'French (fr_BE)',  countryCode: 'BE'},
  {languageCode: 'en',    language: 'English (en_BE)', countryCode: 'BE'},

  {languageCode: 'cs',    language: 'Czech',           countryCode: 'CZ'},
  {languageCode: 'en',    language: 'English (en_CZ)', countryCode: 'CZ'},

  {languageCode: 'da',    language: 'Danish',          countryCode: 'DK'},
  {languageCode: 'en',    language: 'English (en_DK)', countryCode: 'DK'},

  {languageCode: 'fr',    language: 'French',          countryCode: 'FR'},
  {languageCode: 'en',    language: 'English (en_FR)', countryCode: 'FR'},

  {languageCode: 'it',    language: 'Italian',         countryCode: 'IT'},
  {languageCode: 'de',    language: 'Italian (de_IT)', countryCode: 'IT'},
  {languageCode: 'en',    language: 'English (en_IT)', countryCode: 'IT'},

  {languageCode: 'nl',    language: 'Dutch',           countryCode: 'NL'},
  {languageCode: 'en',    language: 'English (en_NL)', countryCode: 'NL'},

  {languageCode: 'no',    language: 'Norwegian',       countryCode: 'NO'},
  {languageCode: 'en',    language: 'English (en_NO)', countryCode: 'NO'},

  {languageCode: 'pl',    language: 'Polish',          countryCode: 'PL'},
  {languageCode: 'en',    language: 'English (en_PL)', countryCode: 'PL'},

  {languageCode: 'sv',    language: 'Swedish',         countryCode: 'SE'},
  {languageCode: 'en',    language: 'English (en_SE)', countryCode: 'SE'}

];

export const MEDIUM_CATEGORY_CONFIG = {
  'section-slider': {
    'main-shop': {
      HEADLINE: 'Main-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Slider',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Main-Shop-Slider',
      ROUTE: 'main-shop'
    },
    'partner-shop': {
      HEADLINE: 'Partner-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Slider',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Partner-Shop-Slider',
      ROUTE: 'partner-shop'
    }
  },
  'section-teaser': {
    'main-shop': {
      HEADLINE: 'Main-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Teaser',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Main-Shop-Teaser',
      ROUTE: 'main-shop'
    },
    'partner-shop': {
      HEADLINE: 'Partner-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Teaser',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Partner-Shop-Teaser',
      ROUTE: 'partner-shop'
    }
  },
  'section-teaser-specials': {
    'main-shop': {
      HEADLINE: 'Main-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global Teaser Specials & Sale',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Main-Shop Teaser Specials & Sale',
      ROUTE: 'main-shop'
    }
  },
  'section-infobox-customer': {
    'main-shop': {
      HEADLINE: 'Main-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Customer-Infobox',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Main-Shop Customer-Infobox',
      ROUTE: 'main-shop'
    }
  },
  'section-infobox-article': {
    'main-shop': {
      HEADLINE: 'Main-Shop',
      HEADLINE_CONFIGURE_GLOBAL_UNIT: 'Configure Global-Article-Infobox',
      HEADLINE_CONFIGURE_INDIVIDUAL_UNIT: 'Configure Main-Shop Article-Infobox',
      ROUTE: 'main-shop'
    }
  },
  slider: {
    HEADLINE: 'Slider',
    HEADLINE_LIST_UNIT: 'List Slider',
    HEADLINE_LIST_ITEM: 'List Slides',
    HEADLINE_CONFIGURE_UNIT: 'Configure Slider',
    HEADLINE_CONFIGURE_ITEM: 'Configure Slide',
    HEADLINE_ADD_NEW_UNIT: 'Add new Slider',
    HEADLINE_ADD_NEW_ITEM: 'Add new Slide',
    HEADLINE_DELETE_UNIT: 'Delete Slider',
    HEADLINE_DELETE_ITEM: 'Delete Slide',
    HEADLINE_COPY_ITEM: 'Copy Slide',
    HEADLINE_IMPORT_ITEMS: 'Import Slides',
    ROUTE: 'slider',
    SUBROUTE: 'slide'
  },
  teaser: {
    HEADLINE: 'Teaser-Row',
    HEADLINE_LIST_UNIT: 'List Teaser-Rows',
    HEADLINE_LIST_ITEM: 'List Teaser',
    HEADLINE_CONFIGURE_UNIT: 'Configure Teaser-Row',
    HEADLINE_CONFIGURE_ITEM: 'Configure Teaser',
    HEADLINE_ADD_NEW_UNIT: 'Add new Teaser-Row',
    HEADLINE_ADD_NEW_ITEM: 'Add new Teaser',
    HEADLINE_DELETE_UNIT: 'Delete Teaser-Row',
    HEADLINE_DELETE_ITEM: 'Delete Teaser',
    HEADLINE_COPY_ITEM: 'Copy Teaser',
    HEADLINE_IMPORT_ITEMS: 'Import Teaser',
    ROUTE: 'teaser-unit',
    SUBROUTE: 'teaser'
  },
  teaser_specials_sale: {
    HEADLINE: 'Teaser',
    HEADLINE_LIST_UNIT: 'List Teaser',
    HEADLINE_LIST_ITEM: 'List Teaser',
    HEADLINE_CONFIGURE_UNIT: 'Configure Teaser',
    HEADLINE_CONFIGURE_ITEM: 'Configure Teaser',
    HEADLINE_ADD_NEW_UNIT: 'Add new Teaser',
    HEADLINE_ADD_NEW_ITEM: 'Add new Teaser',
    HEADLINE_DELETE_UNIT: 'Delete Teaser',
    HEADLINE_DELETE_ITEM: 'Delete Teaser',
    HEADLINE_COPY_ITEM: 'Copy Teaser',
    HEADLINE_IMPORT_ITEMS: 'Import Teaser',
    ROUTE: 'teaser',
    SUBROUTE: 'teaser'
  },
  infobox: {
    HEADLINE: 'Infobox',
    HEADLINE_LIST_UNIT: 'List Infobox',
    HEADLINE_LIST_ITEM: 'List Infobox',
    HEADLINE_CONFIGURE_UNIT: 'Configure Infobox',
    HEADLINE_CONFIGURE_ITEM: 'Configure Infobox',
    HEADLINE_ADD_NEW_UNIT: 'Add new Infobox',
    HEADLINE_ADD_NEW_ITEM: 'Add new Infobox',
    HEADLINE_DELETE_UNIT: 'Delete Infobox',
    HEADLINE_DELETE_ITEM: 'Delete Infobox',
    HEADLINE_COPY_ITEM: 'Copy Infobox',
    HEADLINE_IMPORT_ITEMS: 'Import Infobox',
    ROUTE: 'infobox',
    SUBROUTE: 'infobox'
  },
};

export const CSS_LAYOUT_CONFIG = {
  dashboardCol: {
    'min-width-int': 380,
    'min-width': '380px',
    'max-width': '50%',
    'flex-min-width': '1 1 380px',
    'flex-max-width': '1 1 50%',
  },
  mainCol: {
    'min-width-int': 380,
    'min-width': '380px',
    'max-width': '50%',
    'flex-min-width': '1 1 380px',
    'flex-max-width': '1 1 50%',
  },
  contentCol: {
    'min-width-int': 65,
    'max-width-int': 380,
    'min-width': '65px',
    'max-width': '380px',
    'flex-min-width': '1 1 65px',
    'flex-max-width': '1 1 380px'
  },
  adaptationCol: {
    'min-width-int': 800,
    'min-width': '800px',
    'max-width': '100%',
    'flex-min-width': '1 1 800px',
    'flex-max-width': '1 1 100%',
  },
  layoutGap: {
    'width-int': 6,
    'width': '6px',
  },
  breakPoints: {
    XSmall: {
      label: 'X-Small',
      value: 576
    },
    Small: {
      label: 'Small',
      value: 768
    },
    Medium: {
      label: 'Medium',
      value: 1024
    },
    Large: {
      label: 'Large',
      value: 1200
    },
    XLarge: {
      label: 'X-Large',
      value: null
    }
  },
  responsiveImageDimensions: {
    imageUploadMaxSize: 250,
    imageUploadTypes: [
      'jpg', 'jpeg'
    ],
    uploadSize: {
      slider: 250,
      teaser: 160
    },
    slider: {
      imageUploadMaxSize: 250,
      desktop: {
        landscape: {
          width: 1920,
          height: 400
        }
      },
      mobile: {
        landscape: {
          width: 800,
          height: 250
        },
        portraint: {
          width: 600,
          height: 250
        }
      }
    },
    teaser: {
      imageUploadMaxSize: 160,
      desktop: {
        landscape: {
          width: 750,
          height: 260
        },
        landscapeLarge: {
          width: 960,
          height: 260
        },
        landscapeLargeSmallHeight: {
          width: 960,
          height: 130
        },
        landscapeLargeNew: {
          width: 960,
          height: 250
        },
      },
      mobile: {
        landscape: {
          width: 750,
          height: 260
        }
      }
    },
    teaser_specials_sale: {
      desktop: {
        landscape: {
          width: 300,
          height: 150
        }
      }
    }
  }
};

export const CSS_WYSIWYG_EDITOR_CONFIG = {
  rowHorizontalAlign: [
    {key: 'start', value: 'justify-content-start', label: 'Start'},
    {key: 'center', value: 'justify-content-center', label: 'Center'},
    {key: 'end', value: 'justify-content-end', label: 'End'},
  ] as IKeyValuePair[],
  contentTextAlign: [
    {key: 'left', value: 'text-left', label: 'Left'},
    {key: 'center', value: 'text-center', label: 'Center'},
    {key: 'right', value: 'text-right', label: 'Right'},
  ] as IKeyValuePair[],
  colWidth: [
    {key: 'col-extra-small', value: 'col-extra-small', label: 'Extra Small'},
    {key: 'col-small', value: 'col-small', label: 'Small'},
    {key: 'col-middle', value: 'col-middle', label: 'Middle'},
    {key: 'col-large', value: 'col-large', label: 'Large'},
  ] as IKeyValuePair[],
  colBackground: [
    {key: 'bg-none', value: 'bg-transparent', label: 'None'},
    {key: 'bg-transparent', value: 'bg-semi-transparent', label: 'Semi-Transparent'},
  ] as IKeyValuePair[],
  buttonColor: [
    {key: 'blue', value: 'btn btn-blue', label: 'Blue'},
    {key: 'grey', value: 'btn btn-grey', label: 'Grey'},
    {key: 'white', value: 'btn btn-white', label: 'White'},
    {key: 'black', value: 'btn btn-black', label: 'Black'},
    {key: 'orange', value: 'btn btn-orange', label: 'Orange'},
    {key: 'red', value: 'btn btn-red', label: 'Red'},
  ] as IKeyValuePair[],
  colOffset: [
    {key: 'default', value: '', label: 'none'},
    {key: 'offset-start-1', value: 'offset-start-1', label: 'Left small'},
    {key: 'offset-start-2', value: 'offset-start-2', label: 'Left middle'},
    {key: 'offset-start-3', value: 'offset-start-3', label: 'Left large'},
    {key: 'offset-end-1', value: 'offset-end-1', label: 'Right small'},
    {key: 'offset-end-2', value: 'offset-end-2', label: 'Right middle'},
    {key: 'offset-end-3', value: 'offset-end-3', label: 'Right large'}
  ] as IKeyValuePair[],
  fontFamily: [
    {key: 'Arial', value: 'Arial', label: 'Arial'},
    {key: 'Arial Black', value: 'Arial Black', label: 'Arial Black'},
    {key: 'Oswald Light', value: 'Oswald Light', label: 'Oswald Light'},
    {key: 'Oswald Regular', value: 'Oswald Regular', label: 'Oswald Regular'},
    {key: 'Oswald Bold', value: 'Oswald Bold', label: 'Oswald Bold'},
    {key: 'Roboto Light', value: 'Roboto Light', label: 'Roboto Light'},
    {key: 'Roboto Regular', value: 'Roboto Regular', label: 'Roboto Regular'},
    {key: 'Roboto Bold', value: 'Roboto Bold', label: 'Roboto Bold'},
    {key: 'Roboto Black', value: 'Roboto Black', label: 'Roboto Black'},
    {key: 'RobotoSlab Light', value: 'RobotoSlab Light', label: 'RobotoSlab Light'},
    {key: 'RobotoSlab Regular', value: 'RobotoSlab Regular', label: 'RobotoSlab Regular'},
    {key: 'RobotoSlab Black', value: 'RobotoSlab Black', label: 'RobotoSlab Black'},
    {key: 'Amazing Kids', value: 'Amazing Kids', label: 'Amazing Kids'}
  ] as IKeyValuePair[],
  fontHeadline: [
    {key: 'h1', value: 'h1', label: 'Headline 1'},
    {key: 'h2', value: 'h2', label: 'Headline 2'},
    {key: 'h3', value: 'h3', label: 'Headline 3'},
    {key: 'h4', value: 'h4', label: 'Headline 4'}
  ] as IKeyValuePair[],
  fontColor: [
    {key: 'black', value: '#000000', label: 'Black'},
    {key: 'white', value: '#ffffff', label: 'White'},
    {key: 'lshop-blue', value: '#003087', label: 'L-Shop Blue'}
  ] as IKeyValuePair[],
  mobileFontColor: [
    {key: 'black', value: '#000000', label: 'Black'},
    {key: 'white', value: '#ffffff', label: 'White'},
    {key: 'lshop-blue', value: '#003087', label: 'L-Shop Blue'}
  ] as IKeyValuePair[],
  stylesForSave: [
    {key: 'rowHorizontalAlign', value: 'justify-content-end'},
    {key: 'contentTextAlign', value: 'text-center'},
    {key: 'colWidth', value: 'col-extra-small'},
    {key: 'colBackground', value: 'bg-semi-transparent'},
    {key: 'buttonColor', value: 'btn btn-blue'},
    {key: 'button_direction', value: null},
    {key: 'colOffset', value: null},
    {key: 'display_border', value: ''},
    {key: 'fontFamily', value: 'Oswald Regular'},
    {key: 'fontHeadline', value: 'h4'},
    {key: 'fontColor', value: '#003087'},
    {key: 'colUnit', value: null}
  ]
};

export const NGX_IMAGE_CROPPER_CONFIG = {
  format: 'jpeg',
  imageQuality: 92,
  aspectRatio: Number(4 / 3),
  resizeToHeight: 250,
  maintainAspectRatio: true,
  defaultFileName: 'cropped_image.jpeg'
};

export const CSS_COL_UNIT_VALUES = [
  {key: 'small', value: 'col-xs-12 col-sm-6 col-md-4', label: 'Small [1/3 Row]', unit: 1},
  {key: 'medium', value: 'col-xs-12 col-sm-6 col-md-8', label: 'Medium [2/3 Row]', unit: 2},
  {key: 'large', value: 'col-xs-12 col-sm-12 col-md-12', label: 'Large [3/3 Row]', unit: 3},
  {key: 'small-sm-height', value: 'col-xs-12 col-sm-6 col-md-4 box-slim', label: 'Small [1/3 Row] | Narrow Height', unit: 1},
  {key: 'medium-sm-height', value: 'col-xs-12 col-sm-6 col-md-8 box-slim', label: 'Medium [2/3 Row] | Narrow Height', unit: 2},
  {key: 'large-sm-height', value: 'col-md-12 col-sm-12 col-xs-12 box-slim', label: 'Large [3/3 Row] | Narrow Hight', unit: 3},
];

export const CSS_ALERT_BOX_VALUES = [
  {key: 'Default', value: '__alert alert-default'},
  {key: 'Info', value: '__alert alert-info'},
  {key: 'Success', value: '__alert alert-success'},
  {key: 'Warning', value: '__alert alert-warning'},
  {key: 'Danger', value: '__alert alert-danger'},
  {key: 'NoContent', value: '__alert alert-noContent'},
];

export const HTML_URL_TARGET = [
    {key: 'self', value: '_self'},
    {key: 'blank', value: '_blank'}
];

export const INFO_TEXT = {
  TRANSLATION_FALLBACK: '<p><mat-icon class="fa fa-exclamation-triangle"></mat-icon> Info</p>' +
      '<p>If no text is available for specific regional language versions, the content from the main language version (<b>[en]</b>, <b>[de-DE]</b>, <b>[fr-FR]</b>, <b>[it-IT]</b>, <b>[nl-NL]</b>) will automatically be used.</p>',
  TRANSLATION_LINKS: '<p><mat-icon class="fa fa-exclamation-triangle"></mat-icon> Info</p>' +
      '<p>By entering a "Destination URL" links for all languages are loaded and displayed in tabular form for viewing. ' +
      'With the "Apply" button, the loaded links are first loaded into input fields and can be adjusted manually if necessary. ' +
      'The links are only persisted after the "Save" button has been clicked.</p>',
  POSSIBLE_TEASER_DISPLAY: '<img src="assets/images/teaser-grid.svg" alt="Teaser-Dimensions" loading="lazy" />' +
      '<p>The teaser dimensions can be changed in the column \'Configure Teaser\' under the tab \'Settings. ' +
      'If the column \'Configure Teaser\' is not displayed, the corresponding teaser must first be selected.</p>',
  SLIDER_MERGED_LIST: '<p>In this list view, the shop-individual slides are combined with the global slides.</p>',
  APPROVE_FOR_REPLACEMENT: '<p>This checkbox enables a global slide to be replaced with a shop-individual slide.</p>',
  CRITERIA_SLIDER_MERGED_LIST: '<p>As a guide, no more than <b>5 global slides</b> should be enabled, of which maximum of 2 slides should be shared for replacement.</p>' +
'<p><u>Sorting criteria of the combined global- and shop-individual slides:</u></p>' +
'<p>Individual slides take precedence over global slides.</p>' +
'<p>The global slides enabled for replacement are replaced sequentially in reverse order from the end.</p>',
  ROW_FILLED_OUT: '<p>It is not possible to create new teaser. The row is already filled out. Please delete one teaser or reduce the teaser width of the existing teaser.</p>',
  ROW_NOT_ENOUGH_SPACE: '<p>The selected number of teaser to be created is greater than the number of available space.</p>',
  SLIDER_TEASER_IMPORT: `
                    <p>To automatically generate slider or teaser, a .xlsx file can be used with the following first row headers:</p>
                    <p>The 'name' column should contain a short title for the slider or teaser..</p>
                    <p>The remaining columns contain valid locale for the corresponding translations. For example, 'de_DE', 'en', 'de_AT', 'de_CH' ... etc.</p>`,
  INFOBOX_IMPORT: `
                    <p>To automatically generate infoboxes, a .xlsx file can be used with the following first row headers:</p>
                    <p style="font-weight: bold;">['name', 'shops', 'infoboxType', 'infoboxCategory', 'articleId', 'startDate', 'endDate', 'de_DE', 'en', 'de_AT', 'de_CH' ... etc.]</p>
                    <p>The 'name' column should contain a short title for the infobox. The parser automatically adds the country code to make it easier to search in archive later.</p>
                    <p>The 'shops' column can be left blank to create one global infobox for all main shops or can contain comma-separated country codes to create specific main shops.</p>
                    <p>The 'infoboxType' column can only contain either <b>'customer'</b> or <b>'article'</b>.</p>
                    <p>The 'infoboxCategory' column can only contain either <b>'info'</b>, <b>'warning'</b>, or <b>'danger'</b>.</p>
                    <p>The 'articleId' column must be marked with a valid article ID if the 'infoboxType' column is marked as 'article'. Otherwise, the column can be left blank.</p>
                    <p>The 'startDate' and 'endDate' columns must contain valid date and time values. For example: '01.01.2023  12:45:00'</p>
                    <p>The remaining columns contain valid locale for the corresponding translations. For example, 'de_DE', 'en', 'de_AT', 'de_CH' ... etc.</p>`,
  TEASER_SPECIALS_IMPORT: `
                    <p>To automatically generate teaser, a .xlsx file can be used with the following first row headers:</p>
                    <p style="font-weight: bold;">['name', 'startDate', 'endDate', 'textType', 'shopCountryCode', 'de_DE', 'en', 'de_AT', 'de_CH' ... etc.]</p>
                    <p>The 'name' column should contain a short title for the teaser.</p>
                    <p>The 'startDate' and 'endDate' columns must contain valid date and time values. For example: '01.01.2023  12:45:00'</p>
                    <p>The column "textType" must contain a valid headline type. Either headline or subheadline.'</p>
                    <p>The 'shopCountryCode' column can be left blank to create one global teaser for all main shops, or can contain a country code to create teaser for one main shop.</p>
                    <p>The remaining columns contain valid locale for the corresponding translations. For example, 'de_DE', 'en', 'de_AT', 'de_CH' ... etc.</p>
                    <p>The translation texts can be set to <b>bold</b> if the text is embedded between two <b>*-characters</b>. Example: '*Headline in bold*'.</p>`
};

export const DIALOG_DIMENSIONS = {
  width: '950px',
  height: 'auto'
};

export const BUTTON_CONSTS = {
  actionButton: {
    edit: 'edit',
    delete: 'delete',
    view: 'view',
  },
};

export const COL_UNIT = 'colUnit';

export const IMAGE_CONTAINER_MAX_WIDTH = 400;

export const ALL_WEEKDAYS_AS_BIT = 127;

export const BUTTON_LABEL_TRANSLATIONS = [
  [
    {languageCode: 'de', label: 'Alle Artikel ansehen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'View all articles'},
    {languageCode: 'pl', label: 'Obejrzyj wszystkie artykuły'},
    {languageCode: 'cs', label: 'Prohlédnout si všechny'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Alle artikelen bekijken'},
    {languageCode: 'it', label: 'Vedi tutti gli articoli'},
    {languageCode: 'da', label: 'Se alle artikler'},
    {languageCode: 'sv', label: 'Se här alla artiklar'},
    {languageCode: 'no', label: 'Se alle produktene'},
    {languageCode: 'fr', label: 'Voir tous les articles'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Hier entlang'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'This way'},
    {languageCode: 'pl', label: 'Tutaj'},
    {languageCode: 'cs', label: 'Tudy'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Hierlangs'},
    {languageCode: 'it', label: 'In questo modo'},
    {languageCode: 'da', label: 'Det er denne vej'},
    {languageCode: 'sv', label: 'Fortsätt här'},
    {languageCode: 'no', label: 'Denne vei'},
    {languageCode: 'fr', label: 'Par ici'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt ansehen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Discover now'},
    {languageCode: 'pl', label: 'Zobacz'},
    {languageCode: 'cs', label: 'Zobraz'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu bekijken'},
    {languageCode: 'it', label: 'Scoprire ora'},
    {languageCode: 'da', label: 'Se her'},
    {languageCode: 'sv', label: 'Se här'},
    {languageCode: 'no', label: 'Se nå'},
    {languageCode: 'fr', label: 'Regardez maintenant'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt bestellen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Order now'},
    {languageCode: 'pl', label: 'Zamów teraz'},
    {languageCode: 'cs', label: 'Zde objednejte'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu bestellen'},
    {languageCode: 'it', label: 'Ordina ora'},
    {languageCode: 'da', label: 'Bestil nu'},
    {languageCode: 'sv', label: 'Beställ nu'},
    {languageCode: 'no', label: 'Bestill nå'},
    {languageCode: 'fr', label: 'Commandez maintenant'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt entdecken'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Discover now'},
    {languageCode: 'pl', label: 'Zobacz'},
    {languageCode: 'cs', label: 'Otevřete teď'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu ontdekken'},
    {languageCode: 'it', label: 'Scopri ora'},
    {languageCode: 'da', label: 'Se her'},
    {languageCode: 'sv', label: 'Upptäck nu'},
    {languageCode: 'no', label: 'Oppdag nå'},
    {languageCode: 'fr', label: 'Découvrez ici'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt profitieren'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Benefit now'},
    {languageCode: 'pl', label: 'Nie przegap'},
    {languageCode: 'cs', label: 'Vaše výhoda'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu voordeel halen'},
    {languageCode: 'it', label: 'Beneficia ora'},
    {languageCode: 'da', label: 'Benyt tilbuddet nu'},
    {languageCode: 'sv', label: 'Utnyttja detta erbjudande'},
    {languageCode: 'no', label: 'Få fordelen nå'},
    {languageCode: 'fr', label: 'Bénéficier maintenant'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt shoppen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Shop now'},
    {languageCode: 'pl', label: 'Kup już teraz'},
    {languageCode: 'cs', label: 'Nyní nakupujte'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu winkelen'},
    {languageCode: 'it', label: 'Acquistare ora'},
    {languageCode: 'da', label: 'Køb nu'},
    {languageCode: 'sv', label: 'Køb nu'},
    {languageCode: 'no', label: 'Kjøp nå'},
    {languageCode: 'fr', label: 'Shopper maintenant '},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt sichern'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Buy now'},
    {languageCode: 'pl', label: 'Kup już teraz'},
    {languageCode: 'cs', label: 'Pojistěte si teď'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu winkelen'},
    {languageCode: 'it', label: 'Sicuro ora'},
    {languageCode: 'da', label: 'Lige nu'},
    {languageCode: 'sv', label: 'Spara nu'},
    {languageCode: 'no', label: 'Sikre nå'},
    {languageCode: 'fr', label: 'Achetez ici'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt stöbern'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Shop now'},
    {languageCode: 'pl', label: 'Skuś się'},
    {languageCode: 'cs', label: 'Procházejte'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Nu ontdekken'},
    {languageCode: 'it', label: 'Sfoglia ora'},
    {languageCode: 'da', label: 'Gennemse her'},
    {languageCode: 'sv', label: 'Upptäck nu '},
    {languageCode: 'no', label: 'Gjennomse'},
    {languageCode: 'fr', label: 'Découvrir maintenant'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt vorbestellen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Preorder now'},
    {languageCode: 'pl', label: 'Zamów teraz w przedsprzedaży'},
    {languageCode: 'cs', label: 'Teď objednejte'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Vooruitbestellen'},
    {languageCode: 'it', label: 'Preordinalo ora'},
    {languageCode: 'da', label: 'Bestil nu'},
    {languageCode: 'sv', label: 'Förbeställ nu'},
    {languageCode: 'no', label: 'Forhåndsbestill nå'},
    {languageCode: 'fr', label: 'Précommandez'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Jetzt zugreifen'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'Get it now'},
    {languageCode: 'pl', label: 'Zapraszamy do zakupów'},
    {languageCode: 'cs', label: 'Využijte nyní'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Grijp nu uw kans'},
    {languageCode: 'it', label: 'Scaricalo ora'},
    {languageCode: 'da', label: 'Slå til nu'},
    {languageCode: 'sv', label: 'Beställ dem nu'},
    {languageCode: 'no', label: 'Adgang'},
    {languageCode: 'fr', label: 'À ne pas manquer'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Zu den Artikeln'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'To the articles'},
    {languageCode: 'pl', label: 'Do artykułów'},
    {languageCode: 'cs', label: 'Ke zboží'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Naar de artikelen'},
    {languageCode: 'it', label: 'Agli articoli'},
    {languageCode: 'da', label: 'Til artiklerne'},
    {languageCode: 'sv', label: 'Till våra artiklar'},
    {languageCode: 'no', label: 'Til artiklene'},
    {languageCode: 'fr', label: 'Allez aux articles'},
    {languageCode: 'sl', label: ''}
  ],
  [
    {languageCode: 'de', label: 'Zu den Neuheiten'},
    {languageCode: 'at', label: ''},
    {languageCode: 'ch', label: ''},
    {languageCode: 'en', label: 'To the novelties'},
    {languageCode: 'pl', label: 'Przejdź do nowości'},
    {languageCode: 'cs', label: 'K novinkám'},
    {languageCode: 'be', label: ''},
    {languageCode: 'nl', label: 'Naar de nieuwigheden'},
    {languageCode: 'it', label: 'Alle novità'},
    {languageCode: 'da', label: 'Til nyhederne'},
    {languageCode: 'sv', label: 'Till nyheterna'},
    {languageCode: 'no', label: 'Til nyhetene'},
    {languageCode: 'fr', label: 'Vers les nouveautés'},
    {languageCode: 'sl', label: ''}
  ],
];
