import {Component, ChangeDetectorRef, HostListener, OnInit, OnDestroy} from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import {FacadeService} from './services/facade.service';
import {Observable, Subscription} from 'rxjs';
import {ThemePalette} from '@angular/material/core';
import {ProgressBarMode} from '@angular/material/progress-bar';
import {filter} from 'rxjs/operators';
import {
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Event,
    Router,
    RouterEvent
} from '@angular/router';
import { environment } from '../environments/environment';
import {ShopCategory} from './shared/classes/shop-category';
import {User} from './shared/classes/user';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    subscriptions: Subscription[] = [];
    mobileQuery: MediaQueryList;
    progressPercentage = 0;
    color: ThemePalette = 'primary';
    mode: ProgressBarMode = 'determinate';
    shopCategories: ShopCategory[] = [];
    selectedShopCategory: ShopCategory;
    headline: string;
    mobileQueryListener: () => void;
    user$: Observable<User>;
    resizeTimeout: any;

    constructor(
        private router: Router,
        private fs: FacadeService,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher
    ) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this.mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.fs.progeressBarService.progressBar$.subscribe(data => {
            this.progressPercentage = data;
        });
        this.headline = environment.apiName;
        this.initRouterEvents();
    }

    ngOnInit(): void {
        this.user$ = this.fs.userService.user$;
        // pre-select shop-category => 'Main-Shop' (id: 1)
        this.shopCategories = this.fs.shopCategoryService.getAllSubjects();
        const category = this.fs.shopCategoryService.getOneSubject(1);
        if (category) {
            this.selectedShopCategory = category;
            this.fs.shopCategoryService.selectCategory(this.selectedShopCategory.id);
        }
    }

    private initRouterEvents(): void {
        const subscription = this.router.events
            .pipe(filter((event: Event) => this.isRouterEvent(event)))
            .subscribe((event: Event) => this.handleRouterEvent(event));

        this.subscriptions.push(subscription);
    }

    private isRouterEvent(event: Event): event is Event {
        return (
            event instanceof NavigationStart ||
            event instanceof NavigationEnd ||
            event instanceof NavigationCancel ||
            event instanceof NavigationError
        );
    }

    private handleRouterEvent(event: Event): void {
        const reuterEvent = event as RouterEvent;
        const url = reuterEvent.url;
        if (url === undefined || url === '/login' || url === '/section-infobox-import') {
            return;
        }

        if (event instanceof NavigationStart) {
            this.fs.progeressBarService.start();
        } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
            this.fs.progeressBarService.complete();
        }
    }

    progressInLoading(): void {
        this.progressPercentage = 0;
    }

    logout(): void {
        this.fs.searchDateService.clear();
        this.fs.userService.logout();
    }

    @HostListener('window:resize', ['$event']) onResize(event): void {
        const width = event.target.innerWidth;

        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
            this.fs.gridLayoutService.resizeWindow(width);
        }, 500);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
