import { NgxMatNativeDateAdapter } from '@angular-material-components/datetime-picker';
import * as moment from 'moment';
import {Injectable} from '@angular/core';

@Injectable()
export class DateTimeAdapter extends NgxMatNativeDateAdapter {

  override format(date: Date, displayFormat: object): string {
    return moment(date).format(displayFormat.toString());
  }
}
