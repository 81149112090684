<div class="mt-2" *ngIf="! entity.url; else infoTmp">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="id">

      <div fxLayout="column" class="form-group-wrapper mb-4">

        <div fxFlex="row" fxLayoutAlign="space-between center">
          <div>
            <button mat-stroked-button class="btn-white mr-1" (click)="onAddAllFromArrayElements()">Create predefined URLs</button>
            <button mat-stroked-button class="btn-white" (click)="onAddOneFormArrayElement()">Add one URL</button>
          </div>
          <button *ngIf="translateUrls && translateUrls.length > 0" class="btn-white" mat-stroked-button title="Delete all URLs" (click)="onDeleteAllUrls($event)">
            <i class="fa fa fa-trash-o"></i>
          </button>
        </div>

    </div>
      <div *ngIf="translateUrls && translateUrls.length > 0" formArrayName="mappedTranslateUrls">
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between center" [formGroupName]="i" *ngFor="let t of registerFormArray.controls; let i = index;">

          <input type="hidden" formControlName="languageCode">
          <input type="hidden" formControlName="countryCode">

          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="15%">
              <mat-label>Locale</mat-label>
            <mat-select formControlName="locale" required>
              <mat-option>--</mat-option>
              <mat-option *ngFor="let item of comparisonTranslateUrls" [value]="item.locale">
                {{item.locale}}
              </mat-option>
            </mat-select>
            </mat-form-field>

          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="72%">
              <mat-label>Url</mat-label>
              <input matInput formControlName="url">
            </mat-form-field>

            <div fxFlex="5%">
                <i class="fa fa-trash-o" (click)="onDeleteOneUrl($event, i)" title="Delete URL"></i>
            </div>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <button *ngIf="translateUrls.length"
                mat-stroked-button
                debounceClick
                [debounceTime]="500"
                [disabled]="! form.valid"
                (onEvent)="onSubmit()">Save
        </button>
      </div>
  </form>
</div>

<ng-template #infoTmp>
  <app-message-box [type]="messageBoxType.Warning" [message]="'URLs have already been loaded. Please delete all automatically generated URLs.'"></app-message-box>
</ng-template>
