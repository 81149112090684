<div class="app-container" [class.app-is-mobile]="mobileQuery.matches" *ngIf="user$ | async as user; else login">
  <mat-toolbar color="primary" class="app-toolbar">
    <div fxLayout="row" fxLayoutAlign="center center">
      <button mat-icon-button (click)="snav.toggle()">
        <mat-icon title="Menu">menu</mat-icon>
      </button>
      <span class="menu-item"><a [routerLink]="['dashboard']">{{headline}}</a></span>
    </div>

    <div *ngIf="user$ | async">
      <button class="btn-logout" mat-button (click)="logout()">
        <mat-icon title="Logout">logout</mat-icon>
        <span>Logout</span>
      </button>
    </div>

  </mat-toolbar>
  <mat-progress-bar
    [color]="color"
    [mode]="mode"
    [value]="progressPercentage"
    (animationEnd)="progressInLoading()">
  </mat-progress-bar>

  <mat-sidenav-container  class="app-sidenav-container"
                         [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
    <mat-sidenav #snav [mode]="mobileQuery.matches ? 'over' : 'side'"
                 [fixedInViewport]="mobileQuery.matches" fixedTopGap="56">
      <mat-nav-list>
        <app-menu></app-menu>
      </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>
      <main>
        <router-outlet></router-outlet>
        <app-messages></app-messages>
      </main>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<ng-template #login>
  <app-login></app-login>
</ng-template>
