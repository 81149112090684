import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {Shop} from '../shared/classes/shop';
import {TranslateUrl} from '../shared/classes/translateUrl';
import {LANGUAGES_EXPANDED} from '../shared/consts';


@Injectable({
  providedIn: 'root'
})
export class MainShopService extends BaseService<Shop> {

  languagesExpanded = LANGUAGES_EXPANDED;
  constructor(
    protected override http: HttpClient
  ) {
    super(http, `${environment.apiBaseUrl}/mainShops`);
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.findAll().subscribe(data => {
        this.data = data;
        this.subject.next(this.data);
        resolve();
      });
    });
  }

  public getAllTranslateUrls(): TranslateUrl[] {
    const urls = [];
    this.languagesExpanded.forEach(l => {
        if (l.languageCode && l.countryCode) {
          const locale = `${l.languageCode}_${l.countryCode}`;
          urls.push(new TranslateUrl(locale, l.languageCode, l.countryCode, null));
        }
    });
    return urls;
  }

}
