import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {WeekdaysHelper} from '../../classes/weekdays-helper';
import {ALL_WEEKDAYS_AS_BIT} from '../../consts';

@Component({
    selector: 'app-weekdays-info',
    template: '<span>{{weekdaysInfo}}</span>'
})
export class WeekdaysInfoComponent implements OnInit, OnChanges {
    @Input() weekdays: number | undefined;
    weekdaysInfo: string | undefined;

    constructor() {}
    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['weekdays']) {
            this.init();
        }
    }

    init(): void {
        if (this.weekdays && this.weekdays <= 0) {
            this.weekdaysInfo = 'all weekdays disabled';
        } else if (this.weekdays === ALL_WEEKDAYS_AS_BIT) {
            this.weekdaysInfo = 'all weekdays';
        } else {
            if (this.weekdays) {
                const list = WeekdaysHelper.getListOfSelectedWeekdays(this.weekdays);
                if (list) {
                    this.weekdaysInfo = list.reduce((acc, curr) => acc + curr.shortName + '  ', '');
                }
            }
        }
    }
}
