<p class="__bold mt-2 mb-4">Change Password</p>

<app-message-box *ngIf="message"
    [message]="message"
    [type]="messageBoxType"
></app-message-box>

<form *ngIf="! success" [formGroup]="form" (ngSubmit)="onSubmit()" class="form-content mt-4">

    <mat-form-field appearance="standard" floatLabel="always">
        <mat-label>Login Password</mat-label>
        <input type="password" matInput formControlName="currentPassword">
        <mat-error
                *ngIf="(registerFormControl.currentPassword.touched || submitted) && registerFormControl.currentPassword.errors?.required">
            Password is required
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" floatLabel="always">
        <mat-label>New Password</mat-label>
        <input type="password" matInput formControlName="newPassword">
        <mat-error
                *ngIf="(registerFormControl.newPassword.touched || submitted) && registerFormControl.newPassword.errors?.required">
            Password is required
        </mat-error>
        <mat-error
                *ngIf="(registerFormControl.newPassword.touched || submitted) && registerFormControl.newPassword.errors?.pattern">
            Password must contain at least one number and one uppercase and lowercase letter, and at least 6 to 25 characters
        </mat-error>
    </mat-form-field>

    <mat-form-field appearance="standard" floatLabel="always">
        <mat-label>Confirm Password</mat-label>
        <input type="password" matInput formControlName="confirmPassword">
        <mat-error
                *ngIf="(registerFormControl.confirmPassword.touched || submitted) && registerFormControl.confirmPassword.errors?.required">
            Password is required
        </mat-error>
        <mat-error *ngIf="(registerFormControl.confirmPassword.touched || submitted) && registerFormControl.confirmPassword.hasError('matching')">
            Password must match with new Password.
        </mat-error>
    </mat-form-field>

    <button class="mt-4" mat-stroked-button type="submit"
            [disabled]="!form.valid">Save
    </button>
</form>

<button *ngIf="success" class="btn btn-primary btn-lg mt-4" (click)="onRelogin()">Login</button>







