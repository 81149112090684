import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {BaseService} from './base-service';
import {UserRole} from '../shared/classes/user-role';

@Injectable({
  providedIn: 'root'
})
export class UserRoleService extends BaseService<UserRole> {
  constructor(
      protected override http: HttpClient
  ) {
    super(http, `${environment.apiBaseUrl}/userRoles`);
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.findAll().subscribe(data => {
        this.data = data;
        this.subject.next(this.data);
        resolve();
      });
    });
  }
}
