<div
  fxLayout="row"
  fxLayout.lt-md="column"
  fxLayoutGap="20px"
  fxLayoutGap.xs="0"
>
  <div
    *ngIf="displayGlobalColumn"
    [fxFlex]="CSS_LAYOUT_CONF['dashboardCol']['flex-min-width']"
    fxFlex.lt-md="100%"
  >
    <section class="header">
      <p class="__bold">{{MEDIUM_CATEGORY_CONF?.HEADLINE_CONFIGURE_GLOBAL_UNIT}}</p>
    </section>
    <mat-divider></mat-divider>
    <section
      *ngIf="this.shopCategory?.id == 1; else noContentTpl"
      class="card-link"
      fxLayout="row wrap"
    >
      <mat-card
        class="card-country"
        fxFlex="100%"
        routerLink="global">
        <mat-card-header>
          <mat-card-title>
            <p>{{MEDIUM_CATEGORY_CONF?.HEADLINE}}</p>
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </section>
    <ng-template #noContentTpl>
      <app-message-box [type]="MessageBoxType.NoContent" [message]="'category is not active'"></app-message-box>
    </ng-template>
  </div>
  <div *ngIf="displayIndividualColumn" fxFlex>
    <section class="header">
      <p class="__bold">{{MEDIUM_CATEGORY_CONF?.HEADLINE_CONFIGURE_INDIVIDUAL_UNIT}}</p>
    </section>
    <mat-divider></mat-divider>
    <section
      *ngIf="this.shopCategory?.id == 1; else noContentTpl2"
      class="card-link"
      fxLayout="row wrap"
      fxLayoutGap="4px"
      fxLayoutGap.xs="4px"
    >
      <mat-card
        class="card-country"
        fxFlex="400px"
        fxFlex.xs="100%"
        [routerLink]="[this.MEDIUM_CATEGORY_CONF?.ROUTE, shop?.id]" *ngFor="let shop of shops">
        <mat-card-header>
          <img mat-card-avatar [src]="getImagePath(shop?.countryCode)" [alt]="shop?.name">
          <mat-card-title>
            <p>L-SHOP-TEAM</p>
            <p>{{shop?.name}}</p>
          </mat-card-title>
        </mat-card-header>
      </mat-card>
    </section>
    <ng-template #noContentTpl2>
      <app-message-box [type]="MessageBoxType.NoContent" [message]="'category is not active'"></app-message-box>
    </ng-template>
  </div>
</div>

