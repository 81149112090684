import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageBoxTypeEnum} from '../../../enum/message-box-type-enum';
import {XlsxImporterService} from '../../../../services/xlsx-importer.service';
import {FacadeService} from '../../../../services/facade.service';
import {INFO_TEXT, LANGUAGES, LANGUAGES_EXPANDED} from '../../../consts';
import {Shop} from '../../../classes/shop';
import * as _moment from 'moment';
import {DateHelper} from '../../../classes/date-helper';
import {Mapper} from '../../../classes/mapper';
import {Subscription} from 'rxjs';
import {Message} from '../../../classes/message';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DownloadXlsxComponent} from '../../download-xlsx/download-xlsx.component';
import {DynamicDialogComponent} from '../../dialog/dynamic-dialog/dynamic-dialog.component';
import {MediumCategory} from '../../../classes/medium-category';
import {VisualComponent} from '../../../classes/visual-component';
const moment = _moment;

@Component({
  selector: 'app-visual-component-import',
  templateUrl: './visual-component-import.component.html',
  styleUrls: ['./visual-component-import.component.css']
})
export class VisualComponentImportComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  title: string;
  form: FormGroup;
  data: any[] = [];
  collection: any[] = [];
  selectedCollection: any[] = [];
  columns: any[] = [];
  shops: Shop[] = [];
  mediumCategories: MediumCategory[] = [];
  successMessage: string;
  errorMessages: string[] = [];
  messageBoxType = MessageBoxTypeEnum;
  loading: boolean;
  noContent: boolean;
  submitted: boolean;
  languagesExpanded = LANGUAGES_EXPANDED;
  validColumnLabels = ['name', 'startDate', 'endDate', 'textType', 'shopCountryCode'];

  constructor(
      private fb: FormBuilder,
      private fs: FacadeService,
      private xlsxImporterService: XlsxImporterService,
      protected router: Router,
      private dialog: MatDialog
  ) {
    this.title = 'Import Teaser-Specials & Sale';
    this.shops = this.fs.mainShopService.getAllSubjects();
    this.mediumCategories = this.fs.mediumCategoryService.getAllSubjects().filter(cat => cat.name === 'Teaser_Specials_Sale');
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    const formGroup: FormGroup = this.fb.group({
      importFile: [null, Validators.required],
      mediumCategory: [null, Validators.required]
    });

    this.form = formGroup;
  }

  onFileSelected(event): void {
    this.resetImportData();

    const selectedMediumCategory = this.form.get('mediumCategory').value || null;
    if (! selectedMediumCategory) {
      return;
    }

    const languageCodes = this.languagesExpanded.map(obj => obj.languageCode + '_' + obj.countryCode);
    const headlines = this.validColumnLabels.concat(languageCodes);

    const file: File = event.target.files[0];
    this.xlsxImporterService.importFile(file, headlines).then(response => {

      this.columns = this.validColumnLabels.map(col => ({columnDef: col, header: col}));
      this.collection = response.data;

      if (this.collection.length === 0) {
        this.successMessage = `No data to import.`;
      }

    }).catch(error => {
      this.errorMessages.push(error.message);
    });
  }

  onSelectedDataSource(data: any[]): void {
    this.resetErrorMessage();
    this.selectedCollection = data;
  }

  onSubmit(): void {
    this.resetErrorMessage();
    this.submitted = true;
    if (this.form.valid) {
      if (this.selectedCollection) {

        try {
          const collection = [];
          const selectedMediumCategory = this.form.get('mediumCategory').value;

          this.selectedCollection.forEach(row => {
            const sName = row.name ? row.name.trim() : null;
            const shopCountryCode = row.shopCountryCode ? row.shopCountryCode.trim() : null;
            const sStartDate = row.startDate ? row.startDate : null;
            const sEndDate = row.endDate ? row.endDate : null;
            const sTextType = row.textType ? row.textType : null;
            const dateFormat = DateHelper.DATE_TIME_FORMATS.parse.dateOutput;

            if (! sName) {
              throw new Error('Value name is required');
            }

            if (! sStartDate) {
              throw new Error('Value start date is required');
            }

            if (! sEndDate) {
              throw new Error('Value end date is required');
            }

            if (! sEndDate) {
              throw new Error('Value end date is required');
            }

            if (! sTextType) {
              throw new Error('The value textType is required. Set \'headline\' or \'subheadline\' for this column');
            }

            if (sTextType && ! ['headline', 'subheadline'].includes(sTextType)) {
              throw new Error('Value textType is invalid. Set \'headline\' or \'subheadline\' for this column');
            }

            if (! DateHelper.isValidDate(sStartDate)) {
              throw new Error('Value start date is invalid');
            }

            if (! DateHelper.isValidDate(sStartDate)) {
              throw new Error('Value end date is invalid');
            }

            if (! DateHelper.isValidDateRange(sStartDate, sEndDate)) {
              throw new Error('Date-Range between start- and end date is invalid');
            }

            const startDate = moment(sStartDate).format(dateFormat);
            const endDate = moment(sEndDate).format(dateFormat);

            let shop;
            if (shopCountryCode) {
                shop = this.shops.find(s => s.countryCode.toLowerCase() === shopCountryCode.toLowerCase());
                if (! shop) {
                  throw new Error(`Shop with country code '${shopCountryCode}' not exists`);
                }
            }

            const shopId = shop ? shop.id : null;
            let entity;

            switch (sTextType) {
              case 'headline':
                entity = this.createEntity(sName, shopId, selectedMediumCategory.id, startDate, endDate, sTextType, row);
                collection.push(entity);
                break;
              case 'subheadline':
                entity = collection.find(obj => obj.name === sName && obj.startDate === startDate && obj.endDate === endDate && obj.shopId === shopId);
                if (entity) {
                  this.mapTranslations(entity, sTextType, row);
                }
                else {
                  entity = this.createEntity(sName, shopId, selectedMediumCategory.id, startDate, endDate, sTextType, row);
                  collection.push(entity);
                }
                break;
            }

          });

          if (collection.length > 0) {
            this.fs.progeressBarService.start();
            this.processSave(collection)
                .then(() => {
                  this.successMessage = 'Success! Import Data';
                  this.successHandling(this.successMessage);
                })
                .catch(error => {
                  /*console.error(error);*/
                }).finally(() => {
              this.fs.progeressBarService.complete();
              this.resetImportData();
              this.router.navigate(['/visual-component-import'], {skipLocationChange: true});
            });
          }

        } catch (error) {
          if (error instanceof Error) {
            this.errorMessages.push(error.message);
          } else {
            console.error('An unknown error occurred:', error);
          }
        }
      }
      else {
        throw new Error('No rows selected');
      }
    }
  }

  private createEntity(name, shopId, mediumCategoryId, startDate, endDate, textType, cols): VisualComponent {
    const entity = Mapper.createVisualComponent();
    entity.name = name;
    entity.shopId = shopId;
    entity.mediumCategoryId = mediumCategoryId;
    entity.startDate = startDate;
    entity.endDate = endDate;
    entity.active = false;
    return this.mapTranslations(entity, textType, cols);
  }

  private mapTranslations(entity: VisualComponent, textType: string, row: any): VisualComponent {
    entity.mappedTranslations.forEach(t => {
      let key;
      if (t.languageCode === 'en' && ! t.countryCode) {
        key = 'en';
      }
      else {
        key = `${t.languageCode}_${t.countryCode}`;
      }
      if (row[key]) {
        switch (textType) {
          case 'headline':
            t.headline = row[key].trim();
            break;
          case 'subheadline':
            t.subheadline = row[key].trim();
            break;
        }
      }
    });

    entity.translations = JSON.stringify(entity.mappedTranslations);
    return entity;
  }

  processSave(collection): Promise<any> {
    return new Promise((resolve, reject) => {
      let successCount = 0;
      let errorCount = 0;
      const total = collection.length;

      collection.forEach(entity => {
        const subscription = this.fs.visualComponentService.post(entity).subscribe(
            (result: VisualComponent) => {
              this.fs.visualComponentService.insertSubject(result);
              successCount++;
              checkCompletion();
            },
            error => {
              errorCount++;
              checkCompletion();
            }
        );
        this.subscriptions.push(subscription);
      });

      function checkCompletion(): void {
        if (successCount + errorCount === total) {
          const success = successCount === total;
          if (success) {
            resolve(true);
          } else {
            reject(`Error processing ${errorCount} elements.`);
          }
        }
      }
    });
  }

  resetImportData(): void {
    this.successMessage = null;
    this.errorMessages = [];
    this.data = [];
    this.collection = [];
    this.selectedCollection = [];
  }

  resetForm(): void {
    this.resetImportData();
    this.form.reset();
    this.submitted = false;
  }

  resetErrorMessage(): void {
    this.errorMessages = [];
  }

  onResetForm(event): void {
    event.preventDefault();
    this.resetForm();
  }

  onDisplayDialog(e: Event): void {
    e.preventDefault();
    const languageCodes = this.languagesExpanded.map(obj => {
      return (obj.languageCode === 'en' && ! obj.countryCode) ? 'en' :  obj.languageCode + '_' + obj.countryCode;
    });

    const config = {
      title: 'Info',
      headline: 'Information for .xlsx import to create teaser:',
      content: INFO_TEXT.TEASER_SPECIALS_IMPORT,
      file: {
        name: 'import_teaser_specials_and_sale.xlsx',
        headlines: this.validColumnLabels.concat(languageCodes),
        rows: []
      },
      dialogConfig: null
    };
    this.openDialog(config);
  }

  openDialog(config: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(DynamicDialogComponent, {
      data: { config, component: DownloadXlsxComponent }
    });
  }

  successHandling(message: string): void {
    this.fs.messageService.add(new Message('success', message, true), 10000);
    this.fs.progeressBarService.complete();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(obj => obj.unsubscribe());
  }
}
