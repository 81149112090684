import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripHtml'
})
export class StripHtmlPipe implements PipeTransform {
  transform(str: string): any {
    if ((str === null) || (str === '')) {
      return '';
    } else {
      str = str.replace(/<(?:.|\n)*?>/gm, '');
      str = str.replace(/&nbsp;/g, ' ');
      str = str.replace(/\s\s+/g, ' ');
      return str.trim();
    }
  }
}
