import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'strongify'
})
export class StrongifyPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) { }
  transform(value): any {
    return this.formatTextWithStrongTags(value);
  }

  private formatTextWithStrongTags(text: string): string {
    if (! text || ! text.includes('*')) {
      return text;
    }
    return text.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
  }
}
