import {Translation} from './translation';
import {StripHtmlPipe} from '../pipes/strip-html.pipe';
import {LANGUAGES, LANGUAGES_EXPANDED} from '../consts';

export class TranslationMapper {
  static stripHtmlPipe: StripHtmlPipe;

  constructor() {
    TranslationMapper.stripHtmlPipe = new StripHtmlPipe();
  }

  public static getMainLanguageByCountryCode(countryCode: string): string {
    return LANGUAGES_EXPANDED.find(l => l.countryCode === countryCode).languageCode.toLowerCase();
  }

  public static getTranslationByLanguageCode(translations: Array<Translation>, languageCode: string = 'en'): Translation {
    if (! translations || translations.length === 0) {
      return null;
    }
    return translations.find(obj => obj.languageCode === languageCode);
  }

  public static getLanguageIndex(languageCode: string): number {
      return LANGUAGES.findIndex(obj => obj.languageCode === languageCode);
  }

  public static create(): Translation {
    return new Translation();
  }

  public static fromObject(source: any, target?: Translation, skipProperties?: string[]): Translation {
    target = target ? target : TranslationMapper.create();

    Object.keys(source).forEach(prop => {
      if (target.hasOwnProperty(prop)) {
        if (prop === 'headline') {
          target[prop] = TranslationMapper.compareTextContents(source.headline, source.headlineFormatted);
        }
        if (prop === 'subheadline') {
          target[prop] = TranslationMapper.compareTextContents(source.subheadline, source.subheadlineFormatted);
        } else {
          target[prop] = source[prop];
        }
      }
    });

    if (skipProperties && skipProperties.length) {
      skipProperties.forEach(prop => {
        if (target.hasOwnProperty(prop)) {
          delete target[prop];
        }
      });
    }
    return target;
  }

  public static fromArray(arr: any[], target?: Translation, deleteProps?: string[]): Translation[] {
    const collection: Translation[] = [];
    arr.forEach(obj => collection.push(TranslationMapper.fromObject(obj, target, deleteProps)));
    return collection;
  }

  public static compareTextContents(original: string, formatted: string): string {
    let text = original;
    if (formatted) {
      const stripheadline = TranslationMapper.stripHtmlPipe.transform(formatted);
      if (original !== stripheadline) {
        text = stripheadline;
      }
    }
    return text;
  }
}
