import {IFormData} from '../shared/interfaces/form-data';
import {FormType} from '../shared/enum/form-type-enum';
import {Subscription} from 'rxjs';
import {FacadeService} from '../services/facade.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {VisualComponent} from '../shared/classes/visual-component';
import {DialogHandler} from '../shared/classes/dialog-handler';

@Injectable({
  providedIn: 'root'
})
export abstract class BaseVisualComponentListComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  dialogHandler: DialogHandler;
  REDIRECT_TO_ROUTE = null;

  protected constructor(
      protected fs: FacadeService,
      protected router: Router
  ) {
    this.dialogHandler = new DialogHandler(this.fs, this.fs.visualComponentService);
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
      this.subscriptions.forEach(obj => obj.unsubscribe);
    }
  }

  onSubmitForm($event: IFormData<VisualComponent>): void {
    if (!$event.obj) { return; }
    const data = $event.obj;
    let subscription;
    switch ($event.formType) {
      case FormType.INSERT:
        subscription = this.fs.visualComponentService.post(data).subscribe((obj) => {
           console.log('INSERT :', obj);
        });
        break;
      case FormType.UPDATE:
        subscription = this.fs.visualComponentService.update(data.id, data).subscribe((obj) => {
           console.log('UPDATE :', obj);
        });
        break;
    }
    this.subscriptions.push(subscription);
  }

  async onDialogEvent($event: any): Promise<any> {
    if (! $event.data && ! $event.dialogType) { return; }
    await this.dialogHandler.process($event).then(obj => {
      this.router.navigate([this.REDIRECT_TO_ROUTE]);
    });
  }
}
