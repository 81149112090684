import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {ShopCategory} from '../shared/classes/shop-category';
import {BehaviorSubject} from 'rxjs';
import {NGXLogger} from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class ShopCategoryService extends BaseService<ShopCategory> {
  protected subjectSelectedShopCategory = new BehaviorSubject<ShopCategory>(null);
  observableSelectedShopCategory$ = this.subjectSelectedShopCategory.asObservable();

  constructor(
    protected override http: HttpClient,
    protected logger: NGXLogger
  ) {
    super(http, `${environment.apiBaseUrl}/shopCategory`);
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.findAll().subscribe(data => {
        this.data = data;
        this.subject.next(this.data);
        resolve();
      });
    });
  }

  selectCategory(id: number): void {
    const category = this.subject.getValue().find(obj => obj.id === id);
    if (category) {
      this.subjectSelectedShopCategory.next(category);
    }
  }
}
