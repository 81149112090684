import { Component, OnInit } from '@angular/core';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {
  messageBoxTypeEnum = MessageBoxTypeEnum;
  message: string;
  constructor() {}

  ngOnInit(): void {
    this.message = 'Oops, this page does not come with any content...';
  }
}
