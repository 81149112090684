import {IObject} from '../interfaces/iobject';
import {Translation} from './translation';
import {InfoboxCategory} from './infobox-category';
import {Shop} from './shop';
import {InfoboxType} from './infobox-type';

export class Infobox implements IObject {
  constructor(
    public id?: number,
    public name?: string,
    public specialIdentifier?: string,
    public infoboxTypeId?: number,
    public infoboxType?: InfoboxType,
    public infoboxCategoryId?: number,
    public infoboxCategory?: InfoboxCategory,
    public shopId?: number,
    public shop?: Shop,
    public articleId?: string,
    public translations?: string,
    public mappedTranslations?: Translation[],
    public startDate?: string,
    public endDate?: string,
    public weekdays?: number,
    public orderIndex?: number,
    public active?: boolean,
    public archived?: boolean,
    public isProtected?: boolean,
    public insertTs?: string,
    public updateTs?: string
  ) {}
}
