import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {MediumCategory} from '../shared/classes/medium-category';

@Injectable({
    providedIn: 'root'
})
export class MediumCategoryService extends BaseService<MediumCategory> {

    constructor(
        protected override http: HttpClient
    ) {
        super(http, `${environment.apiBaseUrl}/mediumCategory`);
    }

    init(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.findAll().subscribe(data => {
                this.data = data;
                this.subject.next(this.data);
                resolve();
            });
        });
    }
}
