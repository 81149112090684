import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {ImageCroppedEvent, LoadedImage, base64ToFile} from 'ngx-image-cropper';
import {FacadeService} from '../../../services/facade.service';
import {Medium} from '../../classes/medium';
import {ImageType} from '../../enum/image-type-enum';
import {IFormData} from '../../interfaces/form-data';
import {FormType} from '../../enum/form-type-enum';
import {NGX_IMAGE_CROPPER_CONFIG} from '../../consts';
import {DomSanitizer} from '@angular/platform-browser';
import {VisualComponent} from '../../classes/visual-component';
import {MediumCategoryEnum} from '../../enum/medium-category-enum';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit, OnChanges {
  @Input() entity: Medium | VisualComponent;
  @Input() imageType: ImageType;
  @Input() aspectRatio?: number;
  @Input() resizeToHeight?: number;
  @Output() imageEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  croppedImage: any = '';
  imageFile: File;
  format: string;
  maintainAspectRatio: boolean;
  defaultFileName: string;
  config = NGX_IMAGE_CROPPER_CONFIG;

  constructor(
    private fs: FacadeService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (! this.format) {
      this.format = this.config.format;
    }
    if (! this.aspectRatio) {
      this.aspectRatio = this.config.aspectRatio;
    }
    if (! this.resizeToHeight) {
      this.resizeToHeight = this.config.resizeToHeight;
    }
    if (this.maintainAspectRatio === undefined) {
      this.maintainAspectRatio = this.config.maintainAspectRatio;
    }
    if (! this.defaultFileName) {
      this.defaultFileName = this.config.defaultFileName;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      switch (MediumCategoryEnum[this.entity.mediumCategoryId]) {
        case 'Slider':
        case 'Teaser':
          this.fs.progeressBarService.start();
          this.fs.mediumService.imageLoad(this.entity.id, ImageType.DESKTOP_LANDSCAPE).subscribe(image => {
            this.imageFile = image;
            this.fs.progeressBarService.complete();
          });
          break;

      }
    }
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.objectUrl;
  }

  imageLoaded(image: LoadedImage): void {
    console.log('imageLoaded: ', image);
  }

  cropperReady(): void {
    console.log('cropper ready');
  }

  loadImageFailed(): void {
    console.log('imageFailed');
  }

  onSave(): void {
   if (this.croppedImage) {
     this.convertBase64ToBlob(this.croppedImage).then(blob => {
       const mimeType = `image/${this.format}`;
       const file = new File([blob], this.defaultFileName, {lastModified: new Date().getTime(), type: mimeType});

       const data = {
         formType: FormType.INSERT,
         obj: this.entity,
         image: file,
         imageType: this.imageType
       } as IFormData<Medium | VisualComponent>;

       this.imageEvent.emit(data);
     });
    }
  }

  async convertBase64ToBlob(base64: string): Promise<Blob> {
    const response = await fetch(base64);
    const blob = await response.blob();
    return blob;
  }
}
