import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {TableButtonAction} from '../../interfaces/table-button-action';
import {TableColumn} from '../../interfaces/table-column';
import {DateHelper} from '../../classes/date-helper';

@Component({
  selector: 'app-mat-table',
  templateUrl: './mat-table.component.html',
  styleUrls: ['./mat-table.component.css'],
})
export class MatTableComponent implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) { this.dataSource.sort = sort; }
  @Output() action: EventEmitter<TableButtonAction> = new EventEmitter<TableButtonAction>();
  @Output() selectedDataSource: EventEmitter<any[]> = new EventEmitter<any[]>();
  @Input() columns: Array<TableColumn>;
  @Input() dataset: Array<any> = [];
  @Input() displayFilter: boolean;
  @Input() displayActionColumn: boolean;
  @Input() displaySelectColumn: boolean;
  @Input() displayTableHeaderButtons: boolean;
  @Input() displayPagination: boolean;
  @Input() rowsPerSite = [10, 20, 50, 100];

  DateHelper = DateHelper;
  dataSource: MatTableDataSource<any>;
  selection = new SelectionModel<any>(true, []);
  displayedColumns: string[] = [];
  value: any;

  constructor() {}

  ngOnInit(): void {
    if (this.displaySelectColumn) {
      this.displayedColumns.push('select');
    }
    this.displayedColumns = this.displayedColumns.concat(this.columns.map(x => x.columnDef));

    if (this.displayActionColumn) {
      this.displayedColumns.push('action');
    }

    if (this.displayPagination && this.rowsPerSite && this.rowsPerSite.length > 0) {
      if (this.dataset.length < this.rowsPerSite[0]) {
        this.displayPagination = false;
      }
    }

    this.dataSource = new MatTableDataSource<any>(this.dataset);
    this.dataSource.paginator = this.paginator;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataset) {
      this.dataset = changes.dataset.currentValue;
      this.dataSource = new MatTableDataSource<any>(this.dataset);
    }
  }

  onRefresh(e: Event): void {
    e.preventDefault();
    this.dataSource.filter = null;
  }

  onClearSearch(e: Event): void {
    e.preventDefault();
    this.value = null;
    this.dataSource.filter = null;
  }

  onDelete(e: Event): void {
    e.preventDefault();
    // not implemented
  }

  onTableAction(e: TableButtonAction): void {
    this.action.emit(e);
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  selectHandler(row): void {
    this.selection.toggle(row);
    this.selectedDataSource.emit(this.selection.selected);
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

  }

  getColumnWidth(columnIndex: number): string {
    const currentColumn = this.columns[columnIndex];
    return (currentColumn.width) ? '0 0 ' + currentColumn.width : '0 0 1';
  }
  getColumnFormat(columnIndex: number): string {
    const currentColumn = this.columns[columnIndex];
    return (currentColumn.format) ? currentColumn.format : null;
  }
}


