import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {FacadeService} from 'src/app/services/facade.service';
import { CSS_LAYOUT_CONFIG } from 'src/app/shared/consts';
import {ActivatedRoute, Router} from '@angular/router';
import {sortByOrderIndex} from '../../../shared/classes/collection-filter';
import {VisualComponent} from '../../../shared/classes/visual-component';
import {MediumCategoryEnum} from '../../../shared/enum/medium-category-enum';
import {BaseVisualComponentListComponent} from '../../../base/base-visual-component-list.component';

@Component({
  selector: 'app-visual-component-list',
  templateUrl: './visual-component-list.component.html',
  styleUrls: ['./visual-component-list.component.scss']
})
export class VisualComponentListComponent extends BaseVisualComponentListComponent implements OnInit, OnDestroy {
  mediumCategory: MediumCategoryEnum;
  collection: VisualComponent[] = [];
  cssLayoutConfig;

  constructor(
      protected override fs: FacadeService,
      protected route: ActivatedRoute,
      protected override router: Router
  ) {
    super(fs, router);
    super.REDIRECT_TO_ROUTE = '/section-teaser-specials/global';
    this.cssLayoutConfig = CSS_LAYOUT_CONFIG;
    this.mediumCategory = MediumCategoryEnum.Teaser_Specials_Sale;
  }

  ngOnInit(): void {
    this.init();
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  private init(): void {
    super.REDIRECT_TO_ROUTE = '/section-teaser-specials/global';
    const subscription = this.fs.visualComponentService.observable$.subscribe(response => {
      this.collection = response.filter(res => {
        return (res.mediumCategory.id === Number(MediumCategoryEnum.Teaser_Specials_Sale)) &&
                ! res.shopId &&
                ! res.archived;
      });
      this.collection.sort(sortByOrderIndex);
    });
    this.subscriptions.push(subscription);
  }
}
