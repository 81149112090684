import {KeyValuePair} from './key-value-pair';
import {IKeyValuePair} from '../interfaces/i-key-value-pair';
import {CSS_COL_UNIT_VALUES} from '../consts';

export class CssStyleMapper {

  public static getSliderRowStyles(styles: any): string {
    return styles.find(style => style.key === 'rowHorizontalAlign').value;
  }

  public static getSliderColStyles(styles: any): string {
    let str = '';
    const skipKeys = ['rowHorizontalAlign', 'colUnit', 'fontFamily', 'fontHeadline', 'fontColor', 'buttonColor'];
    str += styles.filter(obj => ! skipKeys.includes(obj.key) && obj.value)
      .map(obj => obj.value)
      .join(' ');
    return str;
  }

  public static getTeaserColStyles(styles: any, rowCount: number): string {
    const style = styles.find(obj => obj.key === 'colUnit').value;
    if (rowCount === 1) {
      if (style && style.indexOf('box-slim') > -1) {
        return CSS_COL_UNIT_VALUES.find(obj => obj.key === 'large-sm-height').value;
      }
      else {
        return CSS_COL_UNIT_VALUES.find(obj => obj.key === 'large').value;
      }
    }
    else {
      return style;
    }
  }

  public static getSliderButtonStyles(styles: any): string {
    return styles.find(style => style.key === 'buttonColor').value;
  }

  public static create(): IKeyValuePair {
    return new KeyValuePair();
  }

  public static fromObject(source: any, target?: KeyValuePair, skipProperties?: string[]): KeyValuePair {
    target = target ? target : CssStyleMapper.create();
    Object.keys(source).forEach(prop => {
      if (target.hasOwnProperty(prop)) {
        target[prop] = source[prop];
      }
    });

    if (skipProperties && skipProperties.length) {
      skipProperties.forEach(prop => {
        if (target.hasOwnProperty(prop)) {
          delete target[prop];
        }
      });
    }
    return target;
  }

  public static fromArray(arr: any[], target?: KeyValuePair, deleteProps?: string[]): KeyValuePair[] {
    const collection: KeyValuePair[] = [];
    arr.forEach(obj => collection.push(CssStyleMapper.fromObject(obj, target, deleteProps)));
    return collection;
  }

  public static getValue(target: KeyValuePair[], key: string): any {
    return target.find(s => s.key === key).value || null;
  }
}
