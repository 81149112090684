import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CSS_LAYOUT_CONFIG} from '../shared/consts';

@Injectable({
  providedIn: 'root'
})
export class GridLayoutService {
  private sideNavWidth = 200;
  private appWidth: number;
  private contentColMinWidth: number;
  private contentColMaxWidth: number;
  private adaptationColWidth: number;
  private gapWidth: number;
  private defaultColDiff: number;
  private windowWidth = new BehaviorSubject<number>(0);
  private contentColLeft = new BehaviorSubject<number>(0);
  private contentColRight = new BehaviorSubject<number>(0);
  private adaptationCol = new BehaviorSubject<number>(0);
  adaptationCol$: Observable<number> = this.adaptationCol.asObservable();

  constructor() {
    this.adaptationColWidth = CSS_LAYOUT_CONFIG.adaptationCol['min-width-int'];
    this.contentColMinWidth = CSS_LAYOUT_CONFIG.contentCol['min-width-int'];
    this.contentColMaxWidth = CSS_LAYOUT_CONFIG.contentCol['max-width-int'];
    this.gapWidth = CSS_LAYOUT_CONFIG.layoutGap['width-int'];
    this.defaultColDiff = this.contentColMaxWidth - this.contentColMinWidth;
    this.init();
  }

  private init(): void {
    this.resizeContentCols();
    this.appWidth = this.calculateGridWidth();
    this.windowWidth.next(window.innerWidth);
  }

  resizeWindow(width: number): void {
    width = Math.round(width);
    if (width !== this.windowWidth.value) {
      this.windowWidth.next(width);
      this.resizeAdaptationCol(this.calculateAdaptationColWidth());
    }
  }

  resizeContentCols(): void {
    this.contentColLeft.next(this.contentColMaxWidth);
    this.contentColRight.next(this.contentColMaxWidth);
    this.adaptationCol.next(this.adaptationColWidth);
  }

  resizeAdaptationCol(width: number): void {
    width = Math.round(width);
    this.adaptationCol.next(width);
  }

  resizeContentColLeft(isNarrow: boolean): void {
    const width = (isNarrow) ? this.contentColMinWidth : this.contentColMaxWidth;
    this.contentColLeft.next(width);
    this.gridChangedHandler(isNarrow);
  }

  resizeContentColRight(isNarrow: boolean): void {
    const width = (isNarrow) ? this.contentColMinWidth : this.contentColMaxWidth;
    this.contentColRight.next(width);
    this.gridChangedHandler(isNarrow);
  }

  private gridChangedHandler(isNarrow: boolean): void {
    let width =  this.adaptationCol.value;
    if (isNarrow) {
      width += this.defaultColDiff;
    }
    else {
      width -= this.defaultColDiff;
    }
    this.adaptationCol.next(width);
  }

  private calculateGridWidth(): number {
    return this.sideNavWidth + this.contentColLeft.value + this.contentColRight.value + this.adaptationColWidth + (4 * this.gapWidth);
  }

  private calculateAdaptationColWidth(): number {
    let width = this.adaptationColWidth;
    if (this.appWidth > this.windowWidth.value) {
      width = this.adaptationColWidth - (this.appWidth - this.windowWidth.value);
    }
    return width;
  }
}
