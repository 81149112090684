<app-language-filter
        [settings]="filterSettings"
        (formDataEvent)="onSubmitFilter($event)"
></app-language-filter>

<mat-table *ngIf="dataSource && dataSource.data.length" [dataSource]="dataSource" matSort class="mat-elevation-z8">
    <!-- table dynamic columns -->
    <ng-container *ngFor="let column of columns; let i = index;" [matColumnDef]="column.columnDef">
        <mat-header-cell [style.flex]="getColumnWidth(i)" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
        <ng-container *cdkCellDef="let row;">
            <mat-cell [style.flex]="getColumnWidth(i)">
                <ng-container *ngIf="column.header === 'Url'; else col">
                    <a [href]="row[column.columnDef]" target="_blank">{{ row[column.columnDef]  }}</a>
                </ng-container>
                <ng-template #col>
                    {{ row[column.columnDef] }}
                </ng-template>
            </mat-cell>
        </ng-container>
    </ng-container>

    <!-- table action column -->
    <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let element" action-buttons [value]="element" (buttonAction)="onTableAction($event)">
            {{ element.weight }}
        </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<!-- table pagination -->
<mat-paginator
        [hidden]="! displayPagination"
        [pageSizeOptions]="rowsPerSite"
        showFirstLastButtons>
</mat-paginator>

<ng-container *ngIf="dataSource && ! dataSource.data.length">
    <app-message-box [type]="MessageBoxType.NoContent" [message]="'No URL loaded'"></app-message-box>
</ng-container>
