import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DateHelper} from '../../../classes/date-helper';
import {environment} from '../../../../../environments/environment';
import {MediumHelper} from '../../../helper/medium-helper';
import {VisualComponent} from '../../../classes/visual-component';
import {Translation} from '../../../classes/translation';

@Component({
  selector: 'app-visual-component-info',
  templateUrl: './visual-component-info.component.html',
  styleUrls: ['./visual-component-info.component.scss']
})
export class VisualComponentInfoComponent implements OnInit, OnChanges {
  @ViewChild('img', { static: false }) img: ElementRef;
  @Input() entity: VisualComponent;
  @Input() languageTabIndex;
  @Output() languageTabIndexEvent = new EventEmitter<number>();
  dateFormat = DateHelper.DATE_FORMATS.display.dateAndTime;
  imagesUrl: string;
  constructor() {
    this.imagesUrl = environment.imagesUrl;
  }
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {

    }
  }
  onTabChange(event: MatTabChangeEvent): void {
    if (this.languageTabIndex !== event.index) {
      this.languageTabIndex = event.index;
      this.languageTabIndexEvent.emit(this.languageTabIndex);
    }
  }
  isTranslated(entity: VisualComponent, code: string): boolean {
    return MediumHelper.isTranslated(entity, code);
  }
  getImagePath(imageName: string): string {
    return `${this.imagesUrl}/${imageName}`;
  }

  getLocale(t: Translation): string {
    return (t.languageCode === 'en' && ! t.countryCode) ? 'en' : `${t.languageCode}-${t.countryCode}`;
  }
}
