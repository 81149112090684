import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from './shared/components/page-not-found/page-not-found.component';
import {AuthGuard} from './core/guards/auth.guard';
import {LoginComponent} from './components/login/login.component';
import {MediumCategoryPageSelectionComponent} from './shared/components/medium-shop-category-selection/medium-category-page-selection.component';
import {UserRole} from './shared/enum/user-role-enum';
import {NoPermissionsComponent} from './shared/components/no-permissions/no-permissions.component';
import {ChangePasswordFormComponent} from './user/components/change-password-form/change-password-form.component';
import {UserInfoComponent} from './user/components/user-info/user-info.component';
import {DashboardComponent} from './shared/components/dashboard/dashboard.component';
import {InfoboxImportComponent} from './shared/components/infobox-import/infobox-import.component';
import {TeaserSpecialsGlobalModule} from './teaser-specials-global/teaser-specials-global.module';
import {
    VisualComponentImportComponent
} from './shared/components/visual-component/visual-component-import/visual-component-import.component';

// @ts-ignore
const routes: Routes = [
    {
        path: 'section-user', children: [
            {path: '', loadChildren: () => import('./user/user.module')
                    .then(m => m.UserModule)},
            {path: '**', component: PageNotFoundComponent},
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_USER]
        }
    },
    {
        path: 'section-slider', children: [
            {path: '', component: MediumCategoryPageSelectionComponent},
            {
                path: 'global',
                loadChildren: () => import('./global-slider/global-slider.module')
                    .then(m => m.GlobalSliderModule)
            },
            {
                path: 'main-shop',
                loadChildren: () => import('./main-shop-slider/main-shop-slider.module')
                    .then(m => m.MainShopSliderModule)
            },
            {
                path: 'archive',
                loadChildren: () => import('./archive-slider/archive-slider.module')
                    .then(m => m.ArchiveSliderModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_SLIDER]
        }
    },
    {
        path: 'section-slider-archive', children: [
            {
                path: '',
                loadChildren: () => import('./archive-slider/archive-slider.module')
                    .then(m => m.ArchiveSliderModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_SLIDER_ARCHIVE]
        }
    },
    {
        path: 'section-teaser', children: [
            {path: '', component: MediumCategoryPageSelectionComponent},
            {
                path: 'global',
                loadChildren: () => import('./global-teaser/global-teaser.module')
                    .then(m => m.GlobalTeaserModule)
            },
            {
                path: 'main-shop',
                loadChildren: () => import('./main-shop-teaser/main-shop-teaser.module')
                    .then(m => m.MainShopTeaserModule)
            },
            {
                path: 'archive',
                loadChildren: () => import('./archive-teaser/archive-teaser.module')
                    .then(m => m.ArchiveTeaserModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_TEASER]
        }
    },
    {
        path: 'section-simulator', children: [
            {path: '', loadChildren: () => import('./simulator/simulator.module')
                    .then(m => m.SimulatorModule)},
            {path: '**', component: PageNotFoundComponent},
        ], canActivate: [AuthGuard]
    },
    {
        path: 'section-teaser-archive', children: [
            {
                path: '',
                loadChildren: () => import('./archive-teaser/archive-teaser.module')
                    .then(m => m.ArchiveTeaserModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_TEASER_ARCHIVE]
        }
    },
    {
        path: 'section-teaser-specials', children: [
            {path: '', component: MediumCategoryPageSelectionComponent},
            {
                path: 'global',
                loadChildren: () => import('./teaser-specials-global/teaser-specials-global.module')
                    .then(m => TeaserSpecialsGlobalModule)
            },
            {
                path: 'main-shop',
                loadChildren: () => import('./teaser-specials/teaser-specials.module')
                    .then(m => m.TeaserSpecialsModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_TEASER_SPECIALS]
        }
    },
    {
        path: 'section-teaser-specials-archive', children: [
            {
                path: '',
                loadChildren: () => import('./archive-teaser-specials/archive-teaser-specials.module')
                    .then(m => m.ArchiveTeaserSpecialsModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_TEASER_SPECIALS_ARCHIVE]
        }
    },
    {
        path: 'section-infobox-customer', children: [
            {path: '', component: MediumCategoryPageSelectionComponent},
            {
                path: 'global',
                loadChildren: () => import('./customer-infobox-global/customer-infobox-global.module')
                    .then(m => m.CustomerInfoboxGlobalModule)
            },
            {
                path: 'main-shop',
                loadChildren: () => import('./customer-infobox/customer-infobox.module')
                    .then(m => m.CustomerInfoboxModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_INFOBOX]
        }
    },
    {
        path: 'section-infobox-article', children: [
            {path: '', component: MediumCategoryPageSelectionComponent},
            {
                path: 'global',
                loadChildren: () => import('./article-infobox-global/article-infobox-global.module')
                    .then(m => m.ArticleInfoboxGlobalModule)
            },
            {
                path: 'main-shop',
                loadChildren: () => import('./article-infobox/article-infobox.module')
                    .then(m => m.ArticleInfoboxModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_INFOBOX]
        }
    },
    {
        path: 'section-infobox-archive', children: [
            {
                path: '',
                loadChildren: () => import('./archive-infobox/archive-infobox.module')
                    .then(m => m.ArchiveInfoboxModule)
            },
            {path: '**', component: PageNotFoundComponent}
        ], canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_INFOBOX_ARCHIVE]
        }
    },
    {
        path: 'infobox-import',
        component: InfoboxImportComponent,
        canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_INFOBOX]
        }
    },
    {
        path: 'visual-component-import',
        component: VisualComponentImportComponent,
        canActivate: [AuthGuard],
        data: {
            role: [UserRole.ROLE_CONFIGURE_TEASER_SPECIALS]
        }
    },
    {path: 'dashboard', component: DashboardComponent},
    {path: 'profile/info', component: UserInfoComponent},
    {path: 'profile/change-password', component: ChangePasswordFormComponent},
    {path: 'login', component: LoginComponent},
    {path: 'not-found', component: PageNotFoundComponent},
    {path: 'no-permissions', component: NoPermissionsComponent},
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: '**', component: PageNotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
