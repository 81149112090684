import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {VisualComponent} from '../shared/classes/visual-component';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {ImageType} from '../shared/enum/image-type-enum';
import {catchError, map, retry, share} from 'rxjs/operators';
import {ApiResponse} from '../shared/interfaces/api-response';
import {Mapper} from '../shared/classes/mapper';
const RETRY_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class VisualComponentService extends BaseService<VisualComponent> {
    constructor(
        protected override http: HttpClient
    ) {
        super(http, `${environment.apiBaseUrl}/visualComponent`);
    }

    init(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.findAll().subscribe(data => {
                this.data = data;
                this.subject.next(this.data);
                resolve();
            });
        });
    }

    override findOne(id: number): Observable<VisualComponent> {
        return this.http.get<ApiResponse>(this.url + '/' + Number(id)).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override findAll(): Observable<VisualComponent[]> {
        return this.http.get<ApiResponse>(this.url).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data) {
                    response.data.forEach(obj => {
                        Mapper.parseTranslations(obj);
                        Mapper.parseTranslateUrls(obj);
                    });
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override post<T>(t): Observable<T> {
        return this.http.post<ApiResponse>(this.url, t).pipe(
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override update<T>(id: number, t: T): Observable<T> {
        return this.http.put<ApiResponse>(this.url + '/' + Number(id), t, {}).pipe(
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    imageUpload(id: number, file: File, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('file', file);
        formData.append('imageType', imageType);
        return this.http.post<any>(`${this.url}/imageUpload`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
    }

    imageDelete(id: number, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('imageType', imageType);

        return this.http.post<any>(`${this.url}/imageDelete`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
    }

    imageLoad(id: number, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('imageType', imageType);

        return this.http.post(`${this.url}/imageLoad`, formData, {
            reportProgress: true,
            responseType: 'blob'
        });
    }

    copy(data: any): Observable<any> {
        return this.http.post<ApiResponse>(`${this.url}/copy`, data).pipe(
            map((response) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    loadMainShopUrls(data: any): Observable<any> {
        return this.http.post<ApiResponse>(`${this.url}/loadMainShopUrls`, data).pipe(
            map((response: ApiResponse) => {
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    getGlobalAndIndividual(shopId: number, mediumCategoryId: number, date: string): Observable<VisualComponent[]> {
        return this.http.get<ApiResponse>(`${this.url}/globalAndIndividual/${shopId}/${mediumCategoryId}/${date}`)
            .pipe(
                map((response) => {
                    if (response.data) {
                        response.data.forEach(obj => {
                            Mapper.parseTranslations(obj);
                            Mapper.parseTranslateUrls(obj);
                        });
                    }
                    return response.data;
                }), catchError(this.errorHandler)
            );
    }

    findAllByFilter(filter: any): Observable<VisualComponent[]> {
        const queryParams = new HttpParams().appendAll(filter);
        return this.http.get<ApiResponse>(`${this.url}/filter`, {params: queryParams})
            .pipe(
                map((response) => {
                    if (response.data) {
                        response.data.forEach(obj => {
                            Mapper.parseTranslations(obj);
                            Mapper.parseTranslateUrls(response.data);
                        });
                    }
                    return response.data;
                }), catchError(this.errorHandler)
            );
    }
}
