import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import {Subject} from 'rxjs';
import {DateHelper} from '../shared/classes/date-helper';

@Injectable({
  providedIn: 'root'
})
export class XlsxImporterService {
  dataSheet = new Subject();
  data: any[] = [];

  constructor() { }

  importFile(file: File, whiteList: string[]): Promise<any> {

    return new Promise((resolve, reject) => {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {

        try {
          const result: string = e.target.result;
          const workBook: XLSX.WorkBook = XLSX.read(result, { type: 'binary', cellDates: true});

          const sheetName: string = workBook.SheetNames[0];
          const workSheet: XLSX.WorkSheet = workBook.Sheets[sheetName];

          const data = XLSX.utils.sheet_to_json(workSheet);
          const headlines = Object.keys(data[0]);
          /*if (! this.hasValidHeadlines(headlines, whiteList)) {
            reject({message: 'The selected .xlsx file contains invalid or missing first row headers. Enabled headers are: ' + whiteList.join(', ')});
          }*/

          const dateColumns = headlines.filter((key) => {
            const firstRowValue = data[0][key];
            return firstRowValue instanceof Date;
          });

          data.forEach((row) => {
            dateColumns.forEach((col) => {
              if (row[col] instanceof Date) {
                row[col] = DateHelper.formatDate(row[col], DateHelper.DATE_TIME_FORMATS.parse.dateOutput);
              }
            });
          });

          this.data = data;

        } catch (error) {
          if (error instanceof Error) {
            reject(error.message);
          } else {
            reject(error);
          }
        }
      };

      reader.readAsArrayBuffer(file);

      reader.onloadend = (e) => {
        const headlines = Object.keys(this.data[0]);
        this.dataSheet.next(this.data);

        const columns = [];
        headlines.forEach(key => {
          const column = {columnDef: key, header: key};
          columns.push(column);
        });

        resolve({
          columns, data: this.data
        });

      };
    });
  }

  hasValidHeadlines(headlines: string[], whiteList: string[]): boolean {
    for (const headline of headlines) {
      if (!whiteList.includes(headline)) {
        return false;
      }
    }
    return true;
  }
}
