import { Component, OnInit, Input } from '@angular/core';
import {DateHelper} from '../../classes/date-helper';

@Component({
  selector: 'app-date-range-info',
  template: '<p *ngIf="isValid">{{startDate | date: dateFormat}} - {{endDate | date: dateFormat}}</p>'
})
export class DateRangeInfoComponent implements OnInit {
  @Input() startDate: string;
  @Input() endDate: string;
  @Input() dateFormat = DateHelper.DATE_FORMATS.de.date;
  isValid: boolean;
  constructor() {}

  ngOnInit(): void {
    this.isValid = DateHelper.isValidDateRange(this.startDate, this.endDate);
  }
}
