import {ICheckboxItem} from '../interfaces/icheckbox-item';

export class WeekdaysHelper {
  private static _weekdays: ICheckboxItem[] = [
    { name: 'Monday', shortName: 'mon.', key: 1},
    { name: 'Tuesday', shortName: 'tue.', key: 2},
    { name: 'Wednesday', shortName: 'wed.', key: 4},
    { name: 'Thursday', shortName: 'thu.', key: 8 },
    { name: 'Friday', shortName: 'fri.', key: 16},
    { name: 'Saturday', shortName: 'sat.', key: 32 },
    { name: 'Sunday', shortName: 'sun.', key: 64}
  ];

  static get weekdays(): ICheckboxItem[] {
    return WeekdaysHelper._weekdays;
  }

  static isWeekdaySelected(dayAsBit: number, weekAsBit: number): boolean {
    // tslint:disable-next-line:no-bitwise
    return !!(dayAsBit & weekAsBit);
  }

  static loadSelectedWeekdays<T extends { id: number | string, weekdays: number }>(collection: T[], skipIds: number[] = []): number[] {
    const days = new Set<number>();
    if (collection && collection.length) {
      collection
        .filter(obj => ! skipIds.includes(Number(obj.id)))
        .forEach(obj => {
          WeekdaysHelper._weekdays.forEach(day => {
            if (WeekdaysHelper.isWeekdaySelected(day.key, obj.weekdays) && ! days.has(day.key)) {
              days.add(day.key);
            }
          });
        });
    }
    return days.size ? Array.from(days).sort((a: number, b: number) => a - b) : [];
  }
  static loadUnselectedWeekdays(weekdays: number): number[] {
    const days = new Set<number>();
    WeekdaysHelper._weekdays.forEach(day => {
      if (! WeekdaysHelper.isWeekdaySelected(day.key, weekdays) && !days.has(day.key)) {
        days.add(day.key);
      }
    });
    return days.size ? Array.from(days).sort((a: number, b: number) => a - b) : [];
  }
  static getSumOfSelectedWeekdays(controls: boolean[]): number {
    return this._weekdays.filter((elem, i) => controls[i])
                         .reduce((bit, obj) => bit + obj.key, 0);
  }
  static getListOfSelectedWeekdays(weekdays: number): ICheckboxItem[] {
    return WeekdaysHelper._weekdays.filter(day => WeekdaysHelper.isWeekdaySelected(day.key, weekdays) === true);
  }
 }
