  <image-cropper
          class="container mb-4"
          [imageFile]="imageFile"
          [maintainAspectRatio]="maintainAspectRatio"
          [aspectRatio]="aspectRatio"
          [resizeToHeight]="resizeToHeight"
          format="jpeg"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded($event)"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
  ></image-cropper>

  <div class="container img">
    <img [src]="croppedImage" alt="" />
  </div>

  <div class="col mt-2 mb-4">
    <button mat-stroked-button
            *ngIf="croppedImage"
            debounceClick
            [debounceTime]="500"
            (onEvent)="onSave()">Save image
    </button>
  </div>



