import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from '../../../enum/dialog-type-enum';
import {IDialogData} from '../../../interfaces/idialog-data';
import {DIALOG_DIMENSIONS} from '../../../consts';

@Component({
  selector: 'app-translation-link-dialog',
  templateUrl: './translation-url-dialog.component.html',
  styleUrls: ['./translation-url-dialog.component.scss']
})
export class TranslationUrlDialogComponent implements OnInit {
  dialogType: DialogType;
  form: FormGroup;
  submitted: boolean;

  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<TranslationUrlDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {}

  ngOnInit(): void {
    if (! this.data.obj) { return; }
    this.form = this.initForm();
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const data = { translatedUrl: {...this.form.value} };
      this.dialogRef.close({dialogType: this.dialogType, data});
    }
  }

  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }

  private initForm(): FormGroup {
    const obj = this.data.obj;
    return this.fb.group({
      locale: [obj.locale, [Validators.required]],
      url: [obj.url, [Validators.required]],
      languageCode: [obj.languageCode, [Validators.required]],
      countryCode: [obj.countryCode, [Validators.required]]
    });
  }
}
