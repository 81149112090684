import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogType} from '../../../enum/dialog-type-enum';
import {IDialogData} from '../../../interfaces/idialog-data';
import {FacadeService} from '../../../../services/facade.service';
import {Subscription} from 'rxjs';
import {UrlValidator} from '../../../formValidators/url-validator';
import {MessageBoxTypeEnum} from '../../../enum/message-box-type-enum';
import {DIALOG_DIMENSIONS, INFO_TEXT} from '../../../consts';
import {TranslateUrl} from '../../../classes/translateUrl';

@Component({
  selector: 'app-translation-link-dialog',
  templateUrl: './translation-url-list-dialog.component.html',
  styleUrls: ['./translation-url-list-dialog.component.scss']
})
export class TranslationUrlListDialogComponent implements OnInit {
  subscriptions: Subscription[] = [];
  dialogType: DialogType | undefined;
  collection: TranslateUrl[] = [];
  messageBoxType = MessageBoxTypeEnum;
  INFO_TEXT = INFO_TEXT;
  form: FormGroup | undefined;
  submitted = false;
  loading = false;
  noContent = false;
  message = '';
  columns = [
    {columnDef: 'url', header: 'Url', width: '80%'},
    {columnDef: 'languageCode', header: 'Language'},
    {columnDef: 'countryCode', header: 'Country'}
  ];

  constructor(
      private fs: FacadeService,
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<TranslationUrlListDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {}

  ngOnInit(): void {
    this.form = this.initForm();
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  close(): void {
    this.dialogRef.close();
  }

  onLoadTranslatedUrls(e: Event): void {
    e.preventDefault();
    this.loading = true;
    this.collection = [];
    const formData = {
      url: this.form?.get('url')?.value,
    };

    this.fs.mediumService.loadMainShopUrls(formData).subscribe(data => {
        this.collection = data;
        this.loading = false;
        this.message = '';
        this.noContent = (this.collection.length === 0);

    }, error => {
      this.message = error?.message || 'No data loaded';
      this.loading = false;
    });
  }

  onResetForm(): void {
    this.message = '';
    this.submitted = false;
    this.form?.reset();
    this.collection = [];
  }

  onSubmit(): void {
    this.message = '';
    this.submitted = true;
    if (this.form && this.form.valid) {
      const formData = {
        url: this.form?.get('url')?.value,
        collection: this.collection
      };
      this.dialogRef.close({dialogType: this.dialogType, data: formData});
      this.onResetForm();
    }
  }

  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }

  private initForm(): FormGroup {
    const url = this.data.obj.url || '';
    return this.fb.group({
      url: [url, [Validators.required, UrlValidator.checkUrl]]
    });
  }
}
