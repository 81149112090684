import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import {UserService} from '../../services/user.service';

@Injectable()
export class MainShopGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
      const shopId = Number(next.params.id);
      if (! this.userService.hasShopPermission(shopId)) {
        this.router.navigate(['no-permissions']);
        return false;
      }
      return true;
  }
}
