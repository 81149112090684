import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {Medium} from '../../classes/medium';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {TableButtonAction} from '../../interfaces/table-button-action';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {IDialogData} from '../../interfaces/idialog-data';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FacadeService} from '../../../services/facade.service';
import {IFormData} from '../../interfaces/form-data';
import {FormType} from '../../enum/form-type-enum';
import {TranslateUrl} from '../../classes/translateUrl';
import {Mapper} from '../../classes/mapper';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {TableColumn} from '../../interfaces/table-column';
import {Shop} from '../../classes/shop';
import {LANGUAGES} from '../../consts';
import {Subscription} from 'rxjs';
import {TranslationUrlDialogComponent} from '../dialog/translation-url-dialog/translation-url-dialog.component';
import {VisualComponent} from '../../classes/visual-component';
interface IFilterSettings {
  shops: Shop[];
  selectedShop: Shop;
  languageCodes: {languageCode: string, language: string}[];
  selectedLanguageCode: string;
}
@Component({
  selector: 'app-translation-url-list',
  templateUrl: './translation-url-list.component.html',
  styleUrls: ['./translation-url-list.component.css']
})
export class TranslationUrlList implements OnInit, OnChanges {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) set matSort(sort: MatSort) { this.dataSource.sort = sort; }
  @Input() entity: Medium | VisualComponent;
  @Output() dialogEvent = new EventEmitter<IDialogData>();
  @Output() formDataEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  subscriptions: Subscription[] = [];
  columns: Array<TableColumn>;
  MessageBoxType = MessageBoxTypeEnum;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any>;
  displayActionColumn: boolean;
  displayPagination: boolean;
  rowsPerSite = [10, 20, 50, 100, 250];
  filterSettings: IFilterSettings;
  constructor(
      private dialog: MatDialog,
      protected fs: FacadeService
  ) {
    this.columns = [
      {columnDef: 'locale', header: 'Locale', width: '10%'},
      {columnDef: 'url', header: 'Url', width: '65%'},
      {columnDef: 'languageCode', header: 'Language'},
      {columnDef: 'countryCode', header: 'Country'}
    ];
    this.displayPagination = true;
    this.displayActionColumn = true;
    this.filterSettings = {
      selectedShop: null,
      selectedLanguageCode: null
    } as IFilterSettings;
    this.dataSource =  new MatTableDataSource<any>();
  }
  ngOnInit(): void {
    this.displayedColumns = this.displayedColumns.concat(this.columns.map(x => x.columnDef));
    this.dataSource.data = this.entity.mappedTranslateUrls || [];
    this.dataSource.paginator = this.paginator;

    if (this.displayActionColumn) {
      this.displayedColumns.push('action');
    }
    this.initFilterSettings();
    this.initFilterDataSource();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.initFilterDataSource();
    }
  }
  getColumnWidth(columnIndex: number): string {
    const currentColumn = this.columns[columnIndex];
    return (currentColumn.width) ? '0 0 ' + currentColumn.width : '0 0 1';
  }
  onTableAction(event: TableButtonAction): void {
    switch (event.name) {
      case 'edit':
        this.onUpdateUrl(event.value);
        break;
      case 'delete':
        this.onDeleteUrl(event.value);
        break;
    }
  }
  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(config.dialogClass, {data: config});

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.dialogType && res.data) {
        this.formDataEvent.emit({formType: FormType.UPDATE, obj: this.entity} as IFormData<Medium>);
      }
    });
  }
  onDeleteUrl(obj: TranslateUrl): void {
    const urlExists = this.entity.mappedTranslateUrls.find(u => u.url === obj.url);
    if (urlExists) {
      const config = {
        title: 'Delete Translated URL',
        dialogClass: DeleteDialogComponent,
        headline: `Do you really want to delete this URL?`,
        obj: this.entity,
        displayActionBtn: true
      } as IDialogData;

      const dialogRef = this.dialog.open(config.dialogClass, {data: config});
      dialogRef.afterClosed().subscribe(res => {
        if (res && res.data) {
          this.entity.mappedTranslateUrls = this.entity.mappedTranslateUrls.filter(u => u.url !== obj.url);
          Mapper.stringifyTranslateUrls(this.entity);
          this.formDataEvent.emit({formType: FormType.UPDATE, obj: this.entity} as IFormData<Medium>);
        }
      });
    }
  }
  onUpdateUrl(translateUrl: TranslateUrl): void {
    const urlExists = this.entity.mappedTranslateUrls.find(u => u.url === translateUrl.url);
    if (urlExists) {
      const config = {
        title: 'Edit Translated URL',
        dialogClass: TranslationUrlDialogComponent,
        headline: null,
        obj: translateUrl,
        displayActionBtn: true,
        dialogDimensions: {width: '1200px', height: 'auto'}
      } as IDialogData;

      const dialogRef = this.dialog.open(config.dialogClass, {data: config});
      dialogRef.afterClosed().subscribe(response => {
        if (response && response.data.translatedUrl) {
          translateUrl = response.data.translatedUrl;
          const index =  this.entity.mappedTranslateUrls.findIndex(u => u.locale === translateUrl.locale);
          if (index >= 0) {
            this.entity.mappedTranslateUrls[index] = translateUrl;
          }
          Mapper.stringifyTranslateUrls(this.entity);
          this.formDataEvent.emit({formType: FormType.UPDATE, obj: this.entity} as IFormData<Medium | VisualComponent>);
        }
      });
    }
  }
  onSubmitFilter(formData: IFormData<any>): void {
    Object.keys(this.filterSettings).forEach(key => {
      if (formData.hasOwnProperty(key)) {
        this.filterSettings[key] = formData[key];
      }
    });
    this.initFilterDataSource();
  }
  private initPagination(): void {
    if (this.rowsPerSite && this.rowsPerSite.length > 0) {
      this.displayPagination = true;
      if ((this.dataSource.data && this.dataSource.data.length) < this.rowsPerSite[0]) {
        this.displayPagination = false;
      }
    }
  }
  private initFilterSettings(): void {
    const shops = this.fs.mainShopService.getAllSubjects();
    if (shops) {
      this.filterSettings.shops = shops;
      this.filterSettings.selectedShop = this.filterSettings.selectedShop || null;
      this.filterSettings.languageCodes = LANGUAGES;
    }
  }
  private initFilterDataSource(): void {
    let collection = this.entity.mappedTranslateUrls || [];
    if (this.filterSettings.selectedShop) {
      collection = collection.filter(obj => obj.countryCode === this.filterSettings.selectedShop.countryCode);
    }
    if (this.filterSettings.selectedLanguageCode) {
      collection = collection.filter(obj => obj.languageCode === this.filterSettings.selectedLanguageCode);
    }
    this.dataSource.data = collection;
    this.initPagination();
  }
}
