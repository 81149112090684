import { Injectable } from '@angular/core';
import {ShopCategoryService} from './shop-category.service';
import {MainShopService} from './main-shop.service';
import {MediumUnitService} from './mediumUnit.service';
import {UserRoleService} from './user-role.service';
import {InfoboxService} from './infobox.service';
import {InfoboxCategoryService} from './infobox-category.service';
import {InfoboxTypeService} from './infobox-type.service';
import {VisualComponentService} from './visual-component.service';
import {MediumCategoryService} from './medium-category.service';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

  constructor(
      private userRoleService: UserRoleService,
      private shopCategoryService: ShopCategoryService,
      private mainShopService: MainShopService,
      private mediumUnitService: MediumUnitService,
      private infoboxTypeService: InfoboxTypeService,
      private infoboxCategoryService: InfoboxCategoryService,
      private infoboxService: InfoboxService,
      private visualComponentService: VisualComponentService,
      private mediumCategoryService: MediumCategoryService,
  ) { }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
        return Promise.all([
            this.userRoleService.init(),
            this.shopCategoryService.init(),
            this.mainShopService.init(),
            this.mediumUnitService.init(),
            this.infoboxTypeService.init(),
            this.infoboxCategoryService.init(),
            this.infoboxService.init(),
            this.visualComponentService.init(),
            this.mediumCategoryService.init()
        ]).then(() => {
              resolve();
        }).catch((err) => {
           reject(err);
        });
    });
  }
}
