import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchDateService {
  STORAGE_NAME = 'medium-search-date';
  protected date: Date;

  constructor() {
    if (! localStorage.getItem(this.STORAGE_NAME)) {
      this.date = new Date();
      this.save();
    }
    else {
      this.date = JSON.parse(localStorage.getItem(this.STORAGE_NAME));
    }
  }

  public save(): void {
    localStorage.setItem(this.STORAGE_NAME, JSON.stringify(this.date));
  }

  public getDate(): Date {
      return this.date;
  }

  public setDate(date: Date): void {
    this.date = date;
    this.save();
  }

  public clear(): void {
    localStorage.clear();
  }
}
