import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {VisualComponentListComponent} from './components/visual-component-list/visual-component-list.component';
import {TeaserSpecialsGlobalRoutingModule} from './teaser-specials-global-routing.module';

@NgModule({
  declarations: [
      VisualComponentListComponent
  ],
  imports: [
    CommonModule,
    TeaserSpecialsGlobalRoutingModule,
    SharedModule,
  ]
})
export class TeaserSpecialsGlobalModule { }

