export class Message {
    type: string;
    content: string;
    closable: boolean;

    constructor(type: string, content: string, closable: boolean) {
        this.type = type;
        this.content = content;
        this.closable = closable;
    }
}
