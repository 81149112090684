import {UserRole} from './user-role';
import {Shop} from './shop';

export class User {
    constructor(
        public id?: number,
        public firstname?: string,
        public lastname?: string,
        public email?: string,
        public admin?: boolean,
        public roles?: UserRole[],
        public shop?: Shop,
        public passwordDefault?: string,
        public active?: boolean,
        public insertTs?: string,
        public updateTs?: string
    ) {}
}
