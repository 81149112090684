import {Component, Input, OnInit} from '@angular/core';
import {Shop} from '../../classes/shop';
import {FormBuilder, FormGroup} from '@angular/forms';
import {FacadeService} from '../../../services/facade.service';
import {DialogType} from '../../enum/dialog-type-enum';
import {IDialogData} from '../../interfaces/idialog-data';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {InfoboxInfoListDialogComponent} from '../dialog/infobox-info-list-dialog/infobox-info-list-dialog.component';
import {Infobox} from '../../classes/infobox';
import {InfoboxType} from '../../classes/infobox-type';

@Component({
  selector: 'app-dashboard-infobox',
  templateUrl: './dashboard-infobox.component.html',
  styleUrls: ['./dashboard-infobox.component.scss']
})
export class DashboardInfoboxComponent implements OnInit {
  @Input() display: boolean;
  @Input() shops: Shop[] = [];
  @Input() infoboxTypes: InfoboxType[] = [];
  form: FormGroup;
  config: any;
  route: string;
  selectedShop: Shop;
  selectedInfoboxType: InfoboxType;
  collection: Infobox[] = [];
  data = {
    all: [],
    active: [],
    inactive: [],
    global: [],
    individual: [],
    selectedShop: {
      all: [],
      active: [],
      inactive: []
    }
  };

  constructor(
      private fb: FormBuilder,
      private fs: FacadeService,
      private dialog: MatDialog
  ) {
    this.config = {title: 'Infobox', icon: 'message', buttonLabel: 'Configure Infobox', section: 'section-infobox'};
  }

  ngOnInit(): void {
    this.selectedShop = this.shops.find(s => s.country === 'Germany');
    this.selectedInfoboxType = this.infoboxTypes[0];
    this.initRoute();

    this.collection = this.fs.infoboxService.getAllSubjects();
    this.filterByInfoboxTypeId(this.selectedInfoboxType.id);
    this.filterByShopId(this.selectedShop.id);
    this.form = this.initForm();
  }

  initForm(): FormGroup {
    const fb = this.fb.group({
      selectedShop: [this.selectedShop],
      selectedInfoboxType: [this.selectedInfoboxType],
    });
    fb.get('selectedShop').valueChanges
        .subscribe(shop => {
          this.selectedShop = shop;
          this.filterByShopId(shop.id);
        });

    fb.get('selectedInfoboxType').valueChanges
        .subscribe(infoboxType => {
          this.selectedInfoboxType = infoboxType;
          this.filterByInfoboxTypeId(infoboxType.id);
          this.filterByShopId(this.selectedShop.id);
          this.initRoute();
        });
    return fb;
  }

  filterByShopId(id: number): void {
    this.data.selectedShop.all = this.data.all.filter(t => t.shopId === id);
    this.data.selectedShop.active = this.data.active.filter(t => t.shopId === id);
    this.data.selectedShop.inactive = this.data.inactive.filter(t => t.shopId === id);
  }

  filterByInfoboxTypeId(id: number): void {
    const data = this.collection.filter(obj => obj.infoboxTypeId === id);
    this.data.all = data;
    this.data.active = data.filter(obj => obj.active === true);
    this.data.inactive = data.filter(obj => obj.active === false);
    this.data.global = data.filter(obj => obj.shopId === null);
    this.data.individual = data.filter(obj => obj.shopId !== null);
  }

  initRoute(): void {
    this.route = `${this.config.section}-${this.selectedInfoboxType.name}`;
  }

  onOpenInfoDialog(collection: Infobox[]): void {
    if (! collection.length) { return; }
    const sTitle = 'Info ' + this.config.title;
    const dialogConf = {
      dialogType: DialogType.INFO_DIALOG,
      title: sTitle,
      list: collection,
      dialogClass: InfoboxInfoListDialogComponent,
      dialogDimensions: {width: '1400px', height: 'auto'}
    } as IDialogData;
    this.openDialog(dialogConf);
  }

  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(config.dialogClass, {data: config});
  }
}
