import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  Inject, OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DIALOG_DIMENSIONS} from '../../../consts';

@Component({
  selector: 'app-dynamic-dialog',
  templateUrl: './dynamic-dialog.component.html',
  styleUrls: ['./dynamic-dialog.component.css']
})
export class DynamicDialogComponent implements OnInit, OnDestroy {
  @ViewChild('target', { read: ViewContainerRef, static: true }) vcRef: ViewContainerRef;
  componentRef: ComponentRef<any>;
  config: any;

  constructor(
      private resolver: ComponentFactoryResolver,
      private dialogRef: MatDialogRef<DynamicDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any) {
    this.config = data.config;
  }

  ngOnInit(): void {
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);

    const factory = this.resolver.resolveComponentFactory(this.data.component);
    this.componentRef = this.vcRef.createComponent(factory);
  }

  ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
