import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Medium} from '../../classes/medium';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {IFormData} from '../../interfaces/form-data';
import {FormType} from '../../enum/form-type-enum';
import {Mapper} from '../../classes/mapper';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {INFO_TEXT, HTML_URL_TARGET} from '../../consts';
import {IDialogData} from '../../interfaces/idialog-data';
import {TranslationUrlListDialogComponent} from '../dialog/translation-url-list-dialog/translation-url-list-dialog.component';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {FacadeService} from '../../../services/facade.service';
import {TranslateUrl} from '../../classes/translateUrl';
import {UrlValidator} from '../../formValidators/url-validator';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {VisualComponent} from '../../classes/visual-component';
@Component({
  selector: 'app-load-translation-urls',
  templateUrl: './load-translation-urls.component.html',
  styleUrls: ['./load-translation-urls.component.scss']
})
export class LoadTranslationUrlsComponent implements OnInit, OnChanges {
  @Input() entity: Medium | VisualComponent | undefined;
  @Output() formDataEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  @Output() dialogEvent = new EventEmitter<IDialogData>();
  translateUrls: TranslateUrl[] = [];
  form: FormGroup | undefined;
  submitted = false;
  mediumCategory: string | undefined;
  messageBoxType = MessageBoxTypeEnum;
  message: string | undefined;
  INFO_TEXT = INFO_TEXT;
  urlTargets: any = [];
  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    protected fs: FacadeService
  ) {
    this.urlTargets = HTML_URL_TARGET;
  }
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      if (this.entity?.mappedTranslateUrls && this.entity?.translateUrls && this.entity?.translateUrls.length > 0) {
        this.translateUrls = this.entity.mappedTranslateUrls;
      }
      else {
        this.translateUrls = [];
      }
      this.form = this.initForm();
    }
  }
  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(config.dialogClass, {
      data: config
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res?.data) {
        this.translateUrls = res.data.collection;
        const url = res.data.url;

        this.clearFormArrayGroup();
        this.translateUrls.forEach(element => {
          const fb = this.buildFormGroup(element);
          this.registerFormArray.push(fb);
        });

        // @ts-ignore
        this.registerFormControl.url.setValue(url);
        if (this.entity) {
          this.entity.url = url;
        }
      }
    });
  }

  onLoadTranslatedUrls(e: Event): void {
    e.preventDefault();
    const config = {
      title: 'Load Translated URLs',
      obj: {
        shopId: this.entity instanceof Medium ?
            this.entity.mediumUnit?.shopId : this.entity instanceof VisualComponent ? this.entity?.shopId : null,
        url: this.entity?.url
      },
      dialogClass: TranslationUrlListDialogComponent,
      dialogDimensions: {width: '1200px', height: 'auto'}
    } as IDialogData;
    this.openDialog(config);
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form && this.form.valid) {
      const entity: Medium | VisualComponent = {...this.entity};
      Mapper.stringifyTranslateUrls(entity);
      Mapper.mapFormGroup(entity, this.form);
      this.formDataEvent.emit({formType: FormType.UPDATE, obj: entity} as IFormData<Medium | VisualComponent>);
    }
  }
  onDeleteAllUrls(e: Event): void {
    e.preventDefault();
    const config = {
      title: 'Delete Translated URLs',
      dialogClass: DeleteDialogComponent,
      headline: `Do you really want to delete all URLs?`,
      obj: this.entity,
      displayActionBtn: true
    } as IDialogData;

    const dialogRef = this.dialog.open(config.dialogClass, {data: config});
    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        this.translateUrls = [];
        this.registerFormArray.clear();
        this.form?.get('url')?.setValue(null);
        this.onSubmit();
      }
    });
  }
  onDeleteOneUrl(e: Event, index: number): void {
    e.preventDefault();
    const config = {
      title: 'Delete Translated URL',
      dialogClass: DeleteDialogComponent,
      headline: `Do you really want to delete this URL?`,
      obj: this.entity,
      displayActionBtn: true
    } as IDialogData;

    const dialogRef = this.dialog.open(config.dialogClass, {data: config});
    dialogRef.afterClosed().subscribe(res => {
      if (res.data) {
        this.registerFormArray.removeAt(index);
        this.onSubmit();
      }
    });
  }

  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }

  get registerFormArray(): FormArray {
    return this.form.get('mappedTranslateUrls') as FormArray;
  }

  private clearFormArrayGroup(): void {
    this.registerFormArray.clear();
  }

  private buildFormGroup(element: TranslateUrl): FormGroup {
    return this.fb.group({
      locale: [element.locale, Validators.required],
      url: [element.url, [Validators.required, UrlValidator.checkUrl]],
      countryCode: [element.countryCode],
      languageCode: [element.languageCode]
    });
  }

  private buildFormArray(values: TranslateUrl[]): FormArray {
    if (! values || values.length === 0) {
      return this.fb.array([]);
    }
    return this.fb.array(
        values.map(t => this.buildFormGroup(t))
    );
  }

  private initForm(): FormGroup {
    return this.fb.group({
      id: [this.entity?.id],
      url: [this.entity?.url],
      mappedTranslateUrls: this.buildFormArray(this.translateUrls)
    });
  }

  protected readonly FormGroup = FormGroup;
}
