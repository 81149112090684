export class Translation {
  constructor(
    public languageCode: string | null = null,
    public language: string | null = null,
    public countryCode: string | null = null,
    public headline: string | null = null,
    public subheadline: string | null = null,
    public headlineFormatted: string | null = null,
    public subheadlineFormatted: string | null = null,
    public infoboxFormatted: string | null = null,
    public btnLabel: string | null = null,
    public imageAlt: string | null = null
  ) { }
}
