import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {IFormData} from '../../../interfaces/form-data';
import {AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ICheckboxItem} from '../../../interfaces/icheckbox-item';
import {FacadeService} from '../../../../services/facade.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {WeekdaysHelper} from '../../../classes/weekdays-helper';
import {INFO_TEXT} from '../../../consts';
import {Mapper} from '../../../classes/mapper';
import {FormType} from '../../../enum/form-type-enum';
import {IDialogData} from '../../../interfaces/idialog-data';
import {DateHelper} from '../../../classes/date-helper';
import {NGX_MAT_DATE_FORMATS, NgxMatDateAdapter} from '@angular-material-components/datetime-picker';
import {DateTimeAdapter} from '../../../classes/date-time-adapter';
import {DateRangeValidator} from '../../../formValidators/date-range-validator';
import {VisualComponent} from '../../../classes/visual-component';

@Component({
  selector: 'app-visual-component-configure',
  templateUrl: './visual-component-configure.component.html',
  styleUrls: ['./visual-component-configure.component.scss'],
  providers: [
    { provide: NGX_MAT_DATE_FORMATS, useValue: DateHelper.DATE_TIME_FORMATS},
    { provide: NgxMatDateAdapter, useClass: DateTimeAdapter},
  ]
})
export class VisualComponentConfigureComponent implements OnInit, OnChanges {
  @Input() visualComponent: VisualComponent;
  @Output() formDataEvent = new EventEmitter<IFormData<VisualComponent>>();

  form: FormGroup;
  submitted = false;
  weekDaysData: ICheckboxItem[] = [];
  displayDateRange: boolean;
  infoText: string;
  showSeconds: boolean;
  stepMinutes = 5;
  stepSeconds = 10;
  constructor(
      private fb: FormBuilder,
      private fs: FacadeService,
      private dialog: MatDialog
  ) {
    this.weekDaysData = WeekdaysHelper.weekdays;
    this.infoText = INFO_TEXT.APPROVE_FOR_REPLACEMENT + INFO_TEXT.CRITERIA_SLIDER_MERGED_LIST;
  }
  ngOnInit(): void {
    this.displayDateRange = true;
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visualComponent) {
      this.form = this.initFormGroup();
    }
  }
  onSubmitHandler(e: any): void {
    this.onSubmit();
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const visualComponent: VisualComponent = {...this.visualComponent};
      Mapper.mapFormGroup(visualComponent, this.form);
      this.formDataEvent.emit({formType: FormType.UPDATE, obj: visualComponent} as IFormData<VisualComponent>);
    }
  }
  openDialog(config: IDialogData): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(config.dialogClass, {data: config});
  }
  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }
  rangeFilter(): boolean {
    return true;
  }
  get weekdays(): FormArray {
    return this.form.get('weekdays') as FormArray;
  }
  private createWeekdaysFormArray(items: ICheckboxItem[]): FormArray {
    const arr = items.map(obj => {
      return new FormControl({
        value: WeekdaysHelper.isWeekdaySelected(obj.key, this.visualComponent.weekdays),
        disabled: false
      });
    });
    return new FormArray(arr);
  }
  private initFormGroup(): FormGroup {
    return this.fb.group({
      id: [this.visualComponent.id],
      dateRange: this.fb.group({
            startDate: [new Date(this.visualComponent.startDate), Validators.required],
            endDate: [new Date(this.visualComponent.endDate), Validators.required]
          }, {validators: [DateRangeValidator.checkDateRange()]}
      ),
      weekdays: this.createWeekdaysFormArray(this.weekDaysData),
      active: [this.visualComponent.active]
    });
  }
}
