import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import {Medium} from '../../classes/medium';
import {DialogType} from '../../enum/dialog-type-enum';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DeleteDialogComponent} from '../dialog/delete-dialog/delete-dialog.component';
import {IDialogData} from '../../interfaces/idialog-data';
import {IFormData} from '../../interfaces/form-data';
import {FormType} from '../../enum/form-type-enum';
import {environment} from '../../../../environments/environment';
import {ImageType} from '../../enum/image-type-enum';
import {MediumCategoryEnum} from '../../enum/medium-category-enum';
import {CSS_LAYOUT_CONFIG} from '../../consts';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {VisualComponent} from '../../classes/visual-component';

@Component({
  selector: 'app-image-loader',
  templateUrl: './image-loader.component.html',
  styleUrls: ['./image-loader.component.scss']
})
export class ImageLoaderComponent implements OnInit, OnChanges {
  @Input() entity: Medium | VisualComponent;
  @Input() imageType: ImageType;
  @Input() cropAllowed?: boolean;
  @Output() imageEvent = new EventEmitter<IFormData<Medium | VisualComponent>>();
  MessageBoxType = MessageBoxTypeEnum;
  imagesUrl: string;
  imageName: string;
  infoImageUploadDimensions: string;
  imageUploadType: string;
  defaultImageUploadType = 'upload';
  config = CSS_LAYOUT_CONFIG.responsiveImageDimensions;
  category: string;

  constructor(
    private dialog: MatDialog
  ) {
    this.imagesUrl = environment.imagesUrl;
  }

  ngOnInit(): void {
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.init();
    }
  }

  init(): void {
    this.imageUploadType = this.defaultImageUploadType;
    this.initImageName();
    this.initInfoImageDimensions();
    this.category = MediumCategoryEnum[this.entity.mediumCategoryId];
  }

  initImageName(): void {
    this.imageName = this.imageType === ImageType.DESKTOP_LANDSCAPE ? this.entity?.image : this.entity?.imageMobile;
  }

  onImageUpload(data: IFormData<Medium>): void {
    this.imageEvent.emit(data);
  }

  deleteImage(): void {
    this.openDialog(this.imageType);
  }

  openDialog(imageType: ImageType): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const config = {
      title: 'Delete Image',
      dialogClass: DeleteDialogComponent,
      dialogType: DialogType.DELETE_DIALOG,
      obj: this.entity,
      headline: `Do you really want to delete this image?`,
      displayActionBtn: true
    } as IDialogData;
    const dialogRef = this.dialog.open(config.dialogClass, {
      data: config
    });

    dialogRef.afterClosed().subscribe(res => {
      if (res && res.data) {
        const data = {formType: FormType.DELETE, obj: res.data, imageType} as IFormData<Medium | VisualComponent>;
        this.imageEvent.emit(data);
      }
    });
  }

  getImagePath(): string {
    return `${this.imagesUrl}/${this.imageName}`;
  }

  private initInfoImageDimensions(): void {
    switch (this.category) {
      case 'Slider':
          switch (this.imageType) {
            case ImageType.DESKTOP_LANDSCAPE:
              this.infoImageUploadDimensions =
                  `Upload dimensions: ${this.config.slider.desktop.landscape.width} x ${this.config.slider.desktop.landscape.height} | Max. upload size ${this.config.slider.imageUploadMaxSize} kb`;
              break;
            case ImageType.MOBILE_LANDSCAPE:
              this.infoImageUploadDimensions =
                  `Upload dimensions: ${this.config.slider.mobile.landscape.width} x ${this.config.slider.mobile.landscape.height} | Max. upload size ${this.config.slider.imageUploadMaxSize} kb`;
              break;
          }
          this.infoImageUploadDimensions += ' | Allowed image types: ' + this.config.imageUploadTypes.join(', ');
          break;
      case 'Teaser':
          this.infoImageUploadDimensions =
              `Upload dimensions: Small & Medium ${this.config.teaser.desktop.landscape.width} x ${this.config.teaser.desktop.landscape.height}`;

          this.infoImageUploadDimensions += ' | Large ' +
              `${this.config.teaser.desktop.landscapeLargeNew.width} x ${this.config.teaser.desktop.landscapeLargeNew.height}` + ' | ' +
              `${this.config.teaser.desktop.landscapeLarge.width} x ${this.config.teaser.desktop.landscapeLarge.height}`;

          this.infoImageUploadDimensions += ' | Large Narrow High ' +
              `${this.config.teaser.desktop.landscapeLargeSmallHeight.width} x ${this.config.teaser.desktop.landscapeLargeSmallHeight.height}`;

          this.infoImageUploadDimensions += ` | Max. upload size ${this.config.teaser.imageUploadMaxSize} kb`;

          this.infoImageUploadDimensions += ' | Allowed image types: ' + this.config.imageUploadTypes.join(', ');
          break;
      case 'Teaser_Specials_Sale':
        this.infoImageUploadDimensions =
            `Upload dimensions:  ${this.config.teaser_specials_sale.desktop.landscape.width} x ${this.config.teaser_specials_sale.desktop.landscape.height}`;

        this.infoImageUploadDimensions += ` | Max. upload size ${this.config.imageUploadMaxSize} kb`;
        this.infoImageUploadDimensions += ' | Allowed image types: ' + this.config.imageUploadTypes.join(', ');
        break;
    }
  }
}

