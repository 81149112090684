import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {IMAGE_CONTAINER_MAX_WIDTH} from '../../consts';

@Component({
    selector: 'app-image-container',
    templateUrl: './image-container.component.html',
    styleUrls: ['./image-container.component.css']
})
export class ImageContainerComponent implements OnChanges {
    @Input() src: string;
    @Input() alt?: string;
    @Input() maxWidth?: number;

    styles: {
        width: string,
        maxWidth: string
    };

    constructor() {
        this.maxWidth = IMAGE_CONTAINER_MAX_WIDTH;
        this.styles = {
            width: '100%',
            maxWidth: this.maxWidth + 'px'
        };
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.maxWidth) {
            this.styles.maxWidth = this.maxWidth + 'px';
        }
    }
}
