<p class="__bold mt-2 mb-4">Profile Info</p>

<table class="table" *ngIf="user">
    <tr>
        <th width="150">Id:</th>
        <td>{{user.id}}</td>
    </tr>
    <tr>
        <th>Firstname:</th>
        <td>{{user.firstname}}</td>
    </tr>
    <tr>
        <th>Lastname:</th>
        <td>{{user.lastname}}</td>
    </tr>
    <tr>
        <th>E-Mail:</th>
        <td>{{user.email}}</td>
    </tr>
    <tr>
        <th>Shop:</th>
        <td>{{user.shop? user.shop.name : '-'}}</td>
    </tr>
    <tr>
        <th>Admin:</th>
        <td>{{user.admin ? 'yes' : 'no'}}</td>
    </tr>
    <tr *ngIf="! user.admin">
        <th>Role:</th>
        <td>
            <ul *ngIf="user.roles; else noRoles">
                <li *ngFor="let role of user.roles">{{role.name}}</li>
            </ul>
            <ng-template #noRoles>
                <p>--</p>
            </ng-template>
        </td>
    </tr>
</table>


