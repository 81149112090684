import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDialogData} from '../../../interfaces/idialog-data';
import {DIALOG_DIMENSIONS} from '../../../consts';
import {DialogType} from '../../../enum/dialog-type-enum';

@Component({
  selector: 'app-user-info-list-dialog',
  templateUrl: './user-info-list-dialog.component.html',
  styleUrls: ['./user-info-list-dialog.component.css']
})
export class UserInfoListDialogComponent implements OnInit {
  columns = [
    {columnDef: 'id', header: 'ID', width: '6%'},
    {columnDef: 'shop', header: 'Shop-ID', width: '6%', format: 'shop'},
    {columnDef: 'firstname', header: 'Firstname'},
    {columnDef: 'lastname', header: 'Lastname'},
    {columnDef: 'email', header: 'E-Mail'},
    {columnDef: 'admin', header: 'Admin', width: '10%'},
    {columnDef: 'active', header: 'Active', width: '10%'}
  ];

  constructor(
      private dialogRef: MatDialogRef<UserInfoListDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {}

  ngOnInit(): void {
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close({dialogType: DialogType.INFO_DIALOG});
  }
}

