import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IDialogData} from '../../../interfaces/idialog-data';
import {DIALOG_DIMENSIONS} from '../../../consts';
import {DialogType} from '../../../enum/dialog-type-enum';

@Component({
  selector: 'app-medium-unit-info-list-dialog',
  templateUrl: './medium-unit-info-list-dialog.component.html',
  styleUrls: ['./medium-unit-info-list-dialog.component.css']
})
export class MediumUnitInfoListDialogComponent implements OnInit {
  columns = [
    {columnDef: 'id', header: 'ID', width: '6%'},
    {columnDef: 'shopId', header: 'Shop-ID', width: '6%'},
    {columnDef: 'name', header: 'Name', width: '40%'},
    {columnDef: 'startDate', header: 'Start date', format: 'date'},
    {columnDef: 'endDate', header: 'End date', format: 'date'},
    {columnDef: 'weekdays', header: 'Weekdays', format: 'weekdays'},
    {columnDef: 'active', header: 'Active', width: '10%'}
  ];

  constructor(
      private dialogRef: MatDialogRef<MediumUnitInfoListDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {}

  ngOnInit(): void {
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  close(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close({dialogType: DialogType.INFO_DIALOG});
  }
}
