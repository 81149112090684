import {Directive, ElementRef, AfterViewInit, HostListener, Renderer2} from '@angular/core';
import Scrollbar from 'smooth-scrollbar';
import {ScrollbarOptions} from 'smooth-scrollbar/interfaces';

@Directive({
  selector: '[smoothScrollbar]'
})
export class SmoothScrollbarDirective implements AfterViewInit {
  protected options: Partial<ScrollbarOptions>;
  constructor(
      private elementRef: ElementRef,
      private renderer: Renderer2
  ) {
    this.options = {
      speed: 1000,
      easing: 'easeOutCubic',
      damping: 0.1,
      alwaysShowTracks: false
    } as Partial<ScrollbarOptions>;
  }

  ngAfterViewInit(): void {
    const element = this.elementRef.nativeElement;
    if (element) {
      this.hideScrollbars();
      Scrollbar.init(element, this.options);
    }
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.showScrollbars();
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.hideScrollbars();
  }

  private hideScrollbars(): void {
    const element = this.elementRef.nativeElement;
    const scrollbar = element.querySelector('.scrollbar-track-y');
    if (scrollbar) {
      this.renderer.setStyle(scrollbar, 'opacity', '0');
    }
  }

  private showScrollbars(): void {
    const element = this.elementRef.nativeElement;
    const scrollbar = element.querySelector('.scrollbar-track-y');
    if (scrollbar) {
      this.renderer.setStyle(scrollbar, 'opacity', '1');
    }
  }
}
