import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {UserService} from '../../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.userService.user$.pipe(
      map((user) => {
        if (user) {
          if (user.admin) {
            return true;
          }

          if (next.data.role) {
            const hasUserRole = user.roles.some(r => next.data.role.includes(r.name));
            if (! hasUserRole) {
              this.router.navigate(['no-permissions']);
              return false;
            }
          }
          return true;
        } else {
          this.router.navigate(['login']);
          return false;
        }
      })
    );
  }
}
