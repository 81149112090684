// @ts-ignore
import * as _moment from 'moment';
import {
  ALL_WEEKDAYS_AS_BIT,
  CSS_WYSIWYG_EDITOR_CONFIG,
  HTML_URL_TARGET,
  LANGUAGES,
  LANGUAGES_EXPANDED
} from '../consts';
import {TranslationMapper} from './translation-mapper';
import {CssStyleMapper} from './css-style-mapper';
import {Medium} from './medium';
import {FormGroup} from '@angular/forms';
import {WeekdaysHelper} from './weekdays-helper';
import {MediumUnit} from './medium-unit';
import {DateHelper} from './date-helper';
import {User} from './user';
import {Infobox} from './infobox';
import {ElementTypeEnum} from '../enum/element-type-enum';
import {VisualComponent} from './visual-component';
const moment = _moment;

export class Mapper {

  public static parseTranslations(obj: Medium | Infobox | VisualComponent): void {
    if (obj.mappedTranslations && obj.mappedTranslations.length > 0) { return; }
    const translations = JSON.parse(obj.translations);
    obj.mappedTranslations = translations || TranslationMapper.fromArray(LANGUAGES);
  }

  public static parseTranslateUrls(obj: Medium | VisualComponent): void {
    if (obj.mappedTranslateUrls && obj.mappedTranslateUrls.length > 0) { return; }
    obj.mappedTranslateUrls = JSON.parse(obj.translateUrls);
  }

  public static parseCssStyles(obj: Medium | VisualComponent): void {
    if (obj.mappedCssStyles && obj.mappedCssStyles.length > 0) { return; }
    const styles = JSON.parse(obj.cssStyles);
    obj.mappedCssStyles = styles || CSS_WYSIWYG_EDITOR_CONFIG.stylesForSave;
  }

  public static stringifyTranslations(obj: Medium | Infobox | VisualComponent): void {
    if (obj.mappedTranslations && obj.mappedTranslations.length > 0) {
      obj.translations = JSON.stringify(obj.mappedTranslations);
    }
    else {
      obj.mappedTranslations = TranslationMapper.fromArray(LANGUAGES);
    }
  }

  public static stringifyTranslateUrls(obj: Medium | VisualComponent): void {
    if (obj.mappedTranslateUrls && obj.mappedTranslateUrls.length > 0) {
      obj.translateUrls = JSON.stringify(obj.mappedTranslateUrls);
    }
    else {
      obj.translateUrls = null;
    }
  }

  public static stringifyCssStyles(obj: Medium | VisualComponent): void {
    if (obj.mappedCssStyles && obj.mappedCssStyles.length > 0) {
      obj.cssStyles = JSON.stringify(obj.mappedCssStyles);
    }
    else {
      obj.cssStyles = null;
    }
  }

  public static mapFormGroup(m: any, formGroup: FormGroup): void {
    if (formGroup && formGroup.value) {
      Object.keys(formGroup.controls).forEach(name => {
        const value = formGroup.controls[name].value;
        switch (name) {
          case 'weekdays':
              m.weekdays = WeekdaysHelper.getSumOfSelectedWeekdays(value);
              break;
          case 'dateRange':
            if (formGroup.value.dateRange.startDate && formGroup.value.dateRange.endDate) {
              const dateFormat = DateHelper.DATE_TIME_FORMATS.parse.dateOutput;
              m.startDate = moment(formGroup.value.dateRange.startDate).format(dateFormat);
              m.endDate = moment(formGroup.value.dateRange.endDate).format(dateFormat);
            }
            break;
          case 'mappedTranslations':
            if (!(m instanceof MediumUnit)) {
              m.mappedTranslations = value;
              m.translations = JSON.stringify(value);
            }
            break;
          case 'mappedTranslateUrls':
            if (!(m instanceof MediumUnit)) {
              m.translateUrls = null;
              if (value && value.length) {
                value.forEach(v => {
                  if (! v.languageCode || ! v.countryCode) {
                    const codes = v.locale.split('_');
                    if (codes.length === 2) {
                      v.languageCode = codes[0];
                      v.countryCode = codes[1];
                    }
                  }
                });
                m.translateUrls = JSON.stringify(value);
              }
            }
            break;
          case 'mappedCssStyles':
            if (!(m instanceof MediumUnit)) {
              m.mappedCssStyles = value;
              m.cssStyles = JSON.stringify(value);
            }
            break;
          default:
            if (m.hasOwnProperty(name)) {
              m[name] = value;
            }
            break;
        }
      });
    }
  }

  public static createMediumUnit(): MediumUnit {
      return new MediumUnit(null, null, null, null, null, null,
          null, ALL_WEEKDAYS_AS_BIT, false, false, false, 0, null, null,
          null, null, null);
  }

  public static createMedium(): Medium {
      const obj = new Medium(null, null, null, null, null, null,
          0, null, null, ALL_WEEKDAYS_AS_BIT, false, null, null, null,
          null, null, null, null, null, null, true, false, false,  null, null, null,
          null, null
      );

      obj.mappedTranslations = TranslationMapper.fromArray(LANGUAGES_EXPANDED);
      obj.mappedCssStyles = CssStyleMapper.fromArray(CSS_WYSIWYG_EDITOR_CONFIG.stylesForSave);
      return obj;
  }

  public static createVisualComponent(): VisualComponent {
    const obj = new VisualComponent(null, null, null, null, null, null, 0,
        null, null,  ALL_WEEKDAYS_AS_BIT, null, null, null, null,
        null, null, null, null, null, false, true, null, null, null
    );

    obj.mappedTranslations = TranslationMapper.fromArray(LANGUAGES_EXPANDED);
    obj.mappedCssStyles = CssStyleMapper.fromArray(CSS_WYSIWYG_EDITOR_CONFIG.stylesForSave);
    return obj;
  }

  public static createInfobox(): Infobox {
    const obj =  new Infobox(null, null, null, null, null, null, null, null, null, null,
        null,  null, null, null, ALL_WEEKDAYS_AS_BIT, 0, false,
        null, null, null, null);
    obj.mappedTranslations = TranslationMapper.fromArray(LANGUAGES_EXPANDED);
    return obj;
  }

  public static createUser(): User {
    return new User(null, null, null, null, false, null, null, null, false, null, null);
  }

  public static passDataFromParent(mediumUnit: MediumUnit, medium: Medium): Medium {
    medium.mediumUnitId = mediumUnit.id;
    medium.mediumCategoryId = mediumUnit.mediumCategoryId;
    medium.elementTypeId = mediumUnit.shopId ? ElementTypeEnum.Individual : ElementTypeEnum.Global;
    medium.startDate = mediumUnit.startDate;
    medium.endDate = mediumUnit.endDate;
    medium.weekdays = mediumUnit.weekdays;
    medium.orderIndex = Mapper.initOrderIndex(mediumUnit.mediumCollection);
    return medium;
  }

  public static initOrderIndex(collection: Medium[]): number {
    let orderIndex = 0;
    if (collection.length > 0) {
      orderIndex = (Math.max(...collection.map(item => item.orderIndex), 0)) + 1;
    }
    return orderIndex;
  }
}




