import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Translation} from '../../../classes/translation';
import {IFormData} from '../../../interfaces/form-data';
import {FormType} from '../../../enum/form-type-enum';
import {Mapper} from '../../../classes/mapper';
import {MediumHelper} from '../../../helper/medium-helper';
import {MessageBoxTypeEnum} from '../../../enum/message-box-type-enum';
import {INFO_TEXT} from '../../../consts';
import {FacadeService} from '../../../../services/facade.service';
import {VisualComponent} from '../../../classes/visual-component';

@Component({
  selector: 'app-visual-component-edit',
  templateUrl: './visual-component-edit.component.html',
  styleUrls: ['./visual-component-edit.component.scss']
})
export class VisualComponentEditComponent implements OnInit, OnChanges {
  @Input() entity: VisualComponent;
  @Input() languageTabIndex;
  @Output() languageTabIndexEvent = new EventEmitter<number>();
  @Output() formDataEvent = new EventEmitter<IFormData<VisualComponent>>();
  form: FormGroup;
  submitted = false;
  mediumCategory: string;
  messageBoxType = MessageBoxTypeEnum;
  INFO_TEXT = INFO_TEXT;

  constructor(
    private fb: FormBuilder,
    protected fs: FacadeService
  ) {}
  ngOnInit(): void {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.form = this.initForm();
    }
  }
  onSubmitHandler(e: any): void {
    this.onSubmit();
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const entity: VisualComponent = {...this.entity};
      Mapper.stringifyTranslations(entity);
      Mapper.mapFormGroup(entity, this.form);
      this.formDataEvent.emit({formType: FormType.UPDATE, obj: entity} as IFormData<VisualComponent>);
    }
  }
  onTabChange(event: MatTabChangeEvent): void {
    if (this.languageTabIndex !== event.index) {
      this.languageTabIndex = event.index;
      this.languageTabIndexEvent.emit(this.languageTabIndex);
    }
  }
  get registerFormControl(): { [p: string]: AbstractControl } {
    return this.form ? this.form.controls : {};
  }
  isTranslated(entity: VisualComponent, code: string): boolean {
    return MediumHelper.isTranslated(entity, code);
  }

  getLocale(t: Translation): string {
    return (t.languageCode === 'en' && ! t.countryCode) ? 'en' : `${t.languageCode}-${t.countryCode}`;
  }
  private buildFormArrayGroup(values: Translation[]): FormArray {
    return this.fb.array(
      values.map(t => this.fb.group(t))
    );
  }
  private initForm(): FormGroup {
    const translationsArray = this.buildFormArrayGroup(this.entity.mappedTranslations);
    return this.fb.group({
      id: [this.entity.id],
      name: [this.entity.name],
      mediumCategoryId: [this.entity.mediumCategoryId],
      url: [this.entity.url],
      campaign: [this.entity.campaign],
      mappedTranslations: translationsArray,
      active: [this.entity.active]
    });
  }
}
