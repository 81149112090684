import { Injectable, Injector } from '@angular/core';
import {MainShopService } from './main-shop.service';
import {MediumUnitService} from './mediumUnit.service';
import {MediumService} from './medium.service';
import {VisualComponentService} from './visual-component.service';
import {SearchDateService} from './search-date.service';
import {ProgressBarService} from './progress-bar.service';
import {GridLayoutService} from './grid-layout.service';
import {ShopCategoryService} from './shop-category.service';
import {UserService} from './user.service';
import {UserRoleService} from './user-role.service';
import {MessageService} from './message.service';
import {InfoboxCategoryService} from './infobox-category.service';
import {InfoboxService} from './infobox.service';
import {InfoboxTypeService} from './infobox-type.service';
import {MediumCategoryService} from './medium-category.service';

@Injectable({
  providedIn: 'root'
})
export class FacadeService {
  private _userService: UserService;
  private _userRoleService: UserRoleService;
  private _mediumUnitService: MediumUnitService;
  private _mediumService: MediumService;
  private _visualComponentService: VisualComponentService;
  private _mainShopService: MainShopService;
  private _shopCategoryService: ShopCategoryService;
  private _searchDateService: SearchDateService;
  private _progressBarService: ProgressBarService;
  private _gridLayoutService: GridLayoutService;
  private _messageService: MessageService;
  private _infoboxTypeService: InfoboxTypeService;
  private _mediumCategoryService: MediumCategoryService;
  private _infoboxCategoryService: InfoboxCategoryService;
  private _infoboxService: InfoboxService;

  public get userService(): UserService {
    if (!this._userService){
      this._userService = this.injector.get(UserService);
    }
    return this._userService;
  }
  public get userRoleService(): UserRoleService {
    if (!this._userRoleService){
      this._userRoleService = this.injector.get(UserRoleService);
    }
    return this._userRoleService;
  }

  public get mediumUnitService(): MediumUnitService {
    if (!this._mediumUnitService){
      this._mediumUnitService = this.injector.get(MediumUnitService);
    }
    return this._mediumUnitService;
  }

  public get mediumService(): MediumService {
    if (!this._mediumService){
      this._mediumService = this.injector.get(MediumService);
    }
    return this._mediumService;
  }

  public get visualComponentService(): VisualComponentService {
    if (!this._visualComponentService){
      this._visualComponentService = this.injector.get(VisualComponentService);
    }
    return this._visualComponentService;
  }

  public get mainShopService(): MainShopService {
    if (!this._mainShopService){
      this._mainShopService = this.injector.get(MainShopService);
    }
    return this._mainShopService;
  }

  public get shopCategoryService(): ShopCategoryService {
    if (!this._shopCategoryService){
      this._shopCategoryService = this.injector.get(ShopCategoryService);
    }
    return this._shopCategoryService;
  }

  public get searchDateService(): SearchDateService {
    if (!this._searchDateService){
      this._searchDateService = this.injector.get(SearchDateService);
    }
    return this._searchDateService;
  }

  public get progeressBarService(): ProgressBarService {
    if (!this._progressBarService){
      this._progressBarService = this.injector.get(ProgressBarService);
    }
    return this._progressBarService;
  }

  public get gridLayoutService(): GridLayoutService {
    if (!this._gridLayoutService){
      this._gridLayoutService = this.injector.get(GridLayoutService);
    }
    return this._gridLayoutService;
  }

  public get messageService(): MessageService {
    if (!this._messageService){
      this._messageService = this.injector.get(MessageService);
    }
    return this._messageService;
  }

  public get infoboxTypeService(): InfoboxTypeService {
    if (!this._infoboxTypeService){
      this._infoboxTypeService = this.injector.get(InfoboxTypeService);
    }
    return this._infoboxTypeService;
  }

  public get infoboxCategoryService(): InfoboxCategoryService {
    if (!this._infoboxCategoryService){
      this._infoboxCategoryService = this.injector.get(InfoboxCategoryService);
    }
    return this._infoboxCategoryService;
  }

  public get mediumCategoryService(): MediumCategoryService {
    if (!this._mediumCategoryService){
      this._mediumCategoryService = this.injector.get(MediumCategoryService);
    }
    return this._mediumCategoryService;
  }

  public get infoboxService(): InfoboxService {
    if (!this._infoboxService){
      this._infoboxService = this.injector.get(InfoboxService);
    }
    return this._infoboxService;
  }

  constructor(private injector: Injector) {  }
}
