import {Medium} from './medium';
import {IObject} from '../interfaces/iobject';
import {MediumCategory} from './medium-category';
import {ShopCategory} from './shop-category';

export class MediumUnit implements IObject {
  constructor(
    public id?: number,
    public mediumCategoryId?: number,
    public shopCategoryId?: number,
    public shopId?: number,
    public name?: string,
    public startDate?: string,
    public endDate?: string,
    public weekdays?: number,
    public isDefault?: boolean,
    public active?: boolean,
    public archived?: boolean,
    public orderIndex?: number,
    public insertTs?: string,
    public updateTs?: string,
    public mediumCategory?: MediumCategory,
    public shopCategory?: ShopCategory,
    public mediumCollection?: Medium[]
  ) {}

}
