import {Injectable} from '@angular/core';
import {MediumUnit} from '../shared/classes/medium-unit';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {catchError, map, retry, share} from 'rxjs/operators';
import {Medium} from '../shared/classes/medium';
import {ApiResponse} from '../shared/interfaces/api-response';
import {Mapper} from '../shared/classes/mapper';

const RETRY_SIZE = 1;

@Injectable({
  providedIn: 'root'
})
export class MediumUnitService extends BaseService<MediumUnit> {
  constructor(
    protected override http: HttpClient
  ) {
    super( http, `${environment.apiBaseUrl}/mediumUnit`);
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.findAll().subscribe(data => {
        this.data = data;
        this.subject.next(this.data);
        resolve();
      });
    });
  }

  override findOne(id: number): Observable<MediumUnit> {
    return this.http.get<ApiResponse>(this.url + '/' + Number(id)).pipe(
        share(),
        retry(RETRY_SIZE),
        map((response: ApiResponse) => {
          if (response.data && response.data.mediumCollection) {
            response.data.mediumCollection.forEach(m => {
              Mapper.parseTranslations(m);
              Mapper.parseTranslateUrls(m);
              Mapper.parseCssStyles(m);
            });
          }
          return response.data;
        }), catchError(this.errorHandler)
    );
  }

  override findAll(): Observable<MediumUnit[]> {
    return this.http.get<ApiResponse>(this.url).pipe(
        share(),
        retry(RETRY_SIZE),
        map((response: ApiResponse) => {
          if (response.data) {
            response.data.forEach(mu => {
                if (mu.mediumCollection) {
                    mu.mediumCollection.forEach(m => {
                        Mapper.parseTranslations(m);
                        Mapper.parseTranslateUrls(m);
                        Mapper.parseCssStyles(m);
                    });
                }
            });
          }
          return response.data;
        }), catchError(this.errorHandler)
    );
  }

  override post<T>(t: T): Observable<T> {
    return this.http.post<ApiResponse>(this.url, t).pipe(
        map((response: ApiResponse) => {
          if (response.data && response.data.mediumCollection) {
            response.data.mediumCollection.forEach(m => {
              Mapper.parseTranslations(m);
              Mapper.parseTranslateUrls(m);
              Mapper.parseCssStyles(m);
            });
          }
          return response.data;
        }), catchError(this.errorHandler)
    );
  }

  override update<T>(id: number, t: T): Observable<T> {
    return this.http.put<ApiResponse>(this.url + '/' + Number(id), t, {}).pipe(
        map((response: ApiResponse) => {
          if (response.data && response.data.mediumCollection) {
            response.data.mediumCollection.forEach(m => {
              Mapper.parseTranslations(m);
              Mapper.parseTranslateUrls(m);
              Mapper.parseCssStyles(m);
            });
          }
          return response.data;
        }), catchError(this.errorHandler)
    );
  }

  GetMediumUnitsByFilter(filter: any): MediumUnit[] {
    return this.data.filter(obj => {
      return Number(obj.mediumCategoryId) === Number(filter.mediumCategoryId) &&
        Number(obj.shopCategoryId) === Number(filter.shopCategoryId) &&
        Number(obj.shopId) === Number(filter.shopId) &&
        obj.active === filter.active;
    });
  }

  GetListForDisplay(shopCategoryId: number, mediumCategoryId: number, shopId: number, date: string): Observable<Medium[]> {
    return this.http.get<ApiResponse>(`${this.url}/globalAndIndividual/${shopCategoryId}/${mediumCategoryId}/${shopId}/${date}`)
      .pipe(
        map((response) => {
            switch (mediumCategoryId) {
                case 1:
                    response.data.forEach(m => {
                        Mapper.parseTranslations(m);
                        Mapper.parseTranslateUrls(m);
                        Mapper.parseCssStyles(m);
                    });
                    break;
                case 2:
                    response.data.forEach(mu => {
                        if (mu.mediumCollection) {
                            mu.mediumCollection.forEach(m => {
                                Mapper.parseTranslations(m);
                                Mapper.parseTranslateUrls(m);
                                Mapper.parseCssStyles(m);
                            });
                        }
                    });
                    break;
            }
            return response.data;
        }), catchError(this.errorHandler)
      );
  }
}
