import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {XlsxImporterService} from '../../../services/xlsx-importer.service';
import {InfoboxType} from '../../classes/infobox-type';
import {FacadeService} from '../../../services/facade.service';
import {Infobox} from '../../classes/infobox';
import {INFO_TEXT, LANGUAGES_EXPANDED } from '../../consts';
import {Shop} from '../../classes/shop';
import {InfoboxCategory} from '../../classes/infobox-category';
import * as _moment from 'moment';
import {DateHelper} from '../../classes/date-helper';
import {Mapper} from '../../classes/mapper';
import {Subscription} from 'rxjs';
import {Message} from '../../classes/message';
import {Router} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {DownloadXlsxComponent} from '../download-xlsx/download-xlsx.component';
import {DynamicDialogComponent} from '../dialog/dynamic-dialog/dynamic-dialog.component';
const moment = _moment;

@Component({
  selector: 'app-infobox-import',
  templateUrl: './infobox-import.component.html',
  styleUrls: ['./infobox-import.component.css']
})
export class InfoboxImportComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  title: string;
  form: FormGroup;
  data: any[] = [];
  collection: any[] = [];
  selectedCollection: any[] = [];
  columns: any[] = [];
  shops: Shop[] = [];
  infoboxTypes: InfoboxType[] = [];
  infoboxCategories: InfoboxCategory[] = [];
  successMessage: string;
  errorMessages: string[] = [];
  messageBoxType = MessageBoxTypeEnum;
  loading: boolean;
  noContent: boolean;
  submitted: boolean;
  languagesExpanded = LANGUAGES_EXPANDED;
  validColumnLabels = {
    customer: ['name', 'shops', 'infoboxType', 'infoboxCategory', 'startDate', 'endDate'],
    article: ['name', 'shops', 'infoboxType', 'infoboxCategory', 'articleId', 'startDate', 'endDate']
  };

  constructor(
      private fb: FormBuilder,
      private fs: FacadeService,
      private xlsxImporterService: XlsxImporterService,
      protected router: Router,
      private dialog: MatDialog
  ) {
    this.title = 'Import Infobox';
    this.shops = this.fs.mainShopService.getAllSubjects();
    this.infoboxTypes = this.fs.infoboxTypeService.getAllSubjects();
    this.infoboxCategories = this.fs.infoboxCategoryService.getAllSubjects();
  }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(): void {
    const formGroup: FormGroup = this.fb.group({
      importFile: [null, Validators.required],
      infoboxType: [null, Validators.required]
    });

    formGroup.get('infoboxType').valueChanges.subscribe(element => {
      this.resetImportData();
    });

    this.form = formGroup;
  }

  onFileSelected(event): void {
    this.resetImportData();

    const selectedInfoboxType = this.form.get('infoboxType').value || null;
    if (! selectedInfoboxType) {
      return;
    }

    const validColumns =  this.validColumnLabels[selectedInfoboxType.name] || [];
    if (! validColumns) {
      return;
    }

    const languageCodes = this.languagesExpanded.map(obj => obj.languageCode + '_' + obj.countryCode);
    const headlines = this.validColumnLabels.article.concat(languageCodes);
    const file: File = event.target.files[0];

    this.xlsxImporterService.importFile(file, headlines).then(response => {
      this.columns = validColumns.map(col => ({columnDef: col, header: col}));
      this.collection = response.data.filter(o => o.infoboxType === selectedInfoboxType.name);

      if (this.collection.length === 0) {
        this.successMessage = `No data could be found for this infobox type in the .xlsx file.`;
      }

    }).catch(error => {
      this.errorMessages.push(error.message);
    });
  }

  onSelectedDataSource(data: any[]): void {
    this.resetErrorMessage();
    this.selectedCollection = data;
  }

  onSubmit(): void {
    this.resetErrorMessage();
    this.submitted = true;
    if (this.form.valid) {
      if (this.selectedCollection) {

        try {
          const collection = [];
          const selectedInfoboxType = this.form.get('infoboxType').value;

          this.selectedCollection.forEach(row => {
            const sName = row.name ? row.name.trim() : null;
            const sShops = row.shops ? row.shops.trim() : null;
            const sInfoboxType = row.infoboxType ? row.infoboxType.trim() : null;
            const sInfoboxCategory = row.infoboxCategory ? row.infoboxCategory.trim() : null;
            const sArticleId = row.articleId ? row.articleId.trim() : null;
            const sStartDate = row.startDate ? row.startDate : null;
            const sEndDate = row.endDate ? row.endDate : null;
            const dateFormat = DateHelper.DATE_TIME_FORMATS.parse.dateOutput;

            if (! sName) {
              throw new Error('Value name is required');
            }

            if (! sArticleId && selectedInfoboxType.name === 'article') {
              throw new Error('Value articleId is required');
            }

            if (! sStartDate) {
              throw new Error('Value start date is invalid');
            }

            if (! sEndDate) {
              throw new Error('Value end date is invalid');
            }

            if (! DateHelper.isValidDate(sStartDate)) {
              throw new Error('Value start date is invalid');
            }

            if (! DateHelper.isValidDate(sStartDate)) {
              throw new Error('Value end date is invalid');
            }

            if (! DateHelper.isValidDateRange(sStartDate, sEndDate)) {
              throw new Error('Date-Range between start- and end date is invalid');
            }

            const startDate = moment(sStartDate).format(dateFormat);
            const endDate = moment(sEndDate).format(dateFormat);

            let infoboxType;
            if (sInfoboxType) {
              infoboxType = this.infoboxTypes.find(o => o.name === sInfoboxType);
              if (! infoboxType) {
                throw new Error('Infobox-Type is invalid');
              }
            }
            else {
              throw new Error('Infobox-Type is required');
            }

            let infoboxCategory;
            if (sInfoboxCategory) {
              infoboxCategory = this.infoboxCategories.find(o => o.name.toLowerCase() === sInfoboxCategory.toLowerCase());
              if (! infoboxCategory) {
                throw new Error('Infobox-Category is invalid');
              }
            }
            else {
              throw new Error('Infobox-Category is required');
            }

            const shops = [];
            if (sShops) {
              const arrShops = sShops.split(',');
              arrShops.forEach(value => {
                const countryCode = value.trim().toLowerCase();
                const shop = this.shops.find(s => s.countryCode.toLowerCase() === countryCode);
                if (! shop) {
                  throw new Error(`Shop with country code '${countryCode}' not exists`);
                }
                shops.push(shop);
              });
            }

            let articleId = null;
            if (selectedInfoboxType.name === 'article') {
              articleId = sArticleId;
            }

            if (shops.length > 0) {
              shops.forEach(shop => {
                const title = `${sName} | ${shop.countryCode}`;
                const infobox = this.createEntity(title, shop.id, infoboxCategory.id, infoboxType.id, articleId, startDate, endDate, row);
                collection.push(infobox);
              });
            }
            else {
              const title = `${sName} | Global`;
              const infobox = this.createEntity(title, null, infoboxCategory.id, infoboxType.id, articleId, startDate, endDate, row);
              collection.push(infobox);
            }
          });

          if (collection.length > 0) {
            this.fs.progeressBarService.start();
            this.processSave(collection)
                .then(() => {
                  this.successMessage = 'Success! Import Data';
                  this.successHandling(this.successMessage);
                })
                .catch(error => {
                  /*console.error(error);*/
                }).finally(() => {
              this.fs.progeressBarService.complete();
              this.resetImportData();
              this.router.navigate(['/infobox-import'], {skipLocationChange: true});
            });
          }

        } catch (error) {
          if (error instanceof Error) {
            this.errorMessages.push(error.message);
          } else {
            console.error('An unknown error occurred:', error);
          }
        }
      }
      else {
        throw new Error('No rows selected');
      }
    }
  }
  private createEntity(title, shopId, infoboxCategoryId, infoboxTypeId, articleId, startDate, endDate, cols): Infobox {
    const entity = Mapper.createInfobox();
    entity.name = title;
    entity.shopId = shopId;
    entity.infoboxTypeId = infoboxTypeId;
    entity.infoboxCategoryId = infoboxCategoryId;
    entity.startDate = startDate;
    entity.endDate = endDate;
    entity.articleId = articleId;
    return this.mapTranslations(entity, cols);

  }
  private mapTranslations(entity: Infobox, row: any): Infobox {
      entity.mappedTranslations.forEach(t => {
        let key;
        if (t.languageCode === 'en' && ! t.countryCode) {
          key = 'en';
        }
        else {
          key = `${t.languageCode}_${t.countryCode}`;
        }
        if (row[key]) {
          const translationString = row[key];
          if (translationString) {
            const results = translationString.split(/\r?\n/);

            let text = '';
            if (results.length > 1) {
              results.forEach((str, i) => {
                if (i === 0) {
                  text = `<p style='font-weight: bold;'>${str.trim()}</p>`;
                }
                else {
                  text += `<p>${str.trim()}</p>`;
                }
              });
            }
            else {
              text = `<p>${translationString.trim()}</p>`;
            }
            t.infoboxFormatted = text;
          }
        }
      });

      entity.translations = JSON.stringify(entity.mappedTranslations);
      return entity;
  }

  processSave(infoboxes): Promise<any> {
    return new Promise((resolve, reject) => {
      let successCount = 0;
      let errorCount = 0;
      const total = infoboxes.length;

      infoboxes.forEach(infobox => {
        const subscription = this.fs.infoboxService.post(infobox).subscribe(
            (result: Infobox) => {
              this.fs.infoboxService.insertSubject(result);
              successCount++;
              checkCompletion();
            },
            error => {
              errorCount++;
              checkCompletion();
            }
        );
        this.subscriptions.push(subscription);
      });

      function checkCompletion(): void {
        if (successCount + errorCount === total) {
          const success = successCount === total;
          if (success) {
            resolve(true);
          } else {
            reject(`Error processing ${errorCount} infobox(es).`);
          }
        }
      }
    });
  }

  resetImportData(): void {
    this.successMessage = null;
    this.errorMessages = [];
    this.data = [];
    this.collection = [];
    this.selectedCollection = [];
  }

  resetForm(): void {
    this.resetImportData();
    this.form.reset();
    this.submitted = false;
  }
  resetErrorMessage(): void {
    this.errorMessages = [];
  }

  onResetForm(event): void {
    event.preventDefault();
    this.resetForm();
  }

  onDisplayDialog(e: Event): void {
    e.preventDefault();
    const languageCodes = this.languagesExpanded.map(obj => {
      return (obj.languageCode === 'en' && ! obj.countryCode) ? 'en' :  obj.languageCode + '_' + obj.countryCode;
    });

    const config = {
      title: 'Info',
      headline: 'Information for .xlsx import to create infoboxes:',
      content: INFO_TEXT.INFOBOX_IMPORT,
      file: {
        name: 'import_infobox.xlsx',
        headlines: this.validColumnLabels.article.concat(languageCodes),
        rows: []
      },
      dialogConfig: null
    };
    this.openDialog(config);
  }

  openDialog(config: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;


    this.dialog.open(DynamicDialogComponent, {
      data: { config, component: DownloadXlsxComponent }
    });
  }

  successHandling(message: string): void {
    this.fs.messageService.add(new Message('success', message, true), 10000);
    this.fs.progeressBarService.complete();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(obj => obj.unsubscribe());
  }
}
