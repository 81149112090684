<div class="table-actionbar">
  <div class="search-box">
    <mat-form-field appearance="standard" floatLabel="always" class="example-form-field" *ngIf="displayFilter">
      <mat-label>Search</mat-label>
      <input matInput type="text" [(ngModel)]="value" (keyup)="applyFilter($event)">

      <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="onClearSearch($event)">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <div class="action-box" *ngIf="displayTableHeaderButtons">
    <button color="warn" mat-mini-fab aria-label="Delete" (click)="onDelete($event)">
      <mat-icon>delete</mat-icon>
    </button>
    <button color="primary" mat-mini-fab aria-label="Refresh" (click)="onRefresh($event)">
      <mat-icon>refresh</mat-icon>
    </button>
  </div>
</div>
<mat-table #table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <!-- checkbox header -->
  <ng-container matColumnDef="select" *ngIf="displaySelectColumn">
    <mat-header-cell *matHeaderCellDef>
      <!--<mat-checkbox color="primary" (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
      </mat-checkbox>-->
    </mat-header-cell>
    <mat-cell *cdkCellDef="let row">
      <mat-checkbox color="primary" (click)="$event.stopPropagation()" (change)="selectHandler(row)"
        [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </mat-cell>
  </ng-container>
  <!-- table dynamic columns -->
  <ng-container *ngFor="let column of columns; let i = index;" [matColumnDef]="column.columnDef">
    <mat-header-cell [style.flex]="getColumnWidth(i)" *cdkHeaderCellDef mat-sort-header>{{ column.header }}</mat-header-cell>
    <ng-container *cdkCellDef="let row;" [ngSwitch]="getColumnFormat(i)">
      <mat-cell *ngSwitchCase="'date'" [style.flex]="getColumnWidth(i)">
        {{ row[column.columnDef] | date: DateHelper.DATE_FORMATS.display.date }}
      </mat-cell>
      <mat-cell *ngSwitchCase="'datetime'" [style.flex]="getColumnWidth(i)">
        {{ row[column.columnDef] | date: DateHelper.DATE_FORMATS.display.dateAndTime }}
      </mat-cell>
      <mat-cell *ngSwitchCase="'weekdays'" [style.flex]="getColumnWidth(i)">
        <app-weekdays-info [weekdays]="row[column.columnDef]"></app-weekdays-info>
      </mat-cell>
      <mat-cell *ngSwitchCase="'shop'" [style.flex]="getColumnWidth(i)">
        {{ row[column.columnDef] ? row[column.columnDef]['id'] : null }}
      </mat-cell>
      <mat-cell *ngSwitchDefault [style.flex]="getColumnWidth(i)">
        {{ row[column.columnDef] }}
      </mat-cell>
    </ng-container>
  </ng-container>

  <!-- table action column -->
  <ng-container matColumnDef="action" *ngIf="displayActionColumn">
    <mat-header-cell *matHeaderCellDef>Action</mat-header-cell>
    <mat-cell *matCellDef="let element" action-buttons [value]="element" (buttonAction)="onTableAction($event)">
      {{ element.weight }}
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>
<!-- table pagination -->
<mat-paginator [hidden]="! displayPagination" [pageSizeOptions]="rowsPerSite" showFirstLastButtons></mat-paginator>

