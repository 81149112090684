import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Shop} from '../../classes/shop';
import {environment} from '../../../../environments/environment';
import {FacadeService} from '../../../services/facade.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CSS_LAYOUT_CONFIG, MEDIUM_CATEGORY_CONFIG} from '../../consts';
import {ShopCategory} from '../../classes/shop-category';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';
import {User} from '../../classes/user';

@Component({
  selector: 'app-medium-category-page-selection',
  templateUrl: './medium-category-page-selection.component.html',
  styleUrls: ['./medium-category-page-selection.component.scss']
})
export class MediumCategoryPageSelectionComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  shops: Shop[] = [];
  layoutStyles = CSS_LAYOUT_CONFIG;
  imageDirectoryPath = environment.flagsimagesDir;
  CSS_LAYOUT_CONF: any;
  MEDIUM_CATEGORY_CONF: any;
  MessageBoxType = MessageBoxTypeEnum;
  shopCategory: ShopCategory | undefined;
  displayGlobalColumn = true;
  displayIndividualColumn = true;

  user: User;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fs: FacadeService
  ) {
    this.CSS_LAYOUT_CONF = CSS_LAYOUT_CONFIG;
  }

  ngOnInit(): void {
    this.fs.userService.user$.subscribe(user => this.user = user);

    const section = this.route.snapshot.parent?.routeConfig?.path;
    if (section === 'section-infobox-customer') {
      this.displayGlobalColumn = true;
    }

    if (section === 'section-infobox-article') {
      this.displayGlobalColumn = true;
    }

    let categoryName = '';
    const subscription = this.fs.shopCategoryService.observableSelectedShopCategory$.subscribe({
      next: data => {
        this.shopCategory = data;
        if (this.shopCategory) {
          switch (Number(this.shopCategory.id)) {
            case 1:
              // Category Main-Shop
              categoryName = this.shopCategory.name?.toLowerCase() ?? '';
              this.shops = this.fs.mainShopService.getAllSubjects();
              break;
            case 2:
              // Partner-Shop not implemented
              categoryName = 'partner-shop';
              this.shops = [];
              break;
          }

          this.MEDIUM_CATEGORY_CONF = MEDIUM_CATEGORY_CONFIG[section][categoryName];
          if (!this.MEDIUM_CATEGORY_CONF) {
            this.router.navigate(['not-found']);
          }
        }
      }
    });
    this.subscriptions.push(subscription);
  }

  getImagePath(countryCode: string): string {
    return `${this.imageDirectoryPath}/flag_${countryCode}.jpg`;
  }

  ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
  }
}
