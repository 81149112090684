import {DialogType} from '../enum/dialog-type-enum';
import {FacadeService} from '../../services/facade.service';
import {Injectable, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {Message} from './message';
import {BaseService} from '../../services/base-service';

@Injectable()
export class DialogHandler implements OnDestroy {
  subscriptions: Subscription[] = [];

  constructor(
    private fs: FacadeService,
    private service: BaseService<any>
  ) {}

  async process(dialogData: any): Promise<any> {
    if (! dialogData.dialogType || ! dialogData.data) { return; }
    switch (dialogData.dialogType) {
      case DialogType.INSERT_DIALOG:
        return this.insertHandler(dialogData.data);
      case DialogType.UPDATE_DIALOG:
        return this.updateHandler(dialogData.data);
      case DialogType.DELETE_DIALOG:
        return  this.deleteHandler(dialogData.data);
    }
  }

  protected insertHandler(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fs.progeressBarService.start();
      const subscription = this.service.post(data).subscribe((res) => {
        this.service.insertSubject(res);
        this.successHandling('Success! Insert | ID: ' + res.id);
        resolve(res);
      }, (err) => {
        this.errorHandling(err);
        /*reject(err);*/
      });
      this.subscriptions.push(subscription);
    });
  }

  protected updateHandler(data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.fs.progeressBarService.start();
      const subscription = this.service.update(data.id, data).subscribe((res) => {
        this.service.updateSubject(res.id, res);
        this.successHandling('Success! Update | ID: ' + res.id);
        resolve(res);
      }, (err) => {
        this.errorHandling(err);
        /*reject(err);*/
      });
      this.subscriptions.push(subscription);
    });
  }

  protected deleteHandler(data: any): Promise<any> {
    this.fs.progeressBarService.start();
    return new Promise((resolve, reject) => {
      const subscription = this.service.delete(data.id).subscribe((res) => {
        this.service.removeSubject(data.id);
        this.successHandling('Success! Delete');
        resolve(res);
      }, (err) => {
        this.errorHandling(err);
        /*reject(err);*/
      });
      this.subscriptions.push(subscription);
    });
  }

  private successHandling(message: string): void {
    this.fs.messageService.add(new Message('success', message, true), 10000);
    this.fs.progeressBarService.complete();
  }

  private errorHandling(err: any): void {
    const message = err.error.description;
    this.fs.messageService.add(new Message('danger', message, true), 20000);
    this.fs.progeressBarService.complete();
  }

  ngOnDestroy(): void {
    if (this.subscriptions.length) {
       this.subscriptions.forEach(obj => obj.unsubscribe);
    }
  }
}
