<div
        class="resizable"
        [class.content-narrow]="isContentNarrow"
        [fxFlex]="LAYOUT_CONFIG.contentCol['flex-max-width']"
        fxFlex.lt-md="100%"
>
    <section
            class="header"
            fxLayout="row"
            fxLayoutAlign="space-between center"
    >
        <ng-container *ngIf="! shop; else shopHeadline">
            <p class="__bold">{{headline}}</p>
        </ng-container>

        <ng-template #shopHeadline>
            <div
                    fxLayout="row"
                    fxLayoutAlign="space-between center"
            >
                <p class="__bold">{{headline}}  | </p>
                <img class="icon" [src]="getImagePath(shop.countryCode)" [alt]="shop.country">
                <p>{{shop?.country}}</p>
            </div>
        </ng-template>

        <section
                class="icon-links"
                fxLayoutAlign="space-between center"
                fxFlexAlign="center"
                mat-list-icon
        >
            <mat-icon class="fa fa-refresh orange" *ngIf="displayButtonRefreshOrder" (click)="onUpdateOrderIndex()"
                      title="Update sorted list"></mat-icon>
            <mat-icon class="fa fa-plus" *ngIf="contentDataIsLoaded" (click)="onCreateItem($event)"
                      title="{{this.MEDIUM_CATEGORY_CONF.HEADLINE_ADD_NEW_UNIT}}"></mat-icon>
            <mat-icon class="fa fa-trash-o" *ngIf="selectedItem" (click)="onDeleteItem($event)"
                      title="{{this.MEDIUM_CATEGORY_CONF.HEADLINE_DELETE_UNIT}}"></mat-icon>
            <mat-icon class="fa fa-arrows-h" (click)="onToggleResizeContent()"
                      title="Resize column width"></mat-icon>
        </section>
    </section>
    <mat-divider></mat-divider>
    <section class="list list-vertical-scroll" smoothScrollbar *ngIf="contentDataIsLoaded">
        <mat-selection-list *ngIf="collection.length; else noContentTpl;"
                            class="drop-content"
                            cdkDropList
                            [cdkDropListData]="collection"
                            (cdkDropListDropped)="drop($event)"
        >
            <mat-list-option *ngFor="let c of collection"
                             [routerLink]="[MEDIUM_CATEGORY_CONF.SUBROUTE, c.id]"
                             [routerLinkActive]="'active'"
                             [attr.data-slideId]="c.id"
                             class="list-drag-item"
                             [cdkDragData]="c"
                             cdkDrag
                             [class.switched-on]="c.active"
                             [class.switched-off]="! c.active"
                             (click)="onSelectItem(c.id)"
            >
                <div
                        fxLayout="row"
                        fxLayoutAlign="space-between center"
                >
                    <div class="content">
                        <p mat-line>{{c.name}}</p>
                        <app-date-range-info *ngIf="c.startDate && c.endDate" [startDate]="c.startDate"
                                             [dateFormat]="dateFormat"
                                             [endDate]="c.endDate"></app-date-range-info>
                        <!--<app-weekdays-info [weekdays]="c.weekdays"></app-weekdays-info>-->
                    </div>
                    <mat-icon class="md-14 icon-settings"
                              title="{{this.MEDIUM_CATEGORY_CONF.HEADLINE_CONFIGURE_UNIT}}"
                              (click)="onEditItem($event, c.id)">settings
                    </mat-icon>
                </div>
            </mat-list-option>
        </mat-selection-list>

        <ng-template #noContentTpl>
            <app-message-box [type]="messageBoxType.NoContent" [message]="'list is empty'"></app-message-box>
        </ng-template>

    </section>
</div>


