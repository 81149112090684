<h2 mat-dialog-title>{{data.title}}</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-dialog-content fxLayout="column">

        <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
            <mat-label>Destination URL</mat-label>
            <input matInput formControlName="url">

            <mat-error *ngIf="(registerFormControl['url'].touched || submitted) && registerFormControl['url'].errors?.required">
                URL is required
            </mat-error>

            <mat-error *ngIf="registerFormControl['url'].hasError('invalidUrl')">
                Valid URL is required
            </mat-error>
        </mat-form-field>

        <div class="mt-2 mb-4">
            <button *ngIf="collection.length > 0" mat-stroked-button title="Reset Fields" (click)="onResetForm()">
                <i class="fa fa-repeat"></i>
            </button>
            <button mat-stroked-button [disabled]="! form.valid" (click)="onLoadTranslatedUrls($event)">Load</button>
        </div>

        <ng-container *ngIf="collection.length > 0">
            <div class="table-wrapper">
                <app-mat-table
                        [dataset]="collection"
                        [columns]="columns"
                        [displayFilter]="false"
                        [displaySelectColumn]="false"
                        [displayPagination]="true">
                </app-mat-table>
            </div>
        </ng-container>

        <ng-template [ngIf]="noContent">
            <app-message-box [type]="messageBoxType.NoContent" [message]="'No links loaded'"></app-message-box>
        </ng-template>

        <ng-template [ngIf]="loading">
            <mat-spinner class="center"></mat-spinner>
        </ng-template>

        <ng-container *ngIf="message">
            <app-message-box [type]="messageBoxType.Info" [message]="message" ></app-message-box>
        </ng-container>

        <app-message-box class="mt-2" [type]="messageBoxType.Default" [message]="INFO_TEXT.TRANSLATION_LINKS" ></app-message-box>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Close</button>
        <button mat-stroked-button type="submit"
                [disabled]="! form.valid || this.collection.length === 0">Apply
        </button>
    </mat-dialog-actions>
</form>



