import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import {UserService} from '../../services/user.service';
import {FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  subscription: Subscription[] = [];
  form: FormGroup;
  busy: boolean;
  loginInvalid: boolean;
  formSubmitAttempt: boolean;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {
    this.form = this.fb.group({
      username: ['', Validators.email],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    const subscription = this.userService.user$.subscribe((user) => {
      if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'login') {
        const accessToken = localStorage.getItem('access_token');
        const refreshToken = localStorage.getItem('refresh_token');
        if (user && accessToken && refreshToken) {
          const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '';
          this.router.navigate([returnUrl]);
        }
      }
    });
    this.subscription.push(subscription);
  }

  onSubmit(): void  {
    this.loginInvalid = false;
    this.formSubmitAttempt = false;
    this.busy = true;
    if (this.form.valid) {
      try {
        const username = this.form.get('username')?.value;
        const password = this.form.get('password')?.value;
        const subscription = this.userService
          .login(username, password)
          .pipe(finalize(() => (this.busy = false)))
          .subscribe(
            () => {
              this.router.navigate(['']);
            },
            (err) => {
              this.loginInvalid = true;
            }
          );
        this.subscription.push(subscription);
      } catch (err) {
        this.loginInvalid = true;
      }
    } else {
      this.formSubmitAttempt = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.forEach(obj => obj.unsubscribe());
  }
}
