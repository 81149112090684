<div class="mt-2">

  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="id">
    <input type="hidden" formControlName="name">
    <input type="hidden" formControlName="mediumCategoryId">
    <div
      fxLayout="column"
      fxLayoutAlign="start">
      <mat-tab-group *ngIf="entity.mappedTranslations" formArrayName="mappedTranslations" class="tab-small" [selectedIndex]="languageTabIndex">
        <ng-container [formGroupName]="i" *ngFor="let t of entity.mappedTranslations; let i = index;">
          <mat-tab>

            <ng-template mat-tab-label>
              <span
                      [matTooltip]="t.language"
                      matTooltipShowDelay="500"
                      matTooltipPosition="above"
              >{{getLocale(t)}}</span>
              <i *ngIf="! isTranslated(entity, t.languageCode)" class='fa fa-pencil'></i>
            </ng-template>

            <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
              <mat-label floatLabel="never">Headline</mat-label>
              <input matInput formControlName="headline">
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
              <mat-label>Subheadline</mat-label>
              <input matInput formControlName="subheadline">
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="100%">
              <mat-label>Image Description (Alt-Attribute)</mat-label>
              <input matInput formControlName="imageAlt">
            </mat-form-field>

          </mat-tab>
        </ng-container>
      </mat-tab-group>

      <mat-form-field appearance="standard" floatLabel="always" fxFlex="100%">
        <mat-label>Campaign-Parameter</mat-label>
        <input matInput formControlName="campaign">
        <mat-error
                *ngIf="(registerFormControl.campaign.touched || submitted) && registerFormControl.campaign.errors?.required">
          Campaign-Parameter is required
        </mat-error>
      </mat-form-field>

      <mat-checkbox formControlName="active">Active</mat-checkbox>

      <div class="mt-4 mb-4">
        <button mat-stroked-button
                debounceClick
                [debounceTime]="500"
                [disabled]="! form.valid"
                (onEvent)="onSubmitHandler($event)">Save
        </button>
      </div>

      <!--<app-message-box [type]="messageBoxType.Default" [message]="INFO_TEXT.TRANSLATION_FALLBACK"></app-message-box>-->
    </div>
  </form>
</div>
