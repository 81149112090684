<h2 mat-dialog-title>{{data.title}}</h2>
<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <mat-dialog-content fxLayout="column">
        <div fxLayout fxLayoutGap="20px" fxLayoutAlign="space-between center">
            <input type="hidden" formControlName="languageCode">
            <input type="hidden" formControlName="countryCode">

            <mat-form-field appearance="standard" floatLabel="always" fxFlex="10%">
                <mat-label>Locale</mat-label>
                <input readonly matInput formControlName="locale">
            </mat-form-field>

            <mat-form-field appearance="standard" floatLabel="always" fxFlex="90%">
                <mat-label>Url</mat-label>
                <input matInput formControlName="url">
                <mat-error *ngIf="(registerFormControl['url'].touched || submitted) && registerFormControl['url'].errors?.required">
                    URL is required
                </mat-error>

                <mat-error *ngIf="registerFormControl['url'].hasError('invalidUrl')">
                    Valid URL is required
                </mat-error>
            </mat-form-field>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-stroked-button mat-dialog-close>Close</button>
        <button mat-stroked-button type="submit"
                [disabled]="! form.valid">Save
        </button>
    </mat-dialog-actions>
</form>



