import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogType } from '../../../enum/dialog-type-enum';
import {IDialogData} from '../../../interfaces/idialog-data';
import {DIALOG_DIMENSIONS} from '../../../consts';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.css']
})
export class DeleteDialogComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  obj: any;
  constructor(
      private fb: FormBuilder,
      private dialogRef: MatDialogRef<DeleteDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: IDialogData) {
        this.obj = data.obj;
  }

  ngOnInit(): void {
    this.form = this.initForm();
    const {width, height} = this.data.dialogDimensions ? this.data.dialogDimensions : DIALOG_DIMENSIONS;
    this.dialogRef.updateSize(width, height);
  }

  close(): void {
      this.dialogRef.close();
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const obj: object = {...this.obj, ...this.form.value};
      this.dialogRef.close({dialogType: DialogType.DELETE_DIALOG, data: obj});
    }
  }

  private initForm(): FormGroup {
    return this.fb.group({
      id: [this.obj.id]
    });
  }
}
