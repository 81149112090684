import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {NGXLogger} from 'ngx-logger';
import {InfoboxCategory} from '../shared/classes/infobox-category';

@Injectable({
  providedIn: 'root'
})
export class InfoboxTypeService extends BaseService<InfoboxCategory> {

  constructor(
    protected override http: HttpClient,
    protected logger: NGXLogger
  ) {
    super(http, `${environment.apiBaseUrl}/infoboxType`);
  }

  init(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this.findAll().subscribe(data => {
        this.data = data;
        this.subject.next(this.data);
        resolve();
      });
    });
  }
}
