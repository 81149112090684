import {Component, Input, Output, OnInit, EventEmitter, OnDestroy, SimpleChanges, OnChanges} from '@angular/core';
import {ResizeEvent} from 'angular-resizable-element';
import {MediumCategoryEnum} from '../../../enum/medium-category-enum';
import {CSS_LAYOUT_CONFIG, MEDIUM_CATEGORY_CONFIG} from '../../../consts';
import {IFormData} from '../../../interfaces/form-data';
import {Subscription} from 'rxjs';
import {FacadeService} from '../../../../services/facade.service';
import {ImageType} from '../../../enum/image-type-enum';
import {VisualComponent} from '../../../classes/visual-component';

@Component({
  selector: 'app-visual-component-adaptation-content',
  templateUrl: './visual-component-adaptation-content.component.html',
  styleUrls: ['./visual-component-adaptation-content.component.scss']
})
export class VisualComponentAdaptationContentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() entity: VisualComponent | undefined;
  @Output() resizedWithEvent = new EventEmitter<string>();
  @Output() formDataEvent = new EventEmitter<IFormData<VisualComponent>>();
  @Output() dialogDataEvent = new EventEmitter<IFormData<VisualComponent>>();
  @Output() imageEvent = new EventEmitter<IFormData<VisualComponent>>();
  imageType = ImageType;
  subscriptions: Subscription[] = [];
  headline = '';
  tabindex = 0;
  languageTabIndex = 0;
  cssLayoutConfig = CSS_LAYOUT_CONFIG;
  MIN_WIDTH;
  MAX_WIDTH;
  MEDIUM_CATEGORY_CONF: any;
  layoutStyles = {
    adaptationCol: {
      width: ''
    }
  };
  constructor(
    public fs: FacadeService
  ) {
    this.MIN_WIDTH = this.cssLayoutConfig.adaptationCol['flex-min-width'];
    this.MAX_WIDTH = this.cssLayoutConfig.adaptationCol['flex-max-width'];
    this.layoutStyles.adaptationCol.width = this.MIN_WIDTH;
  }

  ngOnInit(): void {
    this.tabindex = 0;
    this.languageTabIndex = 0;
    const subscription = this.fs.gridLayoutService.adaptationCol$.subscribe(width => {
        this.layoutStyles.adaptationCol.width = `${width}px`;
        this.resizedWithEvent.emit(this.layoutStyles.adaptationCol.width);
    });
    this.subscriptions.push(subscription);

    this.MEDIUM_CATEGORY_CONF = MEDIUM_CATEGORY_CONFIG.teaser_specials_sale;
    if (this.MEDIUM_CATEGORY_CONF) {
      this.headline = this.MEDIUM_CATEGORY_CONF.HEADLINE_CONFIGURE_ITEM;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entity) {
      this.entity = changes.entity.currentValue;
    }
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(obj => obj.unsubscribe());
  }
  onChangeLanguageTabIndex(index: number): void {
    if (this.languageTabIndex !== index) {
      this.languageTabIndex = index;
    }
  }
  onResizeEnd(event: ResizeEvent): void {
    const width = Number(event.rectangle.width);
    if (isNaN(width)) { return; }
    this.fs.gridLayoutService.resizeAdaptationCol(width);
  }

  onResizing(event: ResizeEvent): void {
    const width = Number(event.rectangle.width);
    if (isNaN(width)) { return; }
    this.fs.gridLayoutService.resizeAdaptationCol(width);
  }

  onSubmitForm(formData: IFormData<any>): void {
    this.formDataEvent.emit(formData);
  }

  onDialogEvent(data: any): void {
    this.dialogDataEvent.emit(data);
  }

  onImageEvent(data: any): void {
    this.imageEvent.emit(data);
  }

  displayEditor(): boolean {
    let display = false;
    if (this.entity?.mediumCategoryId === MediumCategoryEnum.Teaser_Specials_Sale && this.entity.image) {
      display = true;
    }
    return display;
  }
}
