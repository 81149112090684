<ng-container *ngIf="display">
    <mat-card class="card-dashboard">
        <div class="header" [routerLink]="['/', config?.route]" title="{{config?.buttonLabel}}">
            <mat-icon>{{config.icon}}</mat-icon>
        </div>
        <div class="content">
            <div class="title-wrapper">
                <h2>{{config?.title}}</h2>
            </div>
            <table class="table" *ngIf="data">
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': ('grey')}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.all.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.all)">All</span>
                        </div>
                    </th>
                    <td>{{data.all.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': 'grey'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.global.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.global)">Global</span>
                        </div>
                    </th>
                    <td>{{data.global.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': 'grey'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.individual.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.individual)">Individual</span>
                        </div>
                    </th>
                    <td>{{data.individual.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': '#10af00'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.active.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.active)">Active</span>
                        </div>
                    </th>
                    <td>{{data.active.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': '#f00'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.inactive.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.inactive)">Inactive</span>
                        </div>
                    </th>
                    <td>{{data.inactive.length}}</td>
                </tr>
            </table>

            <mat-divider></mat-divider>

            <form [formGroup]="form">
                <div class="mt-4" fxLayout="row"
                     fxLayoutAlign="space-between center"
                >
                    <mat-form-field appearance="standard" floatLabel="always">
                        <mat-label>Shop</mat-label>
                        <mat-select formControlName="selectedShop">
                            <mat-option [value]="s" *ngFor="let s of shops as Shop">
                                <span>{{s.name}}</span>
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div>
                        <img width="15px" height="15px" src="assets/images/flags/flag_{{selectedShop.countryCode}}.jpg"
                             alt="{{selectedShop.name}}">
                    </div>
                </div>
            </form>
            <table class="table" *ngIf="data.selectedShop">
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': 'grey'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.selectedShop.all.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.selectedShop.all)">All</span>
                        </div>
                    </th>
                    <td>{{data.selectedShop.all.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': '#10af00'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.selectedShop.active.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.selectedShop.active)">Active</span>
                        </div>
                    </th>
                    <td>{{data.selectedShop.active.length}}</td>
                </tr>
                <tr>
                    <th>
                        <div class="info-label">
                            <mat-icon [ngStyle]="{'color': '#f00'}">square</mat-icon>
                            <span [ngStyle]="{'cursor': data.selectedShop.inactive.length ? 'pointer' : 'default'}"
                                  (click)="onOpenInfoDialog(data.selectedShop.inactive)">Inactive</span>
                        </div>
                    </th>
                    <td>{{data.selectedShop.inactive.length}}</td>
                </tr>
            </table>

            <div class="footer">
                <mat-divider></mat-divider>
                <button mat-stroked-button [routerLink]="['/', config?.route]">{{config?.buttonLabel}}</button>
            </div>
        </div>
    </mat-card>

</ng-container>
