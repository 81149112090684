import { Component, OnInit } from '@angular/core';
import {MessageBoxTypeEnum} from '../../enum/message-box-type-enum';

@Component({
  selector: 'app-no-permissions',
  templateUrl: './no-permissions.component.html',
  styleUrls: ['./no-permissions.component.css']
})
export class NoPermissionsComponent implements OnInit {
  messageBoxTypeEnum = MessageBoxTypeEnum;
  message: string;
  messageBoxType = MessageBoxTypeEnum;
  constructor() {}

  ngOnInit(): void {
    this.message = 'Sorry - You don\'t have permission to access this content!';
  }
}
