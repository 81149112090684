<div class="mt-2" *ngIf="entity.url || (! entity.url && translateUrls.length == 0); else infoTmp">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <input type="hidden" formControlName="id">

      <div fxLayout="column" class="form-group-wrapper mb-4">
        <mat-form-field appearance="standard" floatLabel="always" *ngIf="registerFormControl.url.value">
          <mat-label>Destination URL</mat-label>
          <input matInput formControlName="url" readonly>
          <mat-error *ngIf="(registerFormControl.url.touched || submitted) && registerFormControl.url.errors?.required">
            URL is required
          </mat-error>
        </mat-form-field>

        <div fxFlex="row" fxLayoutAlign="space-between center">
          <button [disabled]="translateUrls.length > 0" mat-stroked-button class="btn-white" (click)="onLoadTranslatedUrls($event)">Load</button>
          <button [disabled]="translateUrls.length == 0" class="btn-white" mat-stroked-button title="Delete all URLs" (click)="onDeleteAllUrls($event)">
            <i class="fa fa fa-trash-o"></i>
          </button>
        </div>
      </div>
      <div *ngIf="translateUrls && translateUrls.length > 0" formArrayName="mappedTranslateUrls">
        <div fxLayout fxLayoutGap="10px" fxLayoutAlign="space-between center" [formGroupName]="i" *ngFor="let t of registerFormArray.controls; let i = index;">

          <input type="hidden" formControlName="languageCode">
          <input type="hidden" formControlName="countryCode">

          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="10%">
            <mat-label>Locale</mat-label>
            <input readonly matInput formControlName="locale">
          </mat-form-field>

          <mat-form-field appearance="standard" floatLabel="always" fxLayout="column" fxFlex="85%">
            <mat-label>Url</mat-label>
            <input matInput formControlName="url">
            <mat-error *ngIf="(registerFormControl.url.touched || submitted) && registerFormControl.url.errors?.required">
              URL is required
            </mat-error>

            <mat-error *ngIf="registerFormControl.url.hasError('invalidUrl')">
              Valid URL is required
            </mat-error>
          </mat-form-field>

          <div fxFlex="5%">
              <i class="fa fa-trash-o" (click)="onDeleteOneUrl($event, i)" title="Delete URL"></i>
          </div>
        </div>
      </div>

      <div class="mt-4 mb-4">
        <button *ngIf="translateUrls.length"
                mat-stroked-button
                debounceClick
                [debounceTime]="500"
                [disabled]="! form.valid"
                (onEvent)="onSubmit()">Save
        </button>
      </div>
  </form>
</div>

<ng-template #infoTmp>
  <app-message-box [type]="messageBoxType.Warning" [message]="'URLs have already been loaded. Please delete all manually generated URLs.'"></app-message-box>
</ng-template>
