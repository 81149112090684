
<div id="page-root">
    <div class="header-wrapper">
        <h1>L-SHOP CMS-Frontpage</h1>
    </div>
    <div class="form-wrapper">
        <div class="card-pf">
            <header class="login-pf-header">
                <h1 id="kc-page-title">Sign in to your account</h1>
            </header>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field appearance="standard" floatLabel="always">
                <input matInput placeholder="email" formControlName="username" required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="standard" floatLabel="always">
                <input matInput type="password" placeholder="Password" formControlName="password" required>
                <mat-error>
                    Please provide a valid password
                </mat-error>
            </mat-form-field>
            <mat-error *ngIf="loginInvalid">
                Invalid email or password
            </mat-error>
            <button class="btn-login" mat-stroked-button>Sign In</button>
        </form>
    </div>
</div>
