import * as _moment from 'moment';
const moment = _moment;

export class CollectionFilter {
  public static filter(collection: any[], filter: any): any[] {
    if (collection.length === 0) {
      return collection;
    }

    let filteredCollection = collection;

    if (filter.global) {
      filteredCollection = filteredCollection.filter(obj => obj.shopId === null);
    }
    if (filter.shopId) {
      filteredCollection = filteredCollection.filter(obj => Number(obj.shopId) === Number(filter.shopId));
    }
    if (filter.infoboxTypeId) {
      filteredCollection = filteredCollection.filter(obj => Number(obj.infoboxTypeId) === Number(filter.infoboxTypeId));
    }
    if (filter.minDate) {
      filteredCollection = filteredCollection.filter(obj => {
        if (obj.isDefault || moment(obj.startDate).isSameOrAfter(filter.minDate)) {
          return true;
        }
        return false;
      });
    }
    if (filter.maxDate) {
      filteredCollection = filteredCollection.filter(obj => {
        if (obj.isDefault || moment(obj.endDate).isSameOrBefore(filter.maxDate)) {
          return true;
        }
        return false;
      });
    }
    if (filter.name) {
      filteredCollection = filteredCollection.filter(obj => {
        return obj.name.toLowerCase().includes(filter.name.toLowerCase());
      });
    }
    if (filter.sortBy) {
      switch (filter.sortBy) {
        case 'id':
          filteredCollection.sort(sortById);
          break;
        case 'name':
          filteredCollection.sort(sortByName);
          break;
        case 'date':
          filteredCollection.sort(sortByStartDate);
          break;
        case 'orderIndex':
          filteredCollection.sort(sortByOrderIndex);
          break;
      }
    }
    if (filter.orderBy === 'desc') {
      filteredCollection.reverse();
    }
    return filteredCollection;
  }
}
export function sortById(a: any, b: any): number {
  return a.id - b.id;
}
export function sortByName(a: any, b: any): number {
  return a.name.localeCompare(b.name);
}
export function sortByStartDate(a: any, b: any): number {
  return moment(a.startDate).diff(moment(b.startDate));
}
export function sortByOrderIndex(a: any, b: any): number {
  return a.orderIndex - b.orderIndex;
}
