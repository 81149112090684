import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {User} from '../../../shared/classes/user';
import {IFormData} from '../../../shared/interfaces/form-data';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {matchValidator} from '../../../shared/formValidators/match-validator';
import {MessageBoxTypeEnum} from '../../../shared/enum/message-box-type-enum';
import {FacadeService} from '../../../services/facade.service';
import {Message} from '../../../shared/classes/message';

@Component({
    selector: 'app-change-password-form',
    templateUrl: './change-password-form.component.html',
    styleUrls: ['./change-password-form.component.css']
})
export class ChangePasswordFormComponent implements OnInit {
    user: User;
    @Output() formDataEvent = new EventEmitter<IFormData<User>>();
    form: FormGroup;
    message: string;
    success: boolean;
    messageBoxType = MessageBoxTypeEnum.Success;
    submitted = false;

    constructor(
        private fs: FacadeService,
        private fb: FormBuilder
    ) {
        this.fs.userService.user$.subscribe(user => {
            this.user = user;
            this.form = this.initForm();
        });
    }

    ngOnInit(): void {
    }

    get registerFormControl(): { [p: string]: AbstractControl } {
        return this.form ? this.form.controls : {};
    }

    onSubmit(): void {
        this.submitted = true;
        this.message = null;
        if (this.form.valid) {
            const currentPassword = this.form.get('currentPassword').value;
            const newPassword = this.form.get('newPassword').value;

            this.fs.userService.changePassword(this.user, currentPassword, newPassword).subscribe(
                response => {
                    if (response) {
                        this.success = true;
                        this.message = 'Your password has been overwritten. You must now log in with your new password.';
                        this.fs.messageService.add(new Message('success', this.message, true), 30000);
                        this.messageBoxType = MessageBoxTypeEnum.Success;
                        this.form.reset();
                    }
                },
                err => {
                    if (err.statusCode === 402) {
                        this.message = err.error.description;
                        this.fs.messageService.add(new Message('danger', this.message, true), 30000);
                        this.messageBoxType = MessageBoxTypeEnum.Danger;
                    }
                }
            );
        }
    }

    onRelogin(): void {
        this.fs.userService.logout();
    }

    private initForm(): FormGroup {
        return this.fb.group({
            id: [this.user?.id],
            currentPassword: [null, Validators.required],
            newPassword: [null, [
                Validators.required,
                Validators.pattern('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).{6,25}'),
                matchValidator('confirmPassword', true)
            ]],
            confirmPassword: [null, [
                Validators.required,
                matchValidator('newPassword')]
            ],
        });
    }
}
