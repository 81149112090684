<div
  mwlResizable
  class="resizable"
  [ngStyle]="{'width':layoutStyles['adaptationCol']['width']}"
  (resizing)="onResizing($event)"
>
<div
  class="resize-handle-right"
  mwlResizeHandle
  [resizeEdges]="{ right: true }"
></div>

  <section
    class="header"
    fxLayout="row"
    fxLayoutAlign="space-between"
    [fxLayoutGap]="cssLayoutConfig['layoutGap']['width']"
    fxLayoutGap.xs="0"
  >
    <p class="__bold">{{headline}}</p>
    <section
      class="icon-links"
      fxLayoutAlign="space-between center"
      fxFlexAlign="center"
      mat-list-icon>
    </section>
  </section>

  <mat-divider></mat-divider>

  <div [@.disabled]="true">
      <mat-tab-group [selectedIndex]="tabindex">
        <mat-tab label="Info">
          <app-visual-component-info
            [entity]="entity"
            [languageTabIndex]="languageTabIndex"
            (languageTabIndexEvent)="onChangeLanguageTabIndex($event)">
          </app-visual-component-info>
        </mat-tab>
        <mat-tab label="Text">
          <app-visual-component-edit
            [entity]="entity"
            [languageTabIndex]="languageTabIndex"
            (formDataEvent)="onSubmitForm($event)"
            (languageTabIndexEvent)="onChangeLanguageTabIndex($event)">
          </app-visual-component-edit>
        </mat-tab>
        <mat-tab label="URLs">

          <mat-tab-group class="tab-small mt-2">
            <mat-tab label="List">
              <app-translation-url-list
                [entity]="entity"
                (formDataEvent)="onSubmitForm($event)">
              </app-translation-url-list>
            </mat-tab>
            <mat-tab label="Automatic URLs creation">
              <app-load-translation-urls
                [entity]="entity"
                (formDataEvent)="onSubmitForm($event)">
              </app-load-translation-urls>
            </mat-tab>
            <mat-tab label="Manual URLs creation">
              <app-add-translation-urls
                    [entity]="entity"
                    (formDataEvent)="onSubmitForm($event)">
              </app-add-translation-urls>
            </mat-tab>
          </mat-tab-group>

        </mat-tab>
        <mat-tab label="Settings">
          <app-visual-component-configure
            [visualComponent]="entity"
            (formDataEvent)="onSubmitForm($event)"
          >
          </app-visual-component-configure>
        </mat-tab>
        <mat-tab label="Image">
          <mat-tab-group class="tab-small mt-2">
            <mat-tab label="Desktop">
              <app-image-loader
                      [entity]="entity"
                      [imageType]="imageType.DESKTOP_LANDSCAPE"
                      (imageEvent)="onImageEvent($event)">
              </app-image-loader>
            </mat-tab>
            <mat-tab label="Mobile" [disabled]="true">
              <app-image-loader
                      [entity]="entity"
                      [imageType]="imageType.MOBILE_LANDSCAPE"
                      (imageEvent)="onImageEvent($event)">
              </app-image-loader>
            </mat-tab>
          </mat-tab-group>
        </mat-tab>
        <mat-tab *ngIf="displayEditor()" label="Wysiwyg-Editor">
          <app-visual-component-wysiwyg-texteditor
            [entity]="entity"
            [languageTabIndex]="languageTabIndex"
            (languageTabIndexEvent)="onChangeLanguageTabIndex($event)">
          </app-visual-component-wysiwyg-texteditor>
        </mat-tab>
      </mat-tab-group>
  </div>
</div>
