import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {catchError, map, retry, share} from 'rxjs/operators';
import {Medium} from '../shared/classes/medium';
import {ApiResponse} from '../shared/interfaces/api-response';
import {Mapper} from '../shared/classes/mapper';
import {Infobox} from '../shared/classes/infobox';

const RETRY_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class InfoboxService extends BaseService<Infobox> {
    localStorage: Storage;
    localStorageKey: string;

    constructor(
        protected override http: HttpClient
    ) {
        super(http, `${environment.apiBaseUrl}/infobox`);
        this.localStorage = window.sessionStorage;
        this.localStorageKey = 'l-shop-infobox';
    }

    init(): Promise<any> {
        return new Promise<void>((resolve, reject) => {
            this.findAll().subscribe(data => {
                this.data = data;
                this.subject.next(this.data);
                resolve();
            });
        });
    }

    override findOne(id: number): Observable<Infobox> {
        return this.http.get<ApiResponse>(this.url + '/' + Number(id)).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data.translations) {
                    Mapper.parseTranslations(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override findAll(): Observable<Infobox[]> {
        return this.http.get<ApiResponse>(this.url).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data) {
                    response.data.forEach(obj => {
                        if (obj.translations) {
                            Mapper.parseTranslations(obj);
                        }
                    });
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override post<T>(t: T): Observable<T> {
        return this.http.post<ApiResponse>(this.url, t).pipe(
            map((response: ApiResponse) => {
                if (response.data.translations) {
                    Mapper.parseTranslations(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override update<T>(id: number, t: T): Observable<T> {
        return this.http.put<ApiResponse>(this.url + '/' + Number(id), t, {}).pipe(
            map((response: ApiResponse) => {
                if (response.data.translations) {
                    Mapper.parseTranslations(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    GetGlobalAndIndividual(shopId: number, infoboxTypeId: number, date: string): Observable<Medium[]> {
        return this.http.get<ApiResponse>(`${this.url}/globalAndIndividual/${shopId}/${infoboxTypeId}/${date}`)
            .pipe(
                map((response) => {
                    if (response.data) {
                        response.data.forEach(obj => {
                            if (obj.translations) {
                                Mapper.parseTranslations(obj);
                            }
                        });
                    }
                    return response.data;
                }), catchError(this.errorHandler)
            );
    }

    findAllByFilter(filter: any): Observable<Infobox[]> {
        const queryParams = new HttpParams().appendAll(filter);
        return this.http.get<ApiResponse>(`${this.url}/filter`, {params: queryParams})
            .pipe(
                map((response) => {
                    if (response.data) {
                        response.data.forEach(obj => {
                            if (obj.translations) {
                                Mapper.parseTranslations(obj);
                            }
                        });
                    }
                    return response.data;
                }), catchError(this.errorHandler)
            );
    }

    getLocalStorageKey(): string {
        return this.localStorageKey;
    }

    getLocalStorage(key: string): any {
        if (this.isLocalStorageSupported) {
            return JSON.parse(this.localStorage.getItem(key));
        }
        return null;
    }

    setLocalstorage(key: string, value: any): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.setItem(key, JSON.stringify(value));
            return true;
        }
        return false;
    }

    removeLocalstorage(key: string): boolean {
        if (this.isLocalStorageSupported) {
            this.localStorage.removeItem(key);
            return true;
        }
        return false;
    }

    isLocalStorageSupported(): boolean {
        return !!this.localStorage;
    }
}
