import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {Medium} from '../shared/classes/medium';
import {BaseService} from './base-service';
import {Observable} from 'rxjs';
import {ImageType} from '../shared/enum/image-type-enum';
import {catchError, map, retry, share} from 'rxjs/operators';
import {ApiResponse} from '../shared/interfaces/api-response';
import {Mapper} from '../shared/classes/mapper';
const RETRY_SIZE = 1;

@Injectable({
    providedIn: 'root'
})
export class MediumService extends BaseService<Medium> {
    constructor(
        protected override http: HttpClient
    ) {
        super(http, `${environment.apiBaseUrl}/medium`);
    }

    override findOne(id: number): Observable<Medium> {
        return this.http.get<ApiResponse>(this.url + '/' + Number(id)).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                    Mapper.parseCssStyles(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override findAll(): Observable<Medium[]> {
        return this.http.get<ApiResponse>(this.url).pipe(
            share(),
            retry(RETRY_SIZE),
            map((response: ApiResponse) => {
                if (response.data) {
                    response.data.forEach(m => {
                        Mapper.parseTranslations(response.data);
                        Mapper.parseTranslateUrls(response.data);
                        Mapper.parseCssStyles(response.data);
                    });
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override post<T>(t): Observable<T> {
        return this.http.post<ApiResponse>(this.url, t).pipe(
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                    Mapper.parseCssStyles(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    override update<T>(id: number, t: T): Observable<T> {
        return this.http.put<ApiResponse>(this.url + '/' + Number(id), t, {}).pipe(
            map((response: ApiResponse) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                    Mapper.parseCssStyles(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    imageUpload(id: number, file: File, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('file', file);
        formData.append('imageType', imageType);
        return this.http.post<any>(`${this.url}/imageUpload`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
    }

    imageDelete(id: number, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('imageType', imageType);

        return this.http.post<any>(`${this.url}/imageDelete`, formData, {
            reportProgress: true,
            responseType: 'json'
        });
    }

    imageLoad(id: number, imageType = ImageType.DESKTOP_LANDSCAPE): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id.toString());
        formData.append('imageType', imageType);

        return this.http.post(`${this.url}/imageLoad`, formData, {
            reportProgress: true,
            responseType: 'blob'
        });
    }

    copy(data: any): Observable<any> {
        return this.http.post<ApiResponse>(`${this.url}/copy`, data).pipe(
            map((response) => {
                if (response.data) {
                    Mapper.parseTranslations(response.data);
                    Mapper.parseTranslateUrls(response.data);
                    Mapper.parseCssStyles(response.data);
                }
                return response.data;
            }), catchError(this.errorHandler)
        );
    }

    loadMainShopUrls(data: any): Observable<any> {
        return this.http.post<ApiResponse>(`${this.url}/loadMainShopUrls`, data).pipe(
            map((response: ApiResponse) => {
                return response.data;
            }), catchError(this.errorHandler)
        );
    }
}
