
<div class="headline-wrapper">
    <p class="__bold mt-2 mb-4">{{title}}</p>
    <mat-icon class="fa fa-info mt-2 mb-4" (click)="onDisplayDialog($event)" title="Info"></mat-icon>
</div>

<form [formGroup]="form" (ngSubmit)="onSubmit()">

    <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-3">
            <ng-container>
                <mat-form-field appearance="standard" floatLabel="always">
                    <mat-label>Select Medium-Categorie</mat-label>
                    <mat-select formControlName="mediumCategory">
                        <mat-option *ngFor="let type of mediumCategories" [value]="type">{{type.name}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="(form.controls.mediumCategory.touched || submitted) && form.controls.mediumCategory.errors?.required">
                        Medium-Category is required
                    </mat-error>
                </mat-form-field>
            </ng-container>
        </div>
        <div class="col-sm-12 col-md-6 col-xl-3" *ngIf="form.controls.mediumCategory.value">
            <div class="row">
                <div class="col-sm-10 col-md-10">

                    <input type="file" class="form-control" formControlName="importFile" #importFile (change)="onFileSelected($event)" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                    <mat-error *ngIf="(form.controls.importFile.touched && submitted) && form.controls.importFile.errors?.required">
                        Please select a valid .xlsx file
                    </mat-error>

                </div>
                <div class="col-sm-2 col-md-2">
                    <button  mat-stroked-button title="Reset Fields" (click)="onResetForm($event)">
                        <i class="fa fa-repeat"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <ng-container *ngIf="collection.length > 0">
        <div class="table-wrapper">
            <app-mat-table
                    [dataset]="collection"
                    [columns]="columns"
                    [displayFilter]="false"
                    [displaySelectColumn]="true"
                    [displayPagination]="true"
                    (selectedDataSource)="onSelectedDataSource($event)"
            >
            </app-mat-table>
        </div>
    </ng-container>

    <div class="row">
        <div class="col-12">
            <button mat-stroked-button type="submit"
                    [disabled]="! form.valid || selectedCollection.length === 0 || errorMessages.length > 0">Apply
            </button>
        </div>
    </div>

    <div class="row" *ngIf="successMessage">
        <div class="col-12 mt-2">
            <app-message-box [type]="messageBoxType.Success" [message]="successMessage" ></app-message-box>
        </div>
    </div>

    <div class="row" *ngIf="errorMessages.length > 0">
        <div class="col-12 mt-2" *ngFor="let message of errorMessages">
            <app-message-box [type]="messageBoxType.Danger" [message]="message" ></app-message-box>
        </div>
    </div>

    <ng-template [ngIf]="noContent">
        <app-message-box [type]="messageBoxType.NoContent" [message]="'No data loaded'"></app-message-box>
    </ng-template>

    <ng-template [ngIf]="loading">
        <mat-spinner class="center"></mat-spinner>
    </ng-template>

</form>



