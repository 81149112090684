<div class="mt-2 mb-4">
<mat-tab-group class="tab-small" [selectedIndex]="languageTabIndex">
    <ng-container *ngFor="let t of entity.mappedTranslations; let i = index;">
            <mat-tab>
                <ng-template mat-tab-label>
                <span
                        [matTooltip]="t.language"
                        matTooltipShowDelay="1000"
                        matTooltipPosition="above"
                >{{getLocale(t)}}
                </span>
                </ng-template>
                <div class="teaser-specials">
                    <img *ngIf="entity.image" [src]="getImagePath(entity.image)" alt="">
                    <div class="wrapper-text">
                        <h2 *ngIf="t.headline" [innerHTML]="t.headline | strongify"></h2>
                        <p *ngIf="t.subheadline">{{t.subheadline}}</p>
                    </div>
                </div>
            </mat-tab>
        </ng-container>
    </mat-tab-group>
</div>


