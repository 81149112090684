import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MainShopGuard} from '../core/guards/main-shop.guard';
import {VisualComponentListComponent} from './components/visual-component-list/visual-component-list.component';
import {
  VisualComponentAdaptationRootComponent
} from '../shared/components/visual-component/visual-component-adaptation-root/visual-component-adaptation-root.component';

const routes: Routes = [
  {
    path: '',
    component: VisualComponentListComponent,
    canActivate: [MainShopGuard],
    children: [
      {
        path: 'teaser/:id',
        component: VisualComponentAdaptationRootComponent
      }
    ],
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [MainShopGuard]
})
export class TeaserSpecialsGlobalRoutingModule { }
