import { Injectable } from '@angular/core';
import {Message} from '../shared/classes/message';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private _messageStore: Message[] = [];
  private _messages = new BehaviorSubject<Message[]>(this._messageStore);

  messages$ = this._messages.asObservable();

  constructor() {}

  public add(message: Message, timeout: number): void {
    this._messageStore.push(message);
    this._messages.next(this._messageStore);
    if (timeout > 0) {
      setTimeout(() => {
        this.remove(message);
      }, timeout);
    }
  }

  public remove(messageToFind: Message): void {
    this._messageStore = this._messageStore.filter((message) => {
      return !(message.type === messageToFind.type &&
          message.content === messageToFind.content &&
          message.closable === messageToFind.closable);
    });
    this._messages.next(this._messageStore);
  }
}
