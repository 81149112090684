<h2 mat-dialog-title>{{data.title}}</h2>

<h4 *ngIf="data.headline">{{data.headline}}</h4>

<ng-container *ngIf="data.list.length">
    <div class="table-wrapper">
        <app-mat-table
                [dataset]="data.list"
                [columns]="columns"
                [displayFilter]="false"
                [displayPagination]="true">
        </app-mat-table>
    </div>
</ng-container>

<mat-dialog-actions align="end">
    <button mat-stroked-button mat-dialog-close>Close</button>
</mat-dialog-actions>
