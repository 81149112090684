import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TableButtonAction} from '../../interfaces/table-button-action';
import {BUTTON_CONSTS} from '../../consts';

@Component({
  // tslint:disable-next-line:component-selector
  selector: '[action-buttons]',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.css'],
})
export class ActionButtonsComponent implements OnInit {
  @Input() value: string;
  @Output() buttonAction: EventEmitter<TableButtonAction> = new EventEmitter<TableButtonAction>();
  constructor() { }

  ngOnInit(): void {}

  onEditClick(): void {
    this.buttonAction.emit({
      name: BUTTON_CONSTS.actionButton.edit,
      value: this.value,
    });
  }

  onDeleteClick(): void {
    this.buttonAction.emit({
      name: BUTTON_CONSTS.actionButton.delete,
      value: this.value
    });
  }

  onViewClick(): void {
    this.buttonAction.emit({
      name: BUTTON_CONSTS.actionButton.view,
      value: this.value
    });
  }
}
