import {FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export class UrlValidator {
  private static patternUrl = '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})|(mailto:.*)';

  // @ts-ignore
    static checkUrl: ValidatorFn = (control: FormControl): ValidationErrors | null  => {
      const reg = new RegExp(UrlValidator.patternUrl);
      const str = control.value;
      if (str) {
          const match = str.match(reg);
          if (match === null) {
              return {invalidUrl: true};
          }
      }
      return null;
  }
}
