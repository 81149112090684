import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavItem} from '../../shared/interfaces/nav-item';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
})
export class AppMenuComponent implements OnDestroy {
  private subscription: Subscription;
  menu: NavItem[];

  constructor(
      public userService: UserService
  ) {
    const hasAdminPermissions = this.userService.getUser().admin;

    this.menu = [
      {
        displayName: 'Dashboard',
        route: 'dashboard',
        iconName: 'dashboard',
        display: true
      },
      {
        displayName: 'Slider',
        route: 'section-slider',
        iconName: 'burst_mode_icon',
        display: true
      },
      {
        displayName: 'Teaser',
        route: 'section-teaser',
        iconName: 'message',
        display: true,
        children: [
          {
            displayName: 'Startpage',
            route: 'section-teaser',
            display: true
          },
          {
            displayName: 'Specials & Sale',
            route: 'section-teaser-specials',
            display: true
          }
        ]
      },
      {
        displayName: 'Infobox',
        route: 'section-infobox',
        iconName: 'message',
        display: true,
        children: [
          {
            displayName: 'Customer',
            route: 'section-infobox-customer',
            display: true
          },
          {
            displayName: 'Article',
            route: 'section-infobox-article',
            display: true
          }
        ]
      },
      {
        displayName: 'Simulator',
        route: 'section-simulator',
        iconName: 'mobile_friendly',
        display: true,
        children: [
          {
            displayName: 'Startpage',
            route: 'section-simulator/startpage',
            display: true
          },
          {
            displayName: 'Infobox',
            route: 'section-simulator/infobox',
            display: true
          },
          {
            displayName: 'Specials & Sale',
            route: 'section-simulator/visual-component',
            display: true
          }
        ]
      },
      {
        displayName: 'Archive',
        iconName: 'archive_icon',
        display: true,
        children: [
          {
            displayName: 'Slider',
            route: 'section-slider-archive',
            display: true
          },
          {
            displayName: 'Teaser Startpage',
            route: 'section-teaser-archive',
            display: true
          },
          {
            displayName: 'Teaser Specials & Sale',
            route: 'section-teaser-specials-archive',
            display: true
          },
          {
            displayName: 'Infobox',
            route: 'section-infobox-archive',
            display: true
          }
        ]
      },
      {
        displayName: 'Import',
        route: 'visual-component-import',
        iconName: 'upload_file',
        display: true,
        children: [
          {
            displayName: 'Teaser Specials & Sale',
            route: 'visual-component-import',
            display: true
          },
          {
            displayName: 'Infobox',
            route: 'infobox-import',
            display: true
          }
        ]
      },
      {
        displayName: 'User',
        route: 'section-user',
        iconName: 'account_box',
        display: hasAdminPermissions,
        children: [
          {
            displayName: 'List',
            route: 'section-user/list',
            display: hasAdminPermissions
          },
          {
            displayName: 'Add User',
            route: 'section-user/add-edit',
            display: hasAdminPermissions
          }
        ]
      },
      {
        displayName: 'Profile',
        iconName: 'account_box',
        display: true,
        children: [
          {
            displayName: 'Info',
            route: 'profile/info',
            display: true
          },
          {
            displayName: 'Change PW',
            route: 'profile/change-password',
            display: true
          }
        ]
      },
    ];
  }

  ngOnDestroy(): void {
    if (this.subscription !== undefined) {
      this.subscription.unsubscribe();
    }
  }
}
