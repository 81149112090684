import {Component, Inject, OnInit} from '@angular/core';
import {XlsxDownloadService} from '../../../services/xlsx-download.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-download-xlsx',
  templateUrl: './download-xlsx.component.html',
  styleUrls: ['./download-xlsx.component.css']
})
export class DownloadXlsxComponent implements OnInit {
  filename: string;
  headlines: string[];
  rows: string[];
  content: string;

  constructor(
      public dialogRef: MatDialogRef<DownloadXlsxComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private xlsxDownloadService: XlsxDownloadService
  ) {}

  ngOnInit(): void {
    if (this.data.config) {
      this.loadConfig(this.data.config);
    }
  }

  loadConfig(config: any): void {
    this.filename = config?.file?.name || 'download.xlsx';
    this.headlines = config?.file?.headlines || [];
    this.rows = config.file?.rows || [];
    this.content = config?.content || null;
  }

  onDownloadXlsx(e): void {
    e.preventDefault();
    this.xlsxDownloadService.download(this.headlines, this.rows, this.filename);
  }
}
